import { Component, Input, OnInit } from '@angular/core';
import { ICourse } from 'modules/course/common/models/course.model';
import {
  CourseRegistrationStatus, courseRegistrationStatuses
} from 'modules/course-registrations/models/course-registration-status.model';
import _ from 'lodash';
import { CourseRegistrationStatusService } from '../services/course-registration-status.service';
import { Observable, of } from 'rxjs';


@Component({
  selector: 'course-registration-status',
  templateUrl: './course-registration-status.component.html',
})
export class CourseRegistrationStatusComponent implements OnInit {
  static readonly selector = 'courseRegistrationStatus';

  @Input() course: {courseFormat?: {registrationRequired: boolean}, paymentApproval?: boolean};
  @Input() mode: string;
  @Input() showIcon: boolean;
  @Input() hideDeclined: boolean;
  @Input() showHightStatus: boolean;

  statusId: CourseRegistrationStatus;
  _regData: { course?: ICourse };
  statusName: string;
  statusTitle: string;

  constructor(private courseRegistrationStatusService: CourseRegistrationStatusService) { }

  @Input() set regData(value) {
    this._regData = value;
    this.handleChanges();
  }

  get regData() {
    return this._regData;
  }

  ngOnInit() {
    this.handleChanges();
  }

  handleChanges() {
    const course = this.course || this.regData?.course;

    const registrationRequired = course?.courseFormat?.registrationRequired ?? true;

    if (registrationRequired) {
      this.defineStatus();

      if (this.statusId) {
        this.getStatusName(this.statusId).subscribe((name) => {
          this.statusName = name;
        });
        this.courseRegistrationStatusService.getTitle(this.statusId).subscribe((title) => {
          this.statusTitle = title;
        });
      }
    }
  }

  isCompleted(statusId: CourseRegistrationStatus) {
    return _.includes(courseRegistrationStatuses.strictCompletedSet, statusId);
  }

  defineStatus() {
    this.statusId = (!this.showHightStatus && this.getLastStatus()) ||
      _.get(this.regData, 'status_id') ||
      _.get(this.regData, 'statusId') ||
      _.get(this.regData, 'status') ||
      _.get(this.regData, 'course_status_id') ||
      CourseRegistrationStatus.notStarted;

    if (this.hideDeclined && _.includes(courseRegistrationStatuses.declinedSet, this.statusId)) {
      this.statusId = _.get(this.regData, 'lastCompletedRegistration.statusId', null);
    }

    return this.statusId;
  }

  getLastStatus() {
    return _.get(this.regData, 'lastRegistration.statusId');
  }

  getStatusName(statusId: CourseRegistrationStatus):Observable<string> {
    if (this.course?.paymentApproval) {
      if (_.includes([CourseRegistrationStatus.approved, CourseRegistrationStatus.pending], statusId)) {
        if (this.courseRegistrationStatusService.statusNamesOverride[statusId]) {
          return of(this.courseRegistrationStatusService.statusNamesOverride[statusId]);
        }
      }
    }

    return this.courseRegistrationStatusService.getStatusName(statusId);
  }
}

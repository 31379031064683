import { AfterViewChecked, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { CurrentUserService } from 'core/authorization';
import { EMPTY, Unsubscribable, catchError, finalize, tap } from 'rxjs';
import { CheckoutProductType, ICheckoutProduct, IPurchasePayload, IPurchaseResult } from '../models/checkout.models';
import { EcommercePurchaseService } from '../services/ecommerce-purchase.service';
import { NotificationService } from 'ajs/modules/app/environment/notification-service';
import _ from 'lodash';
import { HttpErrorResponse } from '@angular/common/http';
import { NavigationService } from 'core/navigation';


@Component({
  selector: 'checkout',
  templateUrl: './checkout.component.html',
})
export class CheckoutComponent implements OnDestroy, AfterViewChecked {
  @ViewChild('redirectPost') redirectPost: ElementRef;

  @Input() productItem: ICheckoutProduct;

  purchase?: IPurchaseResult;
  purchaseSubscription?: Unsubscribable;
  checkoutSuccess?: boolean;
  processorEndpoint?: string;
  securityToken?: string;
  securityTokenId?: string;
  purchaseError?: string;
  purchaseProductError?: string;

  readonly currentUser = this.currentUserService.get();

  constructor(
    private stateService: StateService,
    private currentUserService: CurrentUserService,
    private activeState: UIRouterGlobals,
    private notificationService: NotificationService,
    private ecommercePurchaseService: EcommercePurchaseService,
    private navigationService: NavigationService,
  ) {}

  ngOnDestroy(): void {
    this.clearPurchaseSubscription();
  }

  checkout(): void {
    if (this.currentUser.anonymous) {
      this.navigationService.setTargetState({
        name: 'main.checkout',
        params: {
          courseId: this.activeState.params.courseId,
          price: this.activeState.params.price,
          sessionId: this.activeState.params?.sessionId,
        }
      });

      this.stateService.go('prompt.login');
    } else {
      this.processPurchase();
    }
  }

  ngAfterViewChecked() {
    if (this.checkoutSuccess && this.redirectPost) {
      this.redirectPost.nativeElement.submit();
    }
  }

  readonly isStateAvailable = (state: string) => !!this.stateService.get(state);

  private processPurchase(): void {
    /**
     * Uncomment next lines for dev test (generate on demo.testing.kmionline.com and update for dev)
    this.securityToken = '';//purchaseResult.payment.securityToken;
    this.securityTokenId = '';//purchaseResult.payment.securityTokenId;
    this.processorEndpoint = '';//purchaseResult.payment.processorEndpoint;
    this.checkoutSuccess = true;

    return;
    **/

    this.clearPurchaseSubscription();
    delete this.purchaseError;
    delete this.purchaseProductError;

    const payload: IPurchasePayload = {
      cart: [this.productItem],
      returnUrl: `{0}/${this.stateService.href('main.checkoutConfirmation', { purchaseId: '{1}' })}`,
      payment: {
        cancelUrl: `${window.location.origin}${this.stateService.href(this.activeState.current)}`
      }
    };

    this.purchaseSubscription = this.ecommercePurchaseService.post(payload, true)
      .pipe(
        tap(purchaseResult => {
          this.purchase = purchaseResult;

          if (purchaseResult.statusId === 1) {
            if (purchaseResult.payment) {
              this.securityToken = purchaseResult.payment.securityToken;
              this.securityTokenId = purchaseResult.payment.securityTokenId;
              this.processorEndpoint = purchaseResult.payment.processorEndpoint;

              if (purchaseResult.payment.paymentGateway?.typeId === CheckoutProductType.COURSE) {
                this.checkoutSuccess = true;
              } else {
                window.location.href = purchaseResult.payment.processorEndpoint;
              }
            } else {
              this.notificationService.error('Payment system is unavailable', 5e3);
            }
          } else {
            this.stateService.go('main.checkoutConfirmation', { purchaseId: purchaseResult.id });
          }
        }),
        catchError((reason: HttpErrorResponse) => {
          if (reason.status === 403 && reason.error) {
            this.purchaseError = this.purchaseProductError =
              typeof reason.error !== 'string' ? reason.error.error : reason.error;

            if (_.get(reason, 'error.cart.0.error')) {
              this.purchaseProductError += ' ' + _.get(reason, 'error.cart.0.error');
            }

            return EMPTY;
          }

          return this.notificationService
            .error(typeof reason.error !== 'string' ? reason.error.error : reason.error, 5e3);
        }),
        finalize(() => this.clearPurchaseSubscription())
      ).subscribe();
  }

  private clearPurchaseSubscription(): void {
    if (this.purchaseSubscription) {
      this.purchaseSubscription.unsubscribe();
      delete this.purchaseSubscription;
    }
  }
}

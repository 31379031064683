import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ICourseDetails } from 'modules/course/common/models/course.model';
import { ILearningObjectRegistration } from 'modules/course/common/models/learning-object-registration.model';
import { CurrentUserService } from 'ajs/modules/app/current-user.service';
import {
  IPlayerEvent,
  Player,
  PlayerServiceFactory,
  PlayerServiceToken
} from 'modules/course/player/services/player.service';
import { Subject, timer, Unsubscribable } from 'rxjs';
import { CourseService } from 'modules/course/common/services/course.service';
import { TrainingService } from 'modules/course-registrations/services/training.service.ajs-upgraded-provider';
import { NotificationService } from 'ajs/modules/app/environment/notification-service';
import {
  LearningObjectRegistrationWorkflowFactory,
  LearningObjectRegistrationWorkflowService,
  LearningObjectRegistrationWorkflowServiceFactory
} from 'modules/course-registrations/services/learning-object-registration-workflow.service.ajs-upgraded-provider';
import { GlobalConfig } from 'core/environment';
import {
  CourseRegistrationStatus, courseRegistrationStatuses
} from 'modules/course-registrations/models/course-registration-status.model';
import {
  CourseRegistrationStatusService
} from 'modules/course-registrations/services/course-registration-status.service';
import {
  LearningObjectRegistrationService
} from 'modules/course-registrations/services/learning-object-registration.service.ajs-upgraded-provider';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { quizEnums } from 'modules/quiz';
import { playableCollectionCourseFormats } from 'modules/course/player/models/player.enums';


@Component({
  selector: 'course-player',
  templateUrl: './course-player.component.html',
})
export class CoursePlayerComponent implements OnInit, OnDestroy {
  @Input() course: ICourseDetails;
  @Input() registration: ILearningObjectRegistration;
  @Input() player: Player;
  @Output() playerChange = new EventEmitter<Player>();
  @Input() contentType: string;
  @Output() playerEvent = new EventEmitter<IPlayerEvent>();
  @Input() hideRetakePostAssessment: boolean;
  @Input() wrapped: string;
  @Input() onlyPlayableCourses: boolean;
  @Input() trackingName: string;
  @Input() closeSubject: Subject<void>;

  playerActions = new Subject<string>();
  userIsAdmin: boolean;

  restart: boolean;
  workflow: LearningObjectRegistrationWorkflowService;
  error: string;
  _stepFinished: boolean;
  retakeAction: string;
  hidding: boolean;

  quizRetakeSubject = new Subject<{ resultId: number, skipStartStep?: boolean }>();
  private _noInteractiveContent: boolean;
  private suggestTimeout?: Unsubscribable;

  private isEventsBound: boolean;

  constructor(
    private currentUser: CurrentUserService,
    private courseService: CourseService,
    private trainingService: TrainingService,
    private notificationService: NotificationService,
    @Inject(LearningObjectRegistrationWorkflowFactory)
    private workflowFactoryService: LearningObjectRegistrationWorkflowServiceFactory,
    @Inject(PlayerServiceToken)
    private playerServiceFactory: PlayerServiceFactory,
    private globalConfig: GlobalConfig,
    private courseRegistrationStatusService: CourseRegistrationStatusService,
    private learningObjectRegistrationService: LearningObjectRegistrationService,
  ) {  }

  set noInteractiveContent(value: boolean) {
    this._noInteractiveContent = value;

    if (this._noInteractiveContent) {
      this.playerEvent.emit({ event: 'noInteractiveContent', step: this.player.currentStep });
    }
  }

  get noInteractiveContent() {
    return this._noInteractiveContent;
  }

  ngOnInit() {
    this.userIsAdmin = this.currentUser.get().checkPermission('courseRegistration.edit');

    if (!this.trackingName) {
      this.trackingName = 'course_player';
    }

    this.initCourseRegistration();
    this.courseService.setMetaInfo(this.course);
  }

  launchContent(action: string) {
    this.workflow.exec(action);
  }

  ngOnDestroy() {
    // this.playerActions.next('player_closing');
    this.suggestTimeout?.unsubscribe();
    this.registration.removeEventListener('updated', this.onRegUpdated);
  }

  closePlayer() {
    this.playerEvent.emit({ event: 'courseInterrupted', step: this.player.currentStep });
  }

  moveToTheNextStep(ignoreAvailability?: boolean) {
    this.player.launchNextStep(ignoreAvailability);
  }

  isQuizStep() {
    return !this.player?.currentStep?.contentStep;
  }

  finishPostAssessment() {
    this.registration.executeAction('FinishPostAssessment');
  }

  retakeResult(type: string, skipStartStep?: boolean) {
    let action, actionPromise;

    if (type === 'post') {
      action = 'LaunchPostAssessmentAction';

      if (this.registration.postAssessment.intendedForModeId &&
        this.registration.postAssessment.intendedForModeId === quizEnums.quizIntendedFor.administrators) {
        const url = `/a/course_registrations/${this.registration.id}/workflow_actions/`;

        actionPromise = this.registration.executeAction(action, null, url);
      }
    } else if (type === 'pre') {
      action = 'LaunchPreAssessmentAction';
    } else {
      action = 'Launch';
    }

    if (!actionPromise) {
      actionPromise = this.registration.executeAction(action);
    }

    actionPromise.then(() => {
      this.setupWorkflow(type);
      this.quizRetakeSubject.next({
        resultId: type === 'post'
          ? this.registration.postAssessment.resultId
          : type === 'pre'
            ? this.registration.preAssessment.resultId
            : this.registration.exam.resultId,
        skipStartStep
      });
    });
  }

  stepFinished(allowMoveToTheNextStep?: boolean) {
    const nextStep = this.player.getNextAvailableStep();

    this._stepFinished = true;

    this.playerEvent.emit({ event: 'stepFinished', step: this.player.currentStep });

    const intendedForLearner = nextStep?.intendedFor === quizEnums.quizIntendedFor.learners &&
      this.registration.userId === this.currentUser.get().id;
    const intendedForAdmin = nextStep?.intendedFor === quizEnums.quizIntendedFor.administrators &&
      this.userIsAdmin;
    const isContentStep = this.player.currentStep.contentStep && [101, 103].includes(this.course.format);

    if (nextStep &&
      (!nextStep.intendedFor || (intendedForLearner || intendedForAdmin)) &&
      (isContentStep || allowMoveToTheNextStep)) {
      this.moveToTheNextStep();
    }

    const regCompleted = courseRegistrationStatuses.completedSet.includes(this.registration.status_id);

    if (!nextStep && (!this.registration.status_id || regCompleted)) {
      this.playerEvent.emit({ event: 'courseCompleted', step: this.player.currentStep });
    }
  }

  canShowCompleted() {
    return !(this.globalConfig.settings.coursePlayer.completionPopupShowOnce &&
      this.registration.id === this.registration.completedRegistration.id);
  }

  continueIsAvailable() {
    const nextStep = this.player && this.player.getNextAvailableStep();
    const intendedForLearner = nextStep?.intendedFor === quizEnums.quizIntendedFor.learners &&
      this.registration.userId === this.currentUser.get().id;
    const intendedForAdmin = nextStep?.intendedFor === quizEnums.quizIntendedFor.administrators &&
      this.userIsAdmin;
    const isContentStep = this.player.currentStep.contentStep &&
      ([101, 102, 103].includes(this.course.format) || this.course.scorm);

    return this._stepFinished &&
      nextStep && (!nextStep.intendedFor || (intendedForLearner || intendedForAdmin)) &&
      !isContentStep;
  }

  private initCourseRegistration() {
    this.registration.originalId = this.registration.id;
    this.trainingService.mergeSessionsData(this.course?.sessions || [], this.registration);
    this.setupWorkflow();
  }

  private onRegUpdated() {
    if (this.restart && this.registration.id &&
      this.registration.originalId &&
      this.registration.originalId !== this.registration.id
    ) {
      this.registration.originalId = null;
      this.restart = null;
    }

    if (!this.registration.originalId && (this.registration.id || this.registration.completedRegistration.id)) {
      this.registration.originalId = this.registration.id || this.registration.completedRegistration.id;
    }

    this.suggestTimeout = timer(2e3).subscribe(() => {
      this.suggestNextStep();
    });
  }

  private setupWorkflow(initialStep?: string) {
    if (!this.isEventsBound) {
      this.registration.on('updated', () => this.onRegUpdated());
      this.isEventsBound = true;
    }

    this.workflow = this.workflowFactoryService(this.registration, { bypassContent: true });

    this.initPlayer(this.workflow, initialStep || this.contentType);
  }

  private initPlayer(workflow: LearningObjectRegistrationWorkflowService, step: string) {
    let hasStep = !!step;

    this.player = this.playerServiceFactory(workflow);

    this.player.onError().subscribe((error) => {
      this.error = error;
    });

    this.player.on('stepChanged').subscribe(() => {
      this.playerActions.next('course_player.step_changed');
    });

    this.player.on('contentNotAvailable').subscribe(() => {
      this.noInteractiveContent = true;
    });

    this.player.on('stepChanged').subscribe(() => {
      this.error = null;
      this._stepFinished = false;
      this.retakeAction = null;
      this.notificationService.hideSuggestion();

      this.suggestTimeout?.unsubscribe();
    });

    this.player.init().subscribe(() => {
      if (this.onlyPlayableCourses && !playableCollectionCourseFormats.includes(this.course.format)) {
        this.noInteractiveContent = true;
      } else {
        if (step) {
          this.player.launch(step);
        } else {
          const step = this.player.getNextAvailableStep();

          if (step && step.defaultAction) {
            step.launch(true)
              .subscribe(() => {
                this.player.launch(step.defaultAction.type);
              });
          }

          hasStep = !!step;
        }

        if (!hasStep) {
          this.noInteractiveContent = true;
        }
      }

      this.playerChange.emit(this.player);
    });
  }

  private suggestNextStep() {
    const step = this.player.getNextAvailableStep();

    if (this.player.currentStep && !this.isQuizStep() && !this.hidding) {
      if (step) {
        if (!step.intendedFor || step.intendedFor === 1 || this.userIsAdmin) {
          if (!this.globalConfig.settings.coursePlayer?.hideNextStepBtnFor?.includes(this.course.format)) {
            this.notificationService.suggest({
              message: `The ${step.title} is now available.`,
              link: {
                message: 'Take it now',
                id: `${this.trackingName}component_${this.course.id}_take_it_now`,
                handler: () => this.player.showStep(step)
              }
            }, 10e3);
          }
        } else {
          this.notificationService.suggest({
            message: `${step.title} must be completed by an administrator`
          }, 10e3);
        }
      } else {
        if (this.player.currentStep && this.player.currentStep.completed && this.canShowCompleted()) {
          if (!this._stepFinished) {
            this.playerEvent.emit({ event: 'courseCompleted', step: this.player.currentStep });
          }

          let message = 'The course is completed.';

          this.courseRegistrationStatusService.getStatusName(this.registration.status_id)
            .subscribe((status) => {
              const statusId = this.registration.status_id;

              if (this.registration.status_id === CourseRegistrationStatus.notPassed) {
                message += ` But the status is ${status.toLowerCase()}.`;
              }

              fromPromise(this.learningObjectRegistrationService.get(this.course, null)).subscribe(registration => {
                this.registration = registration;
                this.workflow = this.workflowFactoryService(this.registration, { bypassContent: true });

                this.notificationService.suggest({
                  message,
                  link: statusId === CourseRegistrationStatus.notPassed && this.workflow.hasAction('Launch') ? {
                    message: 'Re-start',
                    id: `${this.trackingName}component_${this.course.id}_re_start`,
                    handler: () => {
                      this.contentType = null;
                      this.restart = true;
                      this.isEventsBound = false;
                      this.playerActions.next('player_restart');
                      this.registration = registration;
                      this.initCourseRegistration();
                    }
                  } : null
                }, 10e3);
              });
            });
        } else {
          if (this.workflow.hasAction('CompleteAction') &&
            this.registration.course.completionTypeId !== 2) {
            this.notificationService.suggest({
              message: 'You can complete course.',
              link: {
                message: 'Complete',
                id: `${this.trackingName}component_${this.course.id}_complete`,
                handler: () => this.workflow.exec('CompleteAction')
              }
            }, 10e3);
          }
        }
      }
    }
  }
}


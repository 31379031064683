import { FactoryProvider } from '@angular/core';
import { ICourseAbstract } from 'modules/course/common/models/course.model';
import { IPlayCourseAction } from 'modules/course/common/models/play-course.model';
import { IUserCourse } from 'modules/course-registrations/models/course-registration.model';


export abstract class PlayCourseService {
  abstract isCourseInCreateRegistrationState(course: ICourseAbstract,
                                             registration: IUserCourse,
                                             skipPriceCheck: boolean,
                                             skipExpiredCheck: boolean): boolean;

  abstract isCourseInRestartState(course: ICourseAbstract, registration: IUserCourse): boolean;

  abstract isEvaluationLaunchAvailable(course: ICourseAbstract, registration: IUserCourse): boolean;

  abstract componentsScrollAvailable(course: ICourseAbstract): boolean;

  abstract isExpired(course: ICourseAbstract): boolean;

  abstract isBuyNowAvailable(course: ICourseAbstract, registration: IUserCourse, mode: string): boolean;

  abstract isCoursePlayAvailable(course: ICourseAbstract, registration: IUserCourse, mode: string): boolean;

  abstract isSinglePlayCourse(mode: string): boolean;

  abstract saveForLaterAvailable(course: ICourseAbstract, registration: IUserCourse): boolean;

  abstract isPrintCourseAvailable(course: ICourseAbstract, registration: IUserCourse): boolean;

  abstract isAttendTheMeetingBtnAvailable(registration: IUserCourse, course: ICourseAbstract): boolean;

  abstract getAvailablePlayCourseActions(registration: IUserCourse,
                                         playCourseActions: IPlayCourseAction[]): IPlayCourseAction[];
}

export function playCourseServiceFactory(injector: angular.auto.IInjectorService): PlayCourseService {
  return injector.get<PlayCourseService>('playCourseService');
}

export const playCourseServiceProvider: FactoryProvider = {
  provide: PlayCourseService,
  useFactory: playCourseServiceFactory,
  deps: ['$injector']
};

import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid';
import { StateService, Transition } from '@uirouter/core';
import { UserFeaturedCoursesComponent } from 'modules/course/custom-lists/componenets/user-featured-courses.component';


export const courseCustomListRoutingModule: NgHybridStateDeclaration[] = [
  {
    name: 'main.recommendedCourses',
    url: '^/recommended-courses',
    data: {
      label: 'Recommendations',
      availableFor: ['regularUser', 'admin']
    },
    component: UserFeaturedCoursesComponent,
    resolve: [
      {
        provide: 'header',
        deps: [Transition, StateService],
        useFactory: (transition: Transition, stateService: StateService) => {
          const state = stateService.get(transition.to().name);

          return state.data.label;
        }
      }
    ]
  }
];

import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { ElmsUtils } from 'core/utils/elms-utils';
import { IDiscussion } from 'modules/discussions/models/discussion.model';
import moment from 'moment';
import { PASS_HTTP_ERRORS_TOKEN } from 'core/http-interceptors';


export enum DiscussionRequestsPath {
  GET = '/a/discussions/topics/:topicId/',
  PUT = '/a/discussions/topics/:topicId/',
  POST = '/a/discussions/topics/',
  QUERY = '/a/discussions/topics/',
}

@Injectable()
export class DiscussionsService {
  private defaultProps: Partial<IDiscussion> = {
    active: true,
  };

  constructor(private http: HttpClient) { }

  public get(discussionId: number): Observable<IDiscussion> {
    return this.http.get<IDiscussion>(ElmsUtils.formatUrl(DiscussionRequestsPath.GET, { topicId: discussionId }))
      .pipe(
        map(resource => Object.assign({}, this.defaultProps, resource))
      );
  }

  public post(payload: Partial<IDiscussion>): Observable<IDiscussion> {
    return this.http.post<IDiscussion>(DiscussionRequestsPath.POST, payload);
  }

  public update(discussionId: number, payload: Partial<IDiscussion>): Observable<IDiscussion> {
    return this.http.put<IDiscussion>(
      ElmsUtils.formatUrl(DiscussionRequestsPath.PUT, { topicId: discussionId }),
      payload
    );
  }

  public delete(discussionId: number, throwHttpErrors = false): Observable<null> {
    return this.http.delete<null>(
      ElmsUtils.formatUrl(DiscussionRequestsPath.GET, { topicId: discussionId }),
      { context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors) }
    );
  }

  public newDiscussion(params: Partial<IDiscussion>): Partial<IDiscussion> {
    return {
      ...this.defaultProps,
      ...(params)
    };
  }

  public haveBeenUpdated(topic: IDiscussion): boolean {
    return moment(topic.updatedDate).isAfter(topic.createdDate);
  }
}

import { Component, EventEmitter, HostBinding, Inject, Input, OnInit, Output } from '@angular/core';
import { ICourseAbstract, ICourseTile } from 'modules/course/common/models/course.model';
import { NotificationService } from 'ajs/modules/app/environment/notification-service';
import { PlayCourseService } from 'modules/course/common/services/play-course.service.ajs-upgraded-provider';
import {
  LearningObjectRegistrationService
} from 'modules/course-registrations/services/learning-object-registration.service.ajs-upgraded-provider';
import {
  LearningObjectRegistrationWorkflowFactory,
  LearningObjectRegistrationWorkflowService,
  LearningObjectRegistrationWorkflowServiceFactory
} from 'modules/course-registrations/services/learning-object-registration-workflow.service.ajs-upgraded-provider';
import _ from 'lodash';
import { ILearningObjectRegistration } from 'modules/course/common/models/learning-object-registration.model';
import { IUserCourse } from 'modules/course-registrations/models/course-registration.model';


@Component({
  selector: 'course-bookmark',
  templateUrl: './course-bookmark.component.html',
})
export class CourseBookmarkComponent implements OnInit {
  @HostBinding('class.hidden') hidden = false;
  @Input() course: ICourseTile;
  @Input() trackingName: string;
  @Output() registrationChanged = new EventEmitter<
    [ICourseAbstract, ILearningObjectRegistration]
  >();

  loadingWorkflow = false;
  isEmptyObject = _.isEmpty;
  workflow: LearningObjectRegistrationWorkflowService;
  private _userCourse: IUserCourse;

  constructor(
    private notificationService: NotificationService,
    public playCourseService: PlayCourseService,
    private learningObjectRegService: LearningObjectRegistrationService,
    @Inject(LearningObjectRegistrationWorkflowFactory)
    private workflowFactoryService: LearningObjectRegistrationWorkflowServiceFactory
  ) {}

  @Input() set userCourse(uc: IUserCourse) {
    this._userCourse = uc;
    this.updateVisibility();
  }

  get userCourse() {
    return this._userCourse;
  }

  ngOnInit() {
    this.updateVisibility();
  }

  loadWorkflow(callback) {
    if (this.course.courseFormat.registrationRequired) {
      this.loadingWorkflow = true;
      this.learningObjectRegService
        .get(this.course)
        .then((registration) => {
          this.workflow = this.workflowFactoryService(registration);
          callback();
        })
        .finally(() => {
          this.loadingWorkflow = false;
        });
    }
  }

  removeCourse() {
    if (this.loadingWorkflow) {
      return;
    }

    const remove = () => {
      if (this.workflow.hasAction('WithdrawAction')) {
        this.workflow.exec('WithdrawAction', {}).then(() => {
          this.registrationChanged.emit([
            this.course,
            this.workflow.registration,
          ]);
        });
      } else {
        this.notificationService.error(
          'You are unable to remove this course',
          3e3
        );
      }
    };

    if (this.workflow) {
      remove();
    } else {
      this.loadWorkflow(remove);
    }
  }

  onBookmarkClick(): void {
    if (
      this.playCourseService.saveForLaterAvailable(this.course, this.userCourse)
    ) {
      this.saveCourse();
    } else if (this.userCourse.statusId === 10) {
      this.removeCourse();
    }
  }

  saveCourse() {
    if (this.loadingWorkflow) {
      return;
    }

    const addToCurriculum = () => {
      if (this.workflow.hasAction('AddToCurriculum')) {
        this.workflow.exec('AddToCurriculum', {}).then(() => {
          this.registrationChanged.emit([
            this.course,
            this.workflow.registration,
          ]);
        });
      } else {
        this.notificationService.error(
          'You are unable to save this course',
          3e3
        );
      }
    };

    if (this.workflow) {
      addToCurriculum();
    } else {
      this.loadWorkflow(addToCurriculum);
    }
  }

  private updateVisibility() {
    this.hidden =
      this.isEmptyObject(this.userCourse) ||
      !(
        this.playCourseService.saveForLaterAvailable(
          this.course,
          this.userCourse
        ) ||
        (!this.playCourseService.saveForLaterAvailable(
          this.course,
          this.userCourse
        ) &&
          this.userCourse.statusId === 10)
      );
  }
}

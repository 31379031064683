<div class="row">
  <div class="col-xs-12">
    <h2 class="mt-0 mb-20 catalog-title">
      <span *ngIf="!catalogTitle">{{ moduleTitle || 'Catalog' }}</span>
      <span *ngIf="catalogTitle">{{catalogTitle}}</span>
    </h2>
  </div>

  <div class="col-xs-12">
    <catalog-search-filters [loading]="loading"></catalog-search-filters>
  </div>

  <div class="col-xs-12">
    <!--list of horizontal Content tiles-->
    <search-result-dep [mode]="'tiles'" [smallView]="mobileView"
                       [itemsPerPage]="10" (promise)="loading=$event"></search-result-dep>
  </div>
</div>

import { Component, Input } from '@angular/core';


@Component({
  selector: 'course-info-learners-count',
  templateUrl: './course-info-learners-count.component.html',
})

export class CourseLearnersCountComponent {
  @Input() course: {learners: number};
}

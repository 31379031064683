import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ElmsUtils } from 'core/utils';
import _ from 'lodash';
import {
  ICourseRegistration,
  IUserCourse,
  IUserCourseQueryParams
} from 'modules/course-registrations/models/course-registration.model';


enum UserCourseEndpoints {
  GET = '/a/user/:userId/courses/:courseId/',
  PUT = '/a/user/:userId/courses/:courseId/',
  QUERY = '/a/user/:userId/courses/',
  REGISTRATIONS = '/a/user/:userId/courses/registrations/',
}

export interface IUserCourseStats {
  notStarted: number,
  inProgress: number,
  completed: number,
  unsuccessfullyCompleted: number,
  countAll: number,
  total: number,
}

export interface IUserCourseQueryResponse {
  stats: IUserCourseStats,
  courses: IUserCourse[],
}

@Injectable()
export class UserCourseService {
  constructor(private http: HttpClient) {
  }

  public get(userId: number, courseId: number|string, skipWorkflow?:boolean): Observable<IUserCourse> {
    let params = new HttpParams();

    if (skipWorkflow) {
      params = params.set('skip_workflow', true);
    }

    return this.http.get<IUserCourse>(
      ElmsUtils.formatUrl(UserCourseEndpoints.GET, { userId, courseId }),
      { params },
    );
  }

  public update(userId: number, courseId: number, payload: IUserCourse): Observable<IUserCourse> {
    return this.http.put<IUserCourse>(
      ElmsUtils.formatUrl(UserCourseEndpoints.PUT, { userId, courseId }),
      payload,
    );
  }

  public query(userId: number): Observable<IUserCourseQueryResponse> {
    return this.http.get<IUserCourseQueryResponse>(
      ElmsUtils.formatUrl(UserCourseEndpoints.QUERY, { userId }),
    );
  }

  public registrations(userId: number, queryParams: IUserCourseQueryParams): Observable<ICourseRegistration[]> {
    const params = new HttpParams().set('query', JSON.stringify(_.omitBy(queryParams, _.isNil)));

    return this.http.get<ICourseRegistration[]>(
      ElmsUtils.formatUrl(UserCourseEndpoints.REGISTRATIONS, { userId }),
      { params },
    );
  }
}

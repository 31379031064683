import { Injectable } from '@angular/core';


@Injectable()
export class CourseSessionLocationService {
  getAddress(location) {
    return [location.city, location.streetAddress, location.building, location.room].filter((v) => !!v).join(', ');
  }

  getFullInfo(location) {
    return [location.streetAddress, location.zip, location.building, location.room].filter((v) => !!v).join(', ');
  }

  getMapLink(location) {
    const fullAddress = location.streetAddress + ', ' +
      location.city + ', ' + (location.state.name || location.state) + ' ' +
      location.zip + ', ' + (location.country.name || location.country);

    return ['http://maps.google.com/maps?f=q&q=', fullAddress.replace(/ /g, '+'), '&z=13'].join('');
  }
}

import { SecurityService } from 'core/services';
import { Unsubscribable, finalize, tap } from 'rxjs';
import { IBlogPost } from 'modules/blog/models/blog-post.model';
import { BlogPostService } from 'modules/blog/services/blog-post.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BlogPostRotatorService, IBlogPostRotatorState } from 'modules/blog/services/blog-rotator.service';


@Component({
  selector: 'blog-posts-rotator',
  templateUrl: './blog-posts-rotator.component.html',
})
export class BlogPostsRotatorComponent implements OnInit, OnDestroy {
  static readonly selector = 'blogPostsRotator';

  @Input() showNumeration!: boolean;
  @Input() hideAuthor!: boolean;
  @Input() directlyAssigned: boolean = null;
  @Input() postExists!: boolean;
  @Output() postExistsChange = new EventEmitter<boolean>();

  post: IBlogPost | null = null;

  readonly showNewsLink = this.securityService.isStateAvailable('main.news');

  private requestSubscriber?: Unsubscribable;

  constructor( private securityService: SecurityService,
    private blogPostRotatorService: BlogPostRotatorService,
    private blogPostService: BlogPostService
  ) {}


  ngOnInit(): void {
    const lastPostId = this.blogPostRotatorService.state.post?.id;

    if (this.directlyAssigned !== null) {
      this.blogPostRotatorService.state.directlyAssigned = this.directlyAssigned;
    }

    if (lastPostId) {
      this.requestSubscriber = this.blogPostService.get(lastPostId)
        .pipe(
          tap(post => this.updatePost(post)),
          finalize(() => this.clearRequestSubscriber())
        )
        .subscribe();
    } else {
      this.nextPost();
    }
  }

  ngOnDestroy(): void {
    this.clearRequestSubscriber();
  }

  nextPost(): void {
    this.requestSubscriber = this.blogPostRotatorService.nextPost(this.post?.id)
      .pipe(
        tap(post => this.updatePost(post)),
        finalize(() => this.clearRequestSubscriber())
      )
      .subscribe();
  }

  previousPost(): void {
    this.requestSubscriber = this.blogPostRotatorService.previousPost(this.post?.id)
      .pipe(
        tap(post => this.updatePost(post)),
        finalize(() => this.clearRequestSubscriber())
      )
      .subscribe();
  }

  get rotatorState(): IBlogPostRotatorState {
    return this.blogPostRotatorService.state;
  }


  get isLoading(): boolean { return  !!this.requestSubscriber;}

  private clearRequestSubscriber(): void {
    if (this.requestSubscriber) {
      this.requestSubscriber.unsubscribe();
      delete this.requestSubscriber;
    }
  }

  private updatePost(post: IBlogPost | null): void {
    this.post = post;

    if (post && !this.postExists) {
      this.postExists = true;
      this.postExistsChange.emit(true);
    } else if (!post && this.postExists) {
      this.postExists = false;
      this.postExistsChange.emit(false);
    }
  }
}

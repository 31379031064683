import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ElmsUtils } from 'core/utils/elms-utils';
import { ILearningObjectRegistration } from 'modules/course/common/models/learning-object-registration.model';


enum CourseRegistrationServiceAPIPath {
  GET = '/a/course_registrations/:registrationId/',
  GET_HISTORY = '/a/course_registrations/:registrationId/history/',
  BULK_UPDATE = '/a/course_registrations/',
  PUT = '/a/course_registrations/:registrationId/',
  QUERY = '/a/course_registrations/',
}

@Injectable()
export class CourseRegistrationService {
  constructor(private http: HttpClient) { }

  public get(registrationId: number): Observable<ILearningObjectRegistration> {
    return this.http.get<ILearningObjectRegistration>(
      ElmsUtils.formatUrl(CourseRegistrationServiceAPIPath.GET, { registrationId }),
    );
  }
}

// (function () {
//
//   angular.module('kmi.lms.course.registrations')
//     .factory('CourseRegistration', CourseRegistrationService);
//
//   /* @ngInject */
//   function CourseRegistrationService($resource) {
//     return $resource('/a/course_registrations/:registrationId/', {registrationId: '@id'}, {
//       query: {
//         method: 'GET',
//         isArray: true
//       },
//       update: {method: 'PUT'},
//       bulkUpdate: {
//         method: 'PUT',
//         isArray: true
//       },
//       getHistory: {
//         method: 'GET',
//         params: {registrationId: '@id'},
//         isArray: true,
//         url: '/a/course_registrations/:registrationId/history/',
//         transformResponse: function (data, headersGetter, status) {
//           if (status === 200) {
//             return angular.fromJson(data);
//           }
//
//           if (headersGetter('Content-Type') === 'application/json') {
//             return angular.fromJson(data);
//           }
//           return data;
//         }
//       }
//     }, {
//       stripTrailingSlashes: false
//     });
//   }
// })();

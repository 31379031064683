import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CurrentUserService } from 'core/authorization';
import { CalendarEventsService } from '../services/calendar-events.service';
import { NotificationService } from 'ajs/modules/app/environment/notification-service';
import { Observable, Unsubscribable, concat, finalize, map, of, reduce, switchMap, tap } from 'rxjs';
import {
  CalendarViewsMomentMap, ICalendarEvent, ICalendarListItem, ICalendarQueryParams, ICalendarState, ICalendarView,
  ICalendarViewMode
} from '../models/events.model';
import moment, { Moment } from 'moment';


@Component({
  selector: 'calendar-list-view',
  templateUrl: './calendar-list-view.component.html'
})
export class CalendarListViewComponent implements OnInit, OnChanges {
  @Input() minDate: string = null;
  @Input() maxDate: string = null;
  @Input() groupIds?: string[];
  @Input() sessionLabels?: string;
  @Input() registeredOnly: boolean;
  @Input() showRegistrationStatus: boolean;
  @Input() view: ICalendarViewMode = 'dayGridMonth';
  @Output() calendarChange = new EventEmitter<ICalendarView>();

  events: ICalendarListItem[];
  displayTitle: string;
  selectedDate: string;

  user = this.currentUserService.get();
  eventsUser = this.eventsService.user;

  fetching: boolean;
  calendarCurrentState: ICalendarState = {
    viewType: 'dayGridMonth',
    intervalUnit: 'month'
  };

  private calendarView: ICalendarView;
  private requestSubscriber?: Unsubscribable;

  constructor(
    private currentUserService: CurrentUserService,
    private eventsService: CalendarEventsService,
    private notificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.computeDateRange({ date: this.getDefaultDate(this.minDate, this.maxDate), viewType: this.view }, false);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.minDate || changes.maxDate || changes.view) &&
      (!changes.minDate?.firstChange || !changes.maxDate?.firstChange || !changes.view?.firstChange)) {
      const { view, maxDate, minDate } = this.calendarView;

      if (this.view !== view || this.minDate !== minDate || this.maxDate !== maxDate) {
        this.computeDateRange({ date: this.getDefaultDate(this.minDate, this.maxDate), viewType: this.view }, false);
      }
    }

    if ((changes.registeredOnly || changes.groupIds || changes.sessionLabels) &&
      (!changes.registeredOnly?.firstChange || !changes.groupIds?.firstChange || !changes.sessionLabels?.firstChange)) {
      this.fetchEvents(this.getQueryParams());
    }
  }

  computeMonthRange() {
    this.computeDateRange({ viewType: 'dayGridMonth' });
  }

  computeWeekRange() {
    this.computeDateRange({ viewType: 'timeGridWeek' });
  }

  computeDayRange() {
    this.computeDateRange({ viewType: 'timeGridDay' });
  }

  next() {
    this.computeDateRange({ date: this.computeNextDate() });
  }

  prev() {
    this.computeDateRange({ date: this.computePrevDate() });
  }

  today() {
    this.computeDateRange({ date: moment().utc(), viewType: 'timeGridDay' });
  }

  onDatePickerSelected() {
    if (this.selectedDate) {
      this.computeDateRange({ date: moment(this.selectedDate), viewType: 'timeGridDay' });
    }
  }

  get processing(): boolean {
    return !!this.requestSubscriber;
  }

  private fetchEvents(query: ICalendarQueryParams) {
    this.fetching = true;
    this.notificationService.info('Loading');
    this.requestSubscriber?.unsubscribe();
    this.requestSubscriber = this.eventsService.events(query)
      .pipe(
        map(events => events.sort((a, b) => a.start.localeCompare(b.start))
          .filter(e => this.filter(e))
          .map(e => this.transform(e))),
        tap(items => {
          this.fetching = false;
          this.events = items;
        }),
        switchMap(items => this.loadRegistrations(items)),
        finalize(() => {
          this.notificationService.visibleInfo(false);
          this.requestSubscriber?.unsubscribe();
          delete this.requestSubscriber;
        })
      ).subscribe();
  }

  private filter(e: ICalendarEvent): boolean {
    return moment(e.start).clone().utc(true)
      .isBetween(this.calendarCurrentState.startInterval, this.calendarCurrentState.endInterval) ||
          moment(e.start).clone().utc(true).isSame(this.calendarCurrentState.startInterval) ||
          moment(e.start).clone().utc(true).isSame(this.calendarCurrentState.endInterval);
  }

  private transform(event: ICalendarEvent): ICalendarListItem {
    return {
      ...event,

      course: {
        id: event.id,
        name: event.name,
        courseFormat: event.courseFormat,
        format: event.format,
        formatId: event.formatId,
        formatName: event.courseFormat.name,
        formatTypeId: event.courseFormat.typeId,
        approvalStatusId: event.approvalStatusId,
        creditTypes: event.creditTypes,
        providesMultipleCredits: event.providesMultipleCredits,
        courseLocation: event.locationTypeId ? { typeId: event.locationTypeId } : null,
      },
      contentOpen: false,
      sponsor: event.sponsor,
      startTime: moment(event.start).format('LT'),
      endTime: moment(event.end).format('LT'),
      displayDate: !(/day/.test(this.calendarCurrentState.intervalUnit)) ? moment(event.start).format('LL') : null
    };
  }

  private loadRegistrations(items: ICalendarListItem[]): Observable<null> {
    if (this.showRegistrationStatus && !this.registeredOnly) {
      const courses = items.filter(i => i.type === 'course');
      const queue: Observable<ICalendarEvent[]>[] = [];
      const sessionIds = courses.filter(i => !i.conferenceSessionId).map(i => i.sessionId);
      const conferenceSessionIds = courses.filter(i => i.conferenceSessionId).map(i => i.sessionId);

      if (sessionIds.length) {
        queue.push(this.eventsService.registrations(items, this.user.id, sessionIds));
      }

      if (conferenceSessionIds.length) {
        queue.push(this.eventsService.conferenceRegistrations(items, this.user.id, conferenceSessionIds));
      }

      if (queue.length) {
        return concat(...queue)
          .pipe(
            map(events => events.map(e => this.transform(e))),
            tap(allEvents => {
              if (allEvents.length) {
                allEvents.forEach(e => {
                  this.events.forEach(i => {
                    if (((i.sessionId === e.sessionId && e.sessionId !== null) || i.conferenceSessionId ===
                        e.conferenceSessionId && e.conferenceSessionId !== null) &&
                      moment(i.start).clone().utc(true).isSame(moment(e.start).clone().utc(true)) &&
                      moment(i.end).clone().utc(true).isSame(moment(e.end).clone().utc(true))) {
                      Object.assign(i, e);
                    }
                  });
                });
              }
            }),
            reduce(() => null)
          );
      }
    }

    return of(null);
  }

  private computeDateRange(params: Partial<ICalendarState>, emitEvents = true) {
    const zeroTime = {
      hours: 0,
      minutes: 0,
      seconds: 0,
      ms: 0
    };

    const calendarState: ICalendarState = {
      date: params.date || this.calendarCurrentState.date,
      viewType: params.viewType || this.calendarCurrentState.viewType,
      intervalUnit: (params.viewType
        ? CalendarViewsMomentMap[params.viewType]
        : this.calendarCurrentState.intervalUnit) || 'dayGridMonth',
      start: params.start || this.calendarCurrentState.start,
      end: params.end || this.calendarCurrentState.end
    };

    //compute range
    const intervalDuration = moment.duration(1, calendarState.intervalUnit);
    const intervalStart = calendarState.date.clone().startOf(calendarState.intervalUnit);
    const intervalEnd = intervalStart.clone().add(intervalDuration);

    calendarState.start = intervalStart.clone();
    calendarState.end = intervalEnd.clone();
    calendarState.start.utc(true);
    calendarState.start.set(zeroTime);
    calendarState.end.utc(true);
    calendarState.end.set(zeroTime);

    if (/year|month/.test(calendarState.intervalUnit)) {
      calendarState.start.startOf('week');

      // make end-of-week if not already
      if (calendarState.end.weekday()) {
        calendarState.end.add(1, 'week').startOf('week');
      }
    }

    if (/year|month/.test(calendarState.intervalUnit)) {
      calendarState.startInterval = calendarState.date.clone().utc(true).startOf(calendarState.intervalUnit);
      calendarState.endInterval = calendarState.startInterval.clone().utc(true).add(intervalDuration).subtract(1);
    } else {
      calendarState.startInterval = calendarState.start;
      calendarState.endInterval = calendarState.end.clone().subtract(1);
    }

    this.computeTitle(calendarState);
    this.calendarCurrentState = calendarState;
    this.calendarView = {
      minDate: calendarState.start.format('YYYY-MM-DD'),
      maxDate: calendarState.end.format('YYYY-MM-DD'),
      view: calendarState.viewType
    };

    if (emitEvents) {
      this.calendarChange.emit(this.calendarView);
    }

    this.fetchEvents(this.getQueryParams());
  }

  private getQueryParams(): ICalendarQueryParams | null {
    if (this.calendarCurrentState.date.format() === 'Invalid date') {
      return null;
    }

    const params: ICalendarQueryParams = {
      view: this.calendarCurrentState.viewType,
      group_id: this.groupIds?.join(','),
      user_courses: this.registeredOnly || null,
      session_label_id: this.sessionLabels,
      min_start_date: this.calendarCurrentState.start.format('YYYY-MM-DD'),
      max_start_date: this.calendarCurrentState.end.format('YYYY-MM-DD')
    };

    return params;
  }

  private computeTitle(state: ICalendarState) {
    const calendarState = Object.assign({}, state) as ICalendarState;
    const intervalUnit = calendarState.intervalUnit;
    const intervalDuration = moment.duration(1, intervalUnit);
    let start: Moment, end: Moment;

    if (/year|month/.test(intervalUnit)) {
      start = calendarState.date.clone().startOf(intervalUnit);
      end = start.clone().add(intervalDuration).subtract(1);
    } else {
      start = calendarState.start;
      end = calendarState.end.clone().subtract(1);
    }

    if (/year|month/.test(intervalUnit)) {
      this.displayTitle = end.clone().format('MMMM') + ' ' + end.clone().format('YYYY');
    } else if (intervalDuration.as('days') > 1 && start.format('YYYY') === end.format('YYYY')) {
      this.displayTitle = start.format('MMM D') + ' \u2013 ' +
        (start.format('MMMM') === end.format('MMMM') ? end.format('D') : end.format('MMM D')) + ', ' +
        end.format('YYYY');
    } else if (/week/.test(intervalUnit) && start.format('YYYY') !== end.format('YYYY')) {
      this.displayTitle = start.format('MMM DD') + ', ' + start.format('YYYY') + ' \u2013 ' + end.format('MMM DD') +
        ', ' + end.format('YYYY');
    } else {
      this.displayTitle = start.format('LL');
    }
  }

  private computeNextDate() {
    const range = this.calendarCurrentState;

    return range.date.clone().startOf(range.intervalUnit).add(moment.duration(1, range.intervalUnit));
  }

  private computePrevDate() {
    const range = this.calendarCurrentState;

    return range.date.clone().startOf(range.intervalUnit).subtract(moment.duration(1, range.intervalUnit));
  }

  private getDefaultDate(start: string, end: string): moment.Moment {
    if (start) {
      if (end && end !== start) {
        const startDate = moment(start);
        const diffDays = moment(end).diff(startDate, 'days');

        if (diffDays > 0) {
          startDate.add(Math.floor(diffDays / 2), 'day');
        }

        start = startDate.format('YYYY-MM-DD');
      }

      return moment(start);
    }

    return moment();
  }
}

<span
  *ngIf="course.length"
  [ngPlural]="course.length"
  class="tw-whitespace-nowrap tw-flex tw-items-center tw-gap-1.5"
>
  <i class="fa-regular fa-clock tw-opacity-70" aria-hidden="true"></i
  >{{ course.length }}
  <ng-template ngPluralCase="=1">hour</ng-template>
  <ng-template ngPluralCase="other">hours</ng-template>
</span>

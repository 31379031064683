import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ElmsUtils } from 'core/utils';
import { ICourseQuiz } from 'modules/course/quiz/models/course-quiz.models';


export interface ICourseQuizQuery{
  courseId:number|string;
  quizId:number;
  id?:number;
}
enum CourseQuizEndpoints {
  GET = '/a/course/:courseId/quizzes/:quizId/',
}

@Injectable()
export class CourseQuizService {
  constructor(private http: HttpClient) { }

  public get(query:ICourseQuizQuery): Observable<ICourseQuiz> {
    let params = new HttpParams();

    if (query.id) {
      params = params.set('id', query.id.toString());
    }

    return this.http.get<ICourseQuiz>(
      ElmsUtils.formatUrl(CourseQuizEndpoints.GET, { courseId: query.courseId, quizId: query.quizId }),
      { params }
    );
  }
}

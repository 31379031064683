import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'single-star-rating',
  templateUrl: './single-star-rating.component.html',
})
export class SingleStarRatingComponent implements OnInit {
  static readonly selector = 'starRating';

  @Input() rating: number;


  ngOnInit() {
    this.rating = this.rating || 0;
  }

  toPrecision(num: number): string {
    return num ? num.toPrecision(2) : '';
  }
}

<article
  *ngIf="courseTile"
  class="tw-p-4 tw-isolate tw-snap-center tw-snap-always tw-bg-gray-2 tw-flex tw-flex-col tw-gap-2 tw-h-full tw-border-gray-3 tw-text-gray-12 tw-border tw-rounded-xl"
>
  <a
    uiSref="main.course"
    [uiParams]="{ id: courseTile.id, currentBlsId: currentBlsId }"
    title="Open {{ courseTile.name || courseTile.title }}"
    [trackLinkClick]="trackingName"
    [id]="trackingName + '_course_link'"
    tabindex="-1"
    class="tw-relative"
  >
    <course-thumbnail
      class="tw-w-full"
      [course]="courseTile"
    ></course-thumbnail>
    <div
      class="tw-top-2 tw-right-2 tw-absolute tw-bg-gray-2 tw-rounded-md tw-text-base tw-px-2 tw-py-1 tw-text-gray-11 tw-font-medium"
      *ngIf="hasPrice(courseTile.price)"
    >
      {{ formatPrice(courseTile.price) }}
    </div>
  </a>

  <h3 class="tw-text-lg tw-line-clamp-3 tw-m-0 tw-p-0 tw-font-medium">
    <a
      uiSref="main.course"
      [uiParams]="{ id: courseTile.id, currentBlsId: currentBlsId }"
      title="Open {{ courseTile.name || courseTile.title }}"
      [trackLinkClick]="trackingName"
      [id]="trackingName + '_course_link'"
      class="tw-text-brand-9 hover:tw-text-brand-10 tw-rounded focus-visible:tw-outline-brand-7 focus-visible:tw-text-brand-11 focus:tw-outline-none"
    >
      {{ courseTile.name || courseTile.title }}
    </a>
  </h3>

  <p class="tw-line-clamp-3 tw-p-0 tw-m-0">
    {{ courseTile.description | markdownToHtml | htmlToPlainText }}
  </p>

  <p
    *ngIf="courseTile.organization_name || courseTile.createdBy?.firstName"
    class="tw-text-gray-11 tw-text-base tw-p-0 tw-m-0"
  >
    by
    {{
      showOrganizationName
        ? courseTile.organization_name
        : courseTile.createdBy?.firstName + ' ' + courseTile.createdBy?.lastName
    }}
  </p>

  <course-expiration-date
    [variant]="'secondary'"
    [course]="courseTile"
    [short]="true"
    *ngIf="courseTile.expirationDate || courseTile.deactivationDate"
  ></course-expiration-date>

  <div *ngIf="courseTile.startDate || courseTile.endDate">
    <span>Start <span class="tw-sr-only"> date </span></span>
    <span class="delimiter" aria-hidden="true">&#x2022;</span>
    <date-with-hidden-time
      [date]="courseTile.startDate"
    ></date-with-hidden-time>
    <span class="delimiter" aria-hidden="true">&#x2022;</span>
    <span>End <span class="tw-sr-only"> date </span></span>
    <span class="delimiter" aria-hidden="true">&#x2022;</span>
    <date-with-hidden-time [date]="courseTile.endDate"></date-with-hidden-time>
  </div>

  <div class="tw-flex tw-gap-3 tw-flex-wrap tw-text-base">
    <course-star-rating
      [course]="courseTile"
      [singleStar]="true"
      class="tile-rating"
    ></course-star-rating>

    <course-info-learners-count
      [course]="courseTile"
      *ngIf="courseTile.learners > 0"
    >
    </course-info-learners-count>

    <course-length
      [course]="courseTile"
      *ngIf="courseTile.length"
    ></course-length>

    <div *ngIf="courseTile.certificates?.length > 0">
      <i class="fa-solid fa-award" aria-hidden="true"></i>
      <span>Certificate</span>
    </div>
  </div>

  <div class="tw-flex tw-items-center tw-gap-2">
    <course-info-indicators-board
      [course]="courseTile"
      [userCourse]="userCourse"
    ></course-info-indicators-board>

    <course-bookmark
      [trackingName]="trackingName"
      (registrationChanged)="onRegistrationChanged($event)"
      *ngIf="!hideBookmark"
      [course]="courseTile"
      [userCourse]="userCourse"
    ></course-bookmark>
  </div>

  <div class="print:tw-hidden" *ngIf="showRegManagement">
    <course-registration-status
      [course]="courseTile"
      [regData]="userCourse"
      mode="modern"
      *ngIf="completed"
      [showHightStatus]="true"
      [showIcon]="true"
    ></course-registration-status>
    <fieldset [disabled]="frozen">
      <play-course
        [course]="courseTile"
        [mode]="mode"
        [userCourse]="userCourse"
        *ngIf="!completed"
        [trackingName]="trackingName"
        [actionData]="actionData"
      >
      </play-course>
    </fieldset>
  </div>
  <ng-content></ng-content>
</article>

import { NgModule } from '@angular/core';
import { CourseService } from 'modules/course/common/services/course.service';
import {
  CourseInfoIndicatorsBoardComponent
} from 'modules/course/common/components/course-info-indicators-board.component';
import { LmsComponentsModule } from 'components/kmi.lms.components.module';
import { UIRouterModule } from '@uirouter/angular';
import { CommonModule } from '@angular/common';
import { playCourseServiceProvider } from 'modules/course/common/services/play-course.service.ajs-upgraded-provider';
import { CourseLengthComponent } from 'modules/course/common/components/course-length.component';
import { CourseThumbnailComponent } from 'modules/course/common/components/course-thumbnail.component';
import { CourseRegistrationCommonModule } from 'modules/course-registrations/course-registration-common.module';
import { CourseExpirationDateComponent } from 'modules/course/common/components/course-expiration-date.component';
import { StarRatingComponent } from 'modules/course/common/components/star-rating.component';
import { CoursePublishDateComponent } from 'modules/course/common/components/labels/course-publish-date.component';
import { CourseStarRatingComponent } from 'modules/course/common/components/course-star-rating.component';
import { CourseReviewsStatComponent } from 'modules/course/common/components/course-reviews-stat.component';
import { NgbProgressbar } from '@ng-bootstrap/ng-bootstrap';
import { SingleStarRatingComponent } from './components/single-star-rating.component';
import { CourseBookmarkComponent } from './components/course-bookmark.component';
import { CourseLearnersCountComponent } from 'modules/course/common/components/course-info-learners-count.component';
import { CourseEditActionsComponentDirective } from './components/course-edit-actions.component.upgraded';
import { CourseCeDatesLabelComponent } from './components/labels/ce-dates-label.component';
import { EditWayOfAttendanceComponent } from './modal/edit-way-of-attendance.component';
import { TrainingCategorySearchService } from './external/services/training-category-search.service';


@NgModule({
  imports: [
    CommonModule,
    UIRouterModule,
    LmsComponentsModule,
    CourseRegistrationCommonModule,
    NgbProgressbar,
  ],
  declarations: [
    CourseInfoIndicatorsBoardComponent,
    CourseLengthComponent,
    CourseThumbnailComponent,
    CourseExpirationDateComponent,
    StarRatingComponent,
    CoursePublishDateComponent,
    CourseStarRatingComponent,
    CourseReviewsStatComponent,
    SingleStarRatingComponent,
    CourseBookmarkComponent,
    CourseLearnersCountComponent,
    CourseEditActionsComponentDirective,
    CourseCeDatesLabelComponent,
    EditWayOfAttendanceComponent,
  ],
  providers: [
    CourseService,
    TrainingCategorySearchService,
    playCourseServiceProvider
  ],
  exports: [
    CourseInfoIndicatorsBoardComponent,
    CourseExpirationDateComponent,
    StarRatingComponent,
    CoursePublishDateComponent,
    CourseThumbnailComponent,
    CourseStarRatingComponent,
    CourseReviewsStatComponent,
    CourseLengthComponent,
    CourseBookmarkComponent,
    CourseLearnersCountComponent,
    CourseEditActionsComponentDirective,
    CourseCeDatesLabelComponent,
    EditWayOfAttendanceComponent,
  ],
})
export class CourseCommonModule {}

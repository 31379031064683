import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LmsComponentsModule } from 'components/kmi.lms.components.module';
import { CourseCommonModule } from 'modules/course/common/course-common.module';
import { CourseSessionService } from 'modules/course/sessions/services/course-session.service';
import { CheckoutComponent } from './components/checkout.component';
import { ECommerceProductComponent } from './components/e-commerce-product.component';
import { EcommercePurchaseService } from './services/ecommerce-purchase.service';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { ecommerceCheckoutRoutingModule } from './ecommerce-checkout-routing.module';
import { CheckoutConfirmationComponent } from './components/checkout-confirmation.component';
import { UserModule } from 'modules/user/user.module';
import { CourseService } from 'modules/course/common/services/course.service';


@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    HttpClientModule,
    LmsComponentsModule,
    CourseCommonModule,
    UserModule,
    UIRouterUpgradeModule.forChild({ states: ecommerceCheckoutRoutingModule }),
  ],
  declarations: [
    CheckoutComponent,
    ECommerceProductComponent,
    CheckoutConfirmationComponent
  ],
  providers: [
    CourseSessionService,
    CourseService,
    EcommercePurchaseService
  ]
})
export class EcommerceCheckoutModule {}

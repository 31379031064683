<div class="flex">
  <div class="flex-row tile-course-row">
    <div class="flex-col flex-col-9x hidden-xs col-thumbnail">
      <course-thumbnail [course]="course" class="course-player-thumbnail"></course-thumbnail>
    </div>

    <div class="flex-col flex-col-grow">
        <a [name]="course.id" href="#" (click)="confirm()" title="Go to {{course.name }}" [id]="trackingName+'_course_link2'" [trackLinkClick]="trackingName">
          {{course.name}}
        </a>
    </div>
  </div>
</div>


import { NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { coursePlayerRoutingModule } from 'modules/course/player/course-player-routing.module';
import { CommonModule } from '@angular/common';
import { LmsComponentsModule } from 'components/kmi.lms.components.module';
import { CollectionPlayerComponent } from 'modules/course/player/collection/components/collection-player.component';
import { CourseCommonModule } from 'modules/course/common/course-common.module';
import {
  CollectionStructureLayerComponent
} from 'modules/course/player/collection/components/collection-structure-layer.component';
import { TranscriptButtonComponent } from 'modules/course/player/components/transcript-button.component';
import { CourseReviewsModule } from 'modules/course/reviews/course-reviews.module';
import { playerServiceProvider } from 'modules/course/player/services/player.service';
import {
  IncompleteConfirmationComponent
} from 'modules/course/player/components/modal/incomplete-confirmation.component';
import { CoursePlayerComponent } from 'modules/course/player/components/course-player.component';
import { CourseQuizPlayerComponent } from 'modules/course/player/course-quiz/course-quiz-player.component';
import { CourseQuizService } from 'modules/course/quiz/services/course-quiz.service';
import { QuizModule } from 'modules/quiz/quiz.module';
import { CourseContentViewComponent } from 'modules/course/player/components/course-content-view.component';
import { ExternalContentViewComponent } from 'modules/course/player/components/external-content-view.component';
import { CourseContentPlayerComponent } from 'modules/course/player/view/course-content-player.component';
import { NextStepPromptComponent } from 'modules/course/player/components/modal/next-step-prompt.component';
import { CoursePlayerMenuComponent } from 'modules/course/player/components/course-player-menu.component';
import {
  PlayerCollectionComponentsComponent
} from 'modules/course/player/components/player-collection-component.component';
import { CollectionTileComponent } from 'modules/course/player/components/collection-tile.component';
import {
  PlayerRelatedCollectionsComponent
} from 'modules/course/player/components/player-related-collections.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { CourseRegistrationCommonModule } from 'modules/course-registrations/course-registration-common.module';
import { InternalContentViewComponent } from 'modules/course/player/components/internal-content-view.component';


@NgModule({
  imports: [
    NgbDropdownModule,
    CommonModule,
    QuizModule,
    LmsComponentsModule,
    UIRouterUpgradeModule.forChild({ states: coursePlayerRoutingModule }),
    CourseCommonModule,
    CourseReviewsModule,
    CourseRegistrationCommonModule,
  ],
  exports: [],
  declarations: [
    CollectionPlayerComponent,
    CollectionStructureLayerComponent,
    TranscriptButtonComponent,
    IncompleteConfirmationComponent,
    CoursePlayerComponent,
    CourseQuizPlayerComponent,
    CourseContentViewComponent,
    ExternalContentViewComponent,
    CourseContentPlayerComponent,
    NextStepPromptComponent,
    CoursePlayerMenuComponent,
    PlayerCollectionComponentsComponent,
    CollectionTileComponent,
    PlayerRelatedCollectionsComponent,
    InternalContentViewComponent,
  ],
  providers: [
    playerServiceProvider,
    CourseQuizService
  ]
})
export class CoursePlayerModule {}

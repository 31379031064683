<fieldset ngModelGroup="emailForm">
  <div class="row">
    <div class="col-xs-12">
      <div class="form-horizontal">
        <div *ngIf="email.recipients?.length > 50 || email.recipientsProvider" class="alert alert-info text-center">
          {{email.recipients?.length || email.recipientsProvider?.count || 'All'}} recipients selected
          <input type="hidden" name="recipients" [(ngModel)]="allRecipients" />
          <input type="hidden" name="recipientsProvider" [(ngModel)]="email.recipientsProvider" />
        </div>

        <div *ngIf="email.recipients?.length <= 50 && !email.recipientsProvider">
          <label for="recipients" class="w-100">
            <ng-select name="recipients" addTagText="Add Email" placeholder="Add Emails" labelForId="recipients"
                       [required]="!recipientsReadOnly"
                       [items]="email.recipients" [hideSelected]="true" [multiple]="true"
                       [addTag]="addTagPredicate" [searchFn]="searchPredicate" (search)="onSearch($event)"
                       [(ngModel)]="allRecipients" [readonly]="recipientsReadOnly || formOptions?.pending">
              <ng-template ng-label-tmp let-item="item">
                <span class="ui-select-match-item btn btn-default btn-xs" [ngClass]="{'disabled': recipientsReadOnly}">
                  <span *ngIf="item.lastName">{{item.lastName}}, {{item.firstName}} &lt;{{item.email}}&gt;</span>
                  <span *ngIf="!item.lastName">{{item.email}}</span>
                  <button type="button" class="close ui-select-match-close" [hidden]="recipientsReadOnly"
                          (click)="removeEmail(item.email)">&nbsp;×</button>
                </span>
              </ng-template>
              <ng-template ng-option-tmp let-item="item">
                <div *ngIf="item.tag">{{item.email}} <small>(Add)</small></div>
                <div *ngIf="!item.tag">
                  <div *ngIf="item.lastName">
                    {{item.lastName}}, {{item.firstName}} <small>email: {{item.email}}</small>
                  </div>
                  <div *ngIf="!item.lastName">{{item.email}}</div>
                </div>
              </ng-template>
              <ng-template ng-tag-tmp let-search="searchTerm">
                <span *ngIf="taggedEmailValid">add email: "{{search}}"</span>
                <span *ngIf="!taggedEmailValid" class="ng-invalid">email is not valid: "{{search}}"</span>
              </ng-template>
            </ng-select>
          </label>
        </div>

        <div class="form-group sm-margin-top" *ngIf="receiveEmails">
          <div class="col-sm-9">
            <div class="checkbox">
              <label for="copyRequested">
                <input name="copyRequested" id="copyRequested" type="checkbox" [disabled]="formOptions?.pending"
                       [(ngModel)]="email.copyRequested" /> Copy myself on this email
              </label>
            </div>
          </div>
        </div>

        <!--Subject-->
        <div class="form-group" [ngClass]="{'has-error': emailSubject.dirty && emailSubject.invalid}">
          <label for="subject" class="col-sm-2 control-label text-left">Subject</label>
          <div class="col-sm-10">
            <input type="hidden" name="globalId" [(ngModel)]="email.globalId">
            <input type="text" id="subject" name="subject" class="form-control required" noWhiteSpace autoFocus
                   [disabled]="formOptions?.pending"
                   [(ngModel)]="email.subject" #emailSubject="ngModel" required maxlength="255" />
            <p class="help-block" *ngIf="emailSubject.invalid && emailSubject.dirty">
              <span>Subject is Required.</span>
            </p>
          </div>
        </div>

        <!--Body-->
        <div class="form-group" [ngClass]="{ 'has-error': emailBody.dirty && emailBody.invalid }">
          <label [for]="'body'" class="col-sm-2 control-label text-left">Body</label>
          <div class="col-sm-10">
            <markdown-editor-component [id]="'body'" name="body" [disableAttachments]="true" noWhiteSpace
                                       [(ngModel)]="email.body" #emailBody="ngModel" [rows]="12"
                                       [disabled]="formOptions?.pending"
                                       [required]="true">
            </markdown-editor-component>
            <p class="help-block" *ngIf="emailBody.invalid && emailBody.dirty">
              <span>Email Text is Required.</span>
            </p>
          </div>
        </div>

        <!--Attachments-->
        <div class="form-group" [ngClass]="{ 'has-error': attachments.dirty && attachments.invalid }">
          <label for="attachments" class="col-sm-2 control-label text-left">Attach file(s)</label>
          <div class="col-sm-10">
            <email-attachments id="attachments" name="attachments" #attachments="ngModel"
                               [disabled]="formOptions?.pending"
                               [(ngModel)]="email.attachments"></email-attachments>
            <p class="help-block" *ngIf="attachments.invalid && attachments.dirty">
              <span>The total size of the selected files is greater than the limit.</span>
            </p>
          </div>
        </div>

      </div>
    </div>
  </div>
</fieldset>

<div class="row">
  <div class="col-xs-12">
    <div class="d-flex align-items-center gap-15">
      <a href="#" (click)="goBack()" class="modern-back-button btn btn-link" title="Go back"><i class="fa fa-chevron-left"></i>Back</a>
      <a uiSref="edit.discussion" [uiParams]="{topicId: discussionTopic.id}"
        *ngIf="isEditable" title="Edit discussion">Edit</a>
    </div>
  </div>
</div>

<h3>{{discussionTopic.title}}</h3>

<div class="row">
  <div class="col-xs-12">
    <div>
      <p *ngIf="discussionTopic.createdBy.firstName && discussionTopic.createdBy.lastName">
        Posted by <span><strong>{{discussionTopic.createdBy.firstName}}&nbsp;{{discussionTopic.createdBy.lastName}}</strong></span> <span class="text-muted">{{discussionTopic.createdDate|date:'mediumDate'}}</span>
      </p>
      <p  *ngIf="!discussionTopic.createdBy.firstName || !discussionTopic.createdBy.lastName">
        Posted by <span><strong>DELETED</strong></span> <span class="text-muted">{{discussionTopic.createdDate|date:'mediumDate'}}</span>
      </p>
      <p class="standard-margin-bottom" [innerHtml]="discussionTopic.description|markdownToHtml"></p>
    </div>
  </div>
</div>


<!--Posts Search-->
<div class="row">
  <form class="col-sm-10 col-xs-12 mb-20" (submit)="search()">
    <fieldset [disabled]="loading" class="mb-0">
      <div class="input-group  no-margin-top no-margin-bottom">
        <input class="form-control"
               id="search_discussion"
               aria-label="Search in discussions"
               placeholder="Search in discussion"
               type="search"
               name="searchQuery"
               [(ngModel)]="searchQuery"
               (keydown.enter)="search()">
        <span class="input-group-btn">
      <button class="btn btn-default ladda-button" title="Search" [ladda]="loading" type="submit"
              data-spinner-color="#000">
        <span class="fa fa-search" aria-hidden="true"></span>
        <span class="sr-only">Search in threads</span>
      </button>
    </span>
      </div>
    </fieldset>
  </form>
  <div class="col-sm-2 col-xs-12 mb-20">
    <button type="button" title="Start new thread"
            class="btn btn-default btn-block" [trackLinkClick]=''
            (click)="prepareNewPost()" [id]="trackingName+'_new_post'">
      Start thread
    </button>
  </div>
</div>

<div class="alert alert-sm alert-info" *ngIf="itemCount === 0 && !loading">There are no records found.</div>

<div class="row" *ngIf="itemCount > 0 || activities.length" infiniteScroll (infiniteScrollAction)="loadMore()">
  <div class="col-sm-12 col-xs-12">
    <div class="d-flex flex-direction-column gap-20">
      <network-activity *ngFor="let activity of activities"
                        [allowLikes]="false"
                        [isEditable]="isEditable"
                        [query]="searchQuery"
                        [showMarkdown]="true"
                        [activity]="activity"
                        [activityOrigin]="activityOrigin"
                        [showAvatar]="false"
                        [trackingName]="trackingName"
                        [systemMessagesEnable]="false"></network-activity>
    </div>
  </div>
</div>

<div *ngIf="loading" class="alex">
  <div class="load-spinner" role="status" aria-live="polite" aria-label="Loading discussions"></div>
</div>

import { IBlogPost } from './../models/blog-post.model';
import { Injectable } from '@angular/core';
import { BlogPostService } from './blog-post.service';
import { BehaviorSubject, Unsubscribable, tap, finalize } from 'rxjs';


export interface HttpRecentRequestParams extends Record<string, number | string | boolean>{
  offset?: number;
  take: number;
  channelId: number;
}

export const RECENT_CHANNEL_ID = 1;
export const SEARCH_REQUEST_ITEM_COUNT = 20;

@Injectable()
export class RecentBlogPostService {
  public recentBlogPosts = new BehaviorSubject<IBlogPost[]>([]);

  private _canLoadMore = true;
  private _isNewsAvailable = true;
  private loadQueueSubscriber?: Unsubscribable;
  private readonly recentParams: HttpRecentRequestParams = {
    offset: 0,
    take: SEARCH_REQUEST_ITEM_COUNT,
    channelId: RECENT_CHANNEL_ID
  };

  constructor( private blogPostService: BlogPostService ) {}

  loadMore(): void {
    if (!this._canLoadMore || this.loadQueueSubscriber) {
      return;
    }

    this.loadQueueSubscriber = this.blogPostService.queue(this.recentParams)
      .pipe(
        tap(response => {
          this._canLoadMore = (response.items?.length || 0) === this.recentParams.take;
          const nextVal = this.recentBlogPosts.value.concat(
            response.items.map(blogPost => this.blogPostService.transform(blogPost))
          );

          if (nextVal.length === 0) {
            this._isNewsAvailable = false;
          }

          this.recentBlogPosts.next(nextVal);
        }),
        finalize(() => {
          if (this.canLoadMore) {
            this.recentParams.offset += SEARCH_REQUEST_ITEM_COUNT;
          }

          this.unsubscribeQueueLoader();
        })
      ).subscribe();
  }

  get canLoadMore(): boolean {
    return this._canLoadMore;
  }

  get isLoading(): boolean {
    return !!this.loadQueueSubscriber;
  }

  get isNewsAvailable(): boolean {
    return this._isNewsAvailable;
  }

  private unsubscribeQueueLoader(): void {
    if (this.loadQueueSubscriber) {
      this.loadQueueSubscriber.unsubscribe();
      delete this.loadQueueSubscriber;
    }
  }
}

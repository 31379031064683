import { Component, HostListener, Inject, Input, OnInit } from '@angular/core';
import { GlobalConfig } from 'core/environment';
import { CurrentUserService } from 'ajs/modules/app/current-user.service';
import { BackUrlService } from 'core/navigation/back-url.service.ajs-upgraded-provider';
import {
  CourseRegistrationStatus, courseRegistrationStatuses
} from 'modules/course-registrations/models/course-registration-status.model';
import { ILearningObjectRegistration } from 'modules/course/common/models/learning-object-registration.model';
import { CourseMediaType, ICourseDetails, ICourseMedia } from 'modules/course/common/models/course.model';
import {
  IWorkflowEvent,
  LearningObjectRegistrationWorkflowFactory,
  LearningObjectRegistrationWorkflowService,
  LearningObjectRegistrationWorkflowServiceFactory
} from 'modules/course-registrations/services/learning-object-registration-workflow.service.ajs-upgraded-provider';
import { catchError, EMPTY, filter, finalize, map, Observable, of, Subject, switchMap, tap } from 'rxjs';
import {
  CourseComponentsService,
  IFlattenCourse
} from 'modules/course/services/course-components.service.ajs-upgraded-provider';
import { ICollectionItemBase } from 'modules/course/common/models/learning-object-structure.model';
import { StateService, UIRouterGlobals, UrlService } from '@uirouter/core';
import { NotificationService } from 'ajs/modules/app/environment/notification-service';
import { CourseService } from 'modules/course/common/services/course.service';
import {
  LearningObjectRegistrationService
} from 'modules/course-registrations/services/learning-object-registration.service.ajs-upgraded-provider';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { CollectionsService, IStatsInfo } from 'modules/course/services/collections.service.ajs-upgraded-provider';
import { TrainingService } from 'modules/course-registrations/services/training.service.ajs-upgraded-provider';
import { CourseReviewService } from 'modules/course/reviews/services/course-review.service';
import { ICourseReview } from 'modules/course/reviews/models/course-review.model';
import { IPlayerStep, Player } from '../../services/player.service';
import { ScrollService } from 'core/services/scroll.service';


export interface ICollectionWithRegistration extends IFlattenCourse, ICourseDetails {
  registration?: ILearningObjectRegistration;
  status_id: number;
}

@Component({
  selector: 'collection-player',
  templateUrl: './collection-player.component.html'
})
export class CollectionPlayerComponent implements OnInit {
  static readonly selector = 'collectionPlayer';

  @Input() collection: ICourseDetails;
  @Input() collectionRegistration: ILearningObjectRegistration;

  userCourse: ICollectionWithRegistration;
  CourseRegistrationStatus = CourseRegistrationStatus;
  playlistVisible = true;
  playlistUpdated = false;
  settings = this.globalConfig.settings?.coursePlayer?.collectionPlaylist;
  currentCourse?: ICollectionWithRegistration;
  courseRegistration?: ILearningObjectRegistration;
  compilationStructure?: ICollectionItemBase[];
  flattenCourseList?: IFlattenCourse[];
  loading?: string;
  courseLoading?: boolean;
  dialog?: string;
  currentCollectionAction?: string;
  nextCourse: ICollectionItemBase;
  prevCourse: ICollectionItemBase;
  article: ICourseMedia;
  player: Player;
  collectionPlayer: Player;
  collectionWorkflow: LearningObjectRegistrationWorkflowService;
  compilationStat: IStatsInfo;
  reviewAdded: boolean;
  eventsSubject = new Subject<IWorkflowEvent>();
  closeSubject = new Subject<void>();
  newReview: ICourseReview;
  mobileView: boolean;
  finishedCollectionStep?: IPlayerStep;

  constructor(
    private currentUser: CurrentUserService,
    private backUrlService: BackUrlService,
    private globalConfig: GlobalConfig,
    private window: Window,
    @Inject(LearningObjectRegistrationWorkflowFactory)
    private workflowFactoryService: LearningObjectRegistrationWorkflowServiceFactory,
    private courseComponentService: CourseComponentsService,
    private uiRouterGlobals: UrlService,
    private notificationService: NotificationService,
    private courseService: CourseService,
    private trainingService: TrainingService,
    private courseReviewService: CourseReviewService,
    private learningObjectRegistrationService: LearningObjectRegistrationService,
    private stateService: StateService,
    private activeState: UIRouterGlobals,
    private collectionsService: CollectionsService,
    private scrollService: ScrollService
  ) { }

  @HostListener('window:resize')
  onResize() {
    this.checkMobileView();
  }

  get reviewsVisible(): boolean {
    return this.globalConfig.settings?.courseDetails?.reviewsVisible && this.collection.ratings.visible;
  }

  ngOnInit() {
    this.userCourse = this.collection as ICollectionWithRegistration;
    this.collectionWorkflow = this.workflowFactoryService(this.collectionRegistration);
    this.checkMobileView();

    if (this.collectionRegistration.status_id === CourseRegistrationStatus.preAssessmentPending) {
      this.playlistVisible = false;
    }

    this.loadCollectionComponents().then(() => {
      this.startCollection();
    });


    this.eventsSubject.pipe(filter((event: IWorkflowEvent) => {
      return event.event === 'event:user:course:review:added';
    })).subscribe(() => {
      this.acceptReview();
    });

    this.collectionWorkflow.workflowEvents.pipe(
      filter((event: IWorkflowEvent) => {
        return event.event === 'event:course.registration.action.finished';
      }))
      .subscribe((event: IWorkflowEvent) => {
        if (event.payload.registration === this.collectionRegistration) {
          this.startCollection();
        }
      });
  }

  playlistAvailable(): boolean {
    return !(this.collectionRegistration.status_id === CourseRegistrationStatus.preAssessmentPending);
  }

  togglePlaylist() {
    if (!this.playlistAvailable()) {
      return;
    }

    this.playlistVisible = !this.playlistVisible;
    this.playlistUpdated = false;
  }

  openCollectionDetails() {
    this.backUrlService.passThroughRedirect('main.course', { id: this.collection.id });
  }

  isCollectionCompleted(): boolean {
    return this.collectionRegistration.status_id && this.isCompleted(this.collectionRegistration.status_id) ||
      !this.collectionRegistration.status_id && !!this.collectionRegistration;
  }

  close() {
    this.leavePlayer()
      .subscribe(() => {
        this.backUrlService.goBack();
      });
  }

  moveToPreviousCourse() {
    if (this.prevCourse) {
      this.loading = 'previous';

      this.startCourse(this.prevCourse.id)
        .pipe(finalize(() => {
          this.loading = null;
        })).subscribe();
    }
  }

  moveToNextCourse() {
    if (this.nextCourse) {
      this.loading = 'next';
      this.startCourse(this.nextCourse.id)
        .pipe(finalize(() => {
          this.loading = null;
        })).subscribe();
    } else {
      this.showFinalPage();
    }
  }

  onPlayerEvent(event: string, step?: IPlayerStep) {
    if (event === 'noInteractiveContent') {
      this.invokeDialog('upNext');
    } else if (event === 'stepFinished') {
      if (this.currentCollectionAction &&
        this.collectionRegistration?.status_id === CourseRegistrationStatus.inProgress
      ) {
        this.playlistVisible = true;
        this.invokeDialog('openFirst');
      }

      if (this.currentCollectionAction &&
        courseRegistrationStatuses.completedSet.includes(this.collectionRegistration.status_id)) {
        this.finishedCollectionStep = step;
      }

      if (!this.playlistVisible) {
        this.playlistUpdated = true;
      }
    } else if (event === 'courseCompleted') {
      if (step && step.contentStep) {
        if (this.currentCourse.format !== 102 && !this.currentCourse.scorm) {
          this.invokeDialog('upNext');
        } else if (!this.nextCourse) {
          setTimeout(() => {
            this.notificationService.suggest({
              message: ['The ', this.collection.label.name, ' is finished.'].join(''),
              link: {
                message: 'Continue',
                handler: () => {
                  this.moveToNextCourse();
                }
              }
            }, 10000);
          }, 3000);
        }
      } else {
        this.invokeDialog('upNext');
      }
    }
  }

  retakeCourse() {
    if (!this.currentCourse) {
      return;
    }

    this.courseRegistration = null;
    this.player = null;

    this.loading = 'registration';

    this.initCourseRegistration(this.currentCourse)
      .pipe(finalize(() => {
        this.loading = null;
      })).subscribe();
  }

  openFirstCourse() {
    const courseId = this.uiRouterGlobals.search().courseId;

    if (courseId && this.getCourseFromPlaylist(parseInt(courseId))) {
      this.startCourse(parseInt(courseId))
        .subscribe();
    } else {
      this.continueLearning();
    }
  }

  continueLearning() {
    const course = this.trainingService.findNextCollectionComponent(this.compilationStructure, true);

    if (course) {
      this.loading = 'next';
      this.startCourse(course.id)
        .pipe(finalize(() => {
          this.loading = null;
        })).subscribe();
    } else {
      this.moveToNextCourse();
    }
  }

  startCollectionAssessment() {
    this.disposeCoursePlayer();
    this.startCollection();
  }

  isCheckState(registration?: ILearningObjectRegistration): boolean {
    return registration?.isCheckState();
  }

  handleStartCourse(courseId: number) {
    if (this.currentCourse && this.currentCourse.id === courseId || this.courseLoading) {
      return;
    }

    this.startCourse(courseId)
      .subscribe();
  }

  cancelReview() {
    this.newReview = null;
  }

  acceptReview() {
    this.reviewAdded = true;
    this.newReview = null;
  }

  private startCourse(courseId: number) {
    this.updateCourseQueryParam(courseId);

    if (this.mobileView) {
      this.playlistVisible = false;
    }

    return this.leavePlayer()
      .pipe(switchMap(() => {
        this.disposeCoursePlayer();

        this.courseLoading = true;

        return this.courseService.get(courseId, { allowSystemFormat: true });
      }))
      .pipe(tap((course: ICourseDetails) => {
        const playlistCourse = this.getCourseFromPlaylist(course.id);

        this.currentCourse = Object.assign(playlistCourse, course) as ICollectionWithRegistration;

        if (!this.isCompleted(this.currentCourse.status_id)) {
          if (this.currentCourse.courseFormat.registrationRequired) {
            return this.initCourseRegistration(this.currentCourse);
          } else {
            this.article = this.currentCourse.media.find((mediaRecord) => {
              return mediaRecord.type === CourseMediaType.article;
            });
            this.invokeDialog('registrationNotRequired');
          }
        } else {
          this.invokeDialog('courseCompleted');
        }
      }),
      catchError(() => EMPTY),
      finalize(() => {
        this.courseLoading = false;
        this.prevCourse = this.getPreviousCourse(courseId);
        this.nextCourse = this.getNextCourse(courseId);

        this.scrollService.scrollInto(courseId.toString(),  'center');
      }));
  }

  private checkMobileView() {
    this.mobileView = this.window.innerWidth < 768;
  }

  private updateCourseQueryParam(courseId?: number) {
    const params = this.uiRouterGlobals.search();

    if (courseId) {
      params.courseId = courseId;
    } else {
      delete params.courseId;
    }

    this.stateService.go(this.activeState.current, params);
  }

  private initCourseRegistration(course: ICollectionWithRegistration): Observable<void> {
    return fromPromise(this.learningObjectRegistrationService.get(course, null)
      .then((registration: ILearningObjectRegistration) => {
        this.courseRegistration = registration;
        this.courseRegistration.current_bls_id = this.collection.id;

        this.courseRegistration.on('updated', () => {
          if (course.status_id !== this.courseRegistration.status_id &&
            courseRegistrationStatuses.completedSet.includes(this.courseRegistration.status_id) &&
            (course.format === 103 || course.format === 101)) {
            this.invokeDialog('upNext');
          }

          course.status_id = this.courseRegistration.getStatusId();
          (course.registration as any) = course.registration || {};

          Object.assign(course.registration, this.courseRegistration);
        });
      })
      .then(() => {
        this.invokeDialog(null);
      }));
  }

  private getCoursePlaylistIndex(courseId: number): number {
    return this.flattenCourseList.findIndex((course) => course.id === courseId);
  }

  private getCourseFromPlaylist(courseId: number): IFlattenCourse {
    return this.flattenCourseList.find((course) => course.id === courseId);
  }

  private invokeDialog(dialogType: string) {
    this.dialog = dialogType;

    if (this.mobileView) {
      this.playlistVisible = false;
    }
  }

  private getNextCourse(courseId: number): IFlattenCourse {
    const index = this.getCoursePlaylistIndex(courseId);

    if (index > -1 && index + 1 !== this.flattenCourseList.length) {
      return this.flattenCourseList[index + 1];
    }

    return null;
  }

  private getPreviousCourse(courseId?: number): IFlattenCourse {
    const index = this.getCoursePlaylistIndex(courseId);

    if (index > 0) {
      return this.flattenCourseList[index - 1];
    }

    if (index === -1) {
      return this.flattenCourseList[this.flattenCourseList.length - 1];
    }

    return null;
  }

  private isCompleted(statusId: number): boolean {
    return courseRegistrationStatuses.strictCompletedSet.includes(statusId);
  }

  private startCollection() {
    const courseId = this.uiRouterGlobals.search().courseId;
    const attachCallback = () => {
      this.collectionRegistration.on('updated', () => {
        this.userCourse.status_id = this.collectionRegistration.getStatusId();
        (this.userCourse.registration as any) = this.userCourse.registration || {};
        Object.assign(this.userCourse.registration, this.collectionRegistration);
      });
    };

    if (this.collectionRegistration.actions &&
      this.collectionRegistration.actions.includes('LaunchPreEvaluationAction')) {
      this.currentCollectionAction = 'LaunchPreEvaluationAction';
      attachCallback();
    } else if (this.collectionRegistration.status_id === CourseRegistrationStatus.preAssessmentPending) {
      this.currentCollectionAction = 'LaunchPreAssessmentAction';
      attachCallback();
    } else if (this.collectionRegistration.status_id === CourseRegistrationStatus.evaluationPending) {
      this.currentCollectionAction = 'LaunchEvaluationAction';
      attachCallback();
    } else if (this.collectionRegistration.status_id === CourseRegistrationStatus.assessmentPending) {
      this.currentCollectionAction = 'LaunchPostAssessmentAction';
      attachCallback();
    } else if (this.collectionRegistration.status_id !== CourseRegistrationStatus.inProgress) {
      if (this.collectionRegistration.completedRegistration) {
        if (!courseId) {
          if (this.currentCollectionAction !== 'LaunchPostAssessmentAction') {
            this.showFinalPage();
          }
        } else {
          this.openFirstCourse();
        }
      } else {
        this.disposeCoursePlayer();
        this.invokeDialog('noCollectionRegistration');
      }
    } else {
      this.openFirstCourse();
    }
  }

  private loadCollectionComponents(): Promise<void> {
    return this.courseComponentService.getCompilationStructure(this.collection.id, this.currentUser.get().id)
      .then((response) => {
        this.compilationStructure = response.structure;
        this.flattenCourseList = this.courseComponentService.getFlattenCourseList(this.compilationStructure);
      });
  }

  private disposeCoursePlayer() {
    this.currentCourse = null;
    this.courseRegistration = null;
    this.player = null;
    this.dialog = null;
    this.currentCollectionAction = null;
  }

  private showFinalPage() {
    if (this.dialog === 'collectionCompleted') {
      return;
    }

    this.leavePlayer()
      .subscribe(() => {
        this.disposeCoursePlayer();

        this.invokeDialog('collectionCompleted');
        this.newReview = this.courseReviewService.newReview({ courseId: this.collection.id });

        this.collectionRegistration.originalId = this.collectionRegistration.id;
        this.loading = 'true';

        this.collectionRegistration.reload()
          .then(() => {
            this.courseService.get(this.collection.id,
              { allowSystemFormat: true })
              .subscribe((collection) => {
                Object.assign(this.collection, collection);
              });

            return this.loadCollectionComponents()
              .then(() => {
                this.nextCourse = this.trainingService
                  .findNextCollectionComponent(this.compilationStructure, true, true);
                this.prevCourse = this.getPreviousCourse();
                this.compilationStat = this.collectionsService.aggregateRegistrationsInfo(this.compilationStructure);
              });
          })
          .finally(() => {
            this.loading = null;
          });
      });
  }

  private leavePlayer(): Observable<null> {
    return this.player ?
      this.player?.leave()
        .pipe(
          tap(() => this.closeSubject.next()),
          map(() => null)) :
      of(null);
  }
}

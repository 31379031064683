import { Injectable } from '@angular/core';
import { SearchService } from 'modules/search/services';
import { ITrainingCategorySearchItem } from '../models/external.models';


@Injectable()
export class TrainingCategorySearchService extends SearchService<ITrainingCategorySearchItem> {
  static readonly endpoint: string = '/a/search/';

  type = 'training_category';

  protected get endpoint(): string {
    return TrainingCategorySearchService.endpoint;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LmsComponentsModule } from 'components/kmi.lms.components.module';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { ecommercePayLaterRoutingModule } from './ecommerce-pay-later-routing.module';
import { CourseRegistrationCommonModule } from 'modules/course-registrations/course-registration-common.module';
import { PayLaterComponent } from './components/pay-later.component';


@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    LmsComponentsModule,
    CourseRegistrationCommonModule,
    UIRouterUpgradeModule.forChild({ states: ecommercePayLaterRoutingModule }),
  ],
  declarations: [PayLaterComponent],
})
export class EcommercePayLaterModule {}

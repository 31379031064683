<div class="horizontal-tile">
  <div class="d-flex gap-6x flex-direction-column-xs">
    <div>
      <div class="course-thumbnail-image" [ngStyle]="{'background-image': heroBackground}">
      </div>
    </div>

    <div class="grow">
      <div class="d-flex flex-direction-column gap-3x">
        <span class="horizontal-tile_title">{{post.title}}</span>

        <div [innerHTML]="post.getContent()">
        </div>

      </div>
    </div>
  </div>
</div>

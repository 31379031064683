import { NgModule } from '@angular/core';
import { CourseReviewService } from 'modules/course/reviews/services/course-review.service';
import { EditCourseReviewDirective } from 'modules/course/reviews/components/edit-course-review.component.upgraded';


@NgModule({
  declarations: [
    EditCourseReviewDirective
  ],
  imports: [],
  exports: [
    EditCourseReviewDirective
  ],
  providers: [
    CourseReviewService
  ]
})
export class CourseReviewsModule {}

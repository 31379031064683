import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid';
import { Transition } from '@uirouter/core';
import { BlogPostService } from 'modules/blog/services/blog-post.service';
import { lastValueFrom } from 'rxjs';
import { BlogPostDetailsComponent } from './components/view/blog-post-details.component';
import { RecentBlogPostsComponent } from 'modules/blog/components/lists/recent-blog-posts.component';


export const blogRoutingModule: NgHybridStateDeclaration[] = [
  {
    name: 'main.blog',
    url: '^/blog/:id?from',
    params: {
      id: null,
      from: null
    },
    data: {
      label: 'Blog',
      availableFor: ['anonymous', 'regularUser', 'admin'],
    },
    resolve: [
      {
        provide: 'post',
        deps: [Transition, BlogPostService],
        useFactory: (
          transition: Transition, blogPostService: BlogPostService,
        ) => {
          return lastValueFrom(blogPostService.get(Number(transition.params().id)))
            .then(post => Promise.resolve(blogPostService.transform(post)));
        }
      }
    ],
    component: BlogPostDetailsComponent
  },
  {
    name: 'main.news',
    url: '^/news',
    data: {
      label: 'News',
      pageTitle: true,
      availableFor: ['regularUser', 'admin'],
    },
    component: RecentBlogPostsComponent
  }
];

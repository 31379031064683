<div *ngIf="!total && !items?.length && disabled">
  <div class="load-spinner"></div>
</div>

<p><small *ngIf="total >= 0 && !disabled">{{total}} record(s) found.</small></p>

<ul class="content-list" *ngIf="items?.length" infiniteScroll [inViewport]="85" (infiniteScrollAction)="requestMore()">
  <!-- List header -->
  <li class="content-list-header">
    <div class="flex">
      <div class="flex-row">
        <div class="flex-col flex-col-grow">
          <strong>Name</strong>
        </div>
        <div class="flex-col flex-col-8x hidden-xs">
          <strong>Date</strong>
        </div>
        <div class="flex-col flex-col-6x visible-xs">
          <strong>Date</strong>
        </div>
        <div class="flex-col flex-col-6x">
          <strong>Time</strong>
        </div>
      </div>
    </div>
  </li>

  <li class="content-list-item" *ngFor="let item of items">
    <div class="flex">
      <div class="flex-row">
        <div class="flex-col flex-col-grow">
          <div class="line-clamp">
            <a [href]="item.url" [innerHTML]="item.name" [title]="item.title | htmlToPlainText" [hidden]="disabled"
               [ariaLabel]="item.name"></a>
            <span class="btn-link disabled-link underline" aria-disabled="true" [ariaLabel]="item.name"
                  [hidden]="!disabled" [innerHTML]="item.name"></span>
          </div>
        </div>
        <div class="flex-col flex-col-8x hidden-xs" [ngClass]="{'disabled-text': disabled}">
          {{item.start | date: 'mediumDate'}}
        </div>
        <div class=" flex-col flex-col-6x visible-xs" [ngClass]="{'disabled-text': disabled}">
          {{item.start | date: 'shortDate'}}
        </div>
        <div class="flex-col flex-col-6x" [ngClass]="{'disabled-text': disabled}">
          {{item.start | date: 'shortTime'}}
        </div>
      </div>
    </div>
  </li>
</ul>

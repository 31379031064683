<div class="navbar navbar-fixed-top collection-player-navbar">

  <div class="flex flex-vertical-center">
    <div class="flex-row">
      <div class="flex-col flex-col-3x">
        <button type="button" title="Toggle Playlist" class="btn btn-link" *ngIf="playlistAvailable()"
                (click)="togglePlaylist()" [id]="'course_player_collection_' + collection.id + '_toggle_structure'">
          <span class="fa fa-bars font-16px"></span>
          <span class="completed-dot-mark" *ngIf="playlistUpdated"></span>
          <div class="sr-only">Toggle playlist</div>

        </button>
      </div>

      <div class="flex-col flex-col-grow">
        <div class="course-title text-truncate" [title]="collection.name">
          <strong class="font-semi-bold" *ngIf="currentCourse"><span
            *ngIf="currentCourse?.number">{{currentCourse?.number}}.&thinsp;</span>{{currentCourse?.name}}&nbsp;-&nbsp;</strong>{{collection.name}}
        </div>
      </div>

      <div class="flex-col" *ngIf="courseRegistration?.actions.includes('PrintAction')">
        <player-transcript-button [course]="currentCourse" [player]="player" [registration]="courseRegistration"></player-transcript-button>
      </div>

      <div class="flex-col flex-col-4x">
        <button type="button" title="Close collection" class="btn btn-default action-btn" (click)="close()" [id]="'course_player_collection_' + collection.id + '_close_player'">
          <span class="fa fa-times"></span>
          <div class="sr-only">Close collection</div>
        </button>
      </div>

    </div>
  </div>
</div>

<!--<course-registration-dialogs [workflow]="collectionWorkflow"></course-registration-dialogs>-->

<div *ngIf="!compilationStructure">
  <div class="load-spinner"></div>
</div>

<div class="collection-player-main">
  <div class="flex" *ngIf="compilationStructure">
    <div class="flex-row">
      <div class="flex-col col-sidebar" scroll-on-event
           [ngClass]="{'slide-out': !playlistVisible, 'slide-in': playlistVisible,
           'no-control-panel':flattenCourseList.length === 1}">
        <collection-playlist-structure-layer [items]="compilationStructure" *ngIf="compilationStructure"
                                             [runningCoursePlayer]="player" [currentCourse]="currentCourse"
                                             (courseStarted)="handleStartCourse($event)">
        </collection-playlist-structure-layer>
        <div class="control-panel" *ngIf="flattenCourseList.length > 1 && settings?.playbackButtons">
          <button type="button" class="btn btn-default btn-prev" title="Previous Course"
                  data-spinner-color="#FFF" [ladda]="loading === 'previous'"
                  (click)="moveToPreviousCourse()" [id]="'course_player_collection_' + collection.id + '_previous_course'"
                  [disabled]="courseLoading || !!loading|| !prevCourse">Previous
          </button>
          <button type="button" class="btn btn-default btn-next" title="Next Course" (click)="moveToNextCourse()"
                  data-spinner-color="#FFF" [ladda]="loading === 'next'" [id]="'course_player_collection_' + collection.id + '_next_course'"
                  [disabled]="courseLoading || !!loading || dialog === 'collectionCompleted'">Next
          </button>
        </div>

      </div>
      <div class="col-player-wrapper flex-col flex-col-grow" [ngClass]="{'has-dialog':dialog,
        'expanded-player': !playlistVisible, 'collapsed-player': playlistVisible}">
        <div *ngIf="courseLoading">
          <div class="load-spinner"></div>
        </div>

        <div *ngIf="!courseLoading">
          <course-player [course]="currentCourse" [registration]="courseRegistration" [onlyPlayableCourses]="true"
                         (playerEvent)="onPlayerEvent($event.event, $event.step)" [wrapped]="dialog"
                         [closeSubject]="closeSubject"
                         [(player)]="player" *ngIf="courseRegistration"
                         [trackingName]="'course_player_collection'"></course-player>

          <course-player [course]="collection" [registration]="collectionRegistration"
                         (playerEvent)="onPlayerEvent($event.event, $event.step)" [wrapped]="dialog"
                         [closeSubject]="closeSubject"
                         [trackingName]="'course_player_collection'" [(player)]="collectionPlayer"
                         *ngIf="!courseRegistration && collectionRegistration && currentCollectionAction"></course-player>
          <!--Article-->
          <div *ngIf="currentCourse?.format === 129 && !currentCourse?.courseFormat.registrationRequired" class="article-player">
                 <div style="padding: 15px 20px 0px 20px;">
                  <read-time [text]="article.data" class="display-block xs-margin-bottom"></read-time>
                  <article [innerHTML]="article.data|markdownToHtml" class="sm-margin-bottom  hyphens-auto"></article>
                 </div>
          </div>

          <!-- Messages-->
          <div *ngIf="dialog" class="player-dialog"  [ngClass]="{'has-quiz':(player?.currentStep && (!player?.currentStep.contentStep || currentCourse?.format === 108 || currentCourse?.format === 115)) ||
                                                                              (!courseRegistration && collectionPlayer?.currentStep && !player?.currentStep.contentStep),
            'content':courseRegistration}">
            <div [ngSwitch]="dialog">
              <div *ngSwitchCase="'courseNotAvailable'" class="text-center"> <!--page 5-->
                <p>
                  This {{(currentCourse?.label.name||currentCourse?.formatName)}} is not available.<span *ngIf="nextCourse">Please continue to the next component.</span>
                </p>
                <button type="button" class="btn btn-primary btn-dialog btn-lg" title="Next" (click)="moveToNextCourse()"
                        [id]="'course_player_collection_' + collection.id + '_next_course'"
                        *ngIf="nextCourse">
                  Next
                </button>
              </div>

              <div *ngSwitchCase="'noCollectionRegistration'">
                <div *ngIf="collectionWorkflow.hasAction('RegisterAction') && !collectionWorkflow.hasAction('LaunchPreAssessmentAction') && !collectionWorkflow.hasAction('LaunchPreEvaluationAction')" class="text-center">
                <p>Start <strong>{{collection.name}}</strong>?</p>
                  <button class="btn btn-default btn-dialog" type="button"
                          (click)="collectionWorkflow.exec('RegisterAction')"
                          [ladda]="collectionWorkflow.processingAction === 'RegisterAction'"
                          data-spinner-color="#FFF">Start</button>
                </div>

                <!--page 1-->
                <div *ngIf="collectionWorkflow.hasAction('LaunchPreAssessmentAction')" class="text-center">
                  <p>This {{(currentCourse?.label.name||currentCourse?.formatName)}} requires a pre-assessment.</p>
                  <button class="btn btn-default btn-dialog btn-lg" type="button" [id]="'course_player_collection_' + collection.id + '_launch_pre_assessment'"
                          (click)="collectionWorkflow.exec('LaunchPreAssessmentAction')"
                           title="Continue"
                          [ladda]="collectionWorkflow.processingAction === 'LaunchPreAssessmentAction'"
                          data-spinner-color="#FFF">Continue</button>
                </div>
                <!--end page 1-->

                <!--page 1-->
                <div *ngIf="collectionWorkflow.hasAction('LaunchPreEvaluationAction')" class="text-center">
                  <p>This {{(currentCourse?.label.name||currentCourse?.formatName)}} requires a pre-evaluation.</p>
                  <button class="btn btn-default btn-dialog btn-lg" type="button" [id]="'course_player_collection_' + collection.id + '_launch_pre_evaluation'"
                          title="Continue"
                          (click)="collectionWorkflow.exec('LaunchPreEvaluationAction')"
                          [ladda]="collectionWorkflow.processingAction === 'LaunchPreEvaluationAction'"
                          data-spinner-color="#FFF">Continue</button>
                </div>
                <!--end page 1-->

                <div *ngIf="!collectionWorkflow.hasAction('LaunchPreAssessmentAction') && !collectionWorkflow.hasAction('LaunchPreEvaluationAction') && !collectionWorkflow.hasAction('RegisterAction')" class="text-center">
                  <h4>Oh, sorry</h4>
                  <p>
                    Course "<strong>{{collection.name}}</strong>" content is restricted.
                  </p>
                  <p>
                    Please visit <a href="#" title="Open Course Details" (click)="openCollectionDetails()" [id]="'course_player_collection_' + collection.id + '_open_details'">course details
                    page</a> to access it.
                  </p>
                </div>
              </div>

              <div *ngSwitchCase="'collectionCompleted'" [ngClass]="{'content panel panel-default':isCollectionCompleted()}">
                <div *ngIf="loading">
                  <div class="load-spinner"></div>
                </div>

                <div *ngIf="!loading">
                  <div *ngIf="isCollectionCompleted()"> <!--page 11-->
                    <h3>Congratulations!</h3>
                    You've completed the requirements for this {{(collection.label?.name||collection.formatName)}}.
                    <div *ngIf="collectionWorkflow.hasAction('LaunchEvaluationAction')">

                      <button class="btn btn-default btn-dialog btn-primary player-eval-button" type="button"
                              (click)="collectionWorkflow.exec('LaunchEvaluationAction')"
                              [id]="'course_player_collection_' + collection.id + '_launch_evaluation'"
                              [ladda]="collectionWorkflow.processingAction === 'LaunchEvaluationAction'"
                              data-spinner-color="#FFF">Take Evaluation</button>
                    </div>

                    <div *ngIf="!reviewAdded && collection.ratings.allow_addition && reviewsVisible">
                      <h4>
                        Submit a review:
                      </h4>
                      <edit-course-review [course]="collection"
                                          [fullStart]="true"
                                          [hideCancel]="true"
                                          [(review)]="newReview"
                                          *ngIf="!reviewAdded && collection.ratings.allow_addition"
                                          [trackingName]="'course_details_review_window'"
                                          (cancelHandler)="cancelReview()"
                                          (addHandler)="acceptReview()"
                      ></edit-course-review>
                      <p *ngIf="reviewAdded">
                        Thank you for the review!
                      </p>
                    </div>
                  </div>

                  <div *ngIf="!isCollectionCompleted()" class="text-center"> <!--page 10-->
                    <div *ngIf="nextCourse">
                      You've reached the end of this {{(collection.label?.name||collection.formatName)}} but not all content is complete.
                      You can close this window or re-visit incomplete components.
                    </div>
                    <div *ngIf="!nextCourse">
                      <h3>Congratulations!</h3>
                      You've completed the requirements for this {{(collection.label?.name||collection.formatName)}}.
                    </div>

                    <div class="content-status">
                      <div class="progress">
                        <div class="progress-bar progress-bar-success" [style]="{ 'width': compilationStat.completed+'%'}">
                          <span class="sr-only">{{compilationStat.completed}}% Complete</span>
                        </div>
                      </div>
                    </div>

                    <button class="btn btn-primary btn-dialog btn-lg" title="Continue Learning" type="button"
                            (click)="moveToNextCourse()" *ngIf="nextCourse" [id]="'course_player_collection_' + collection.id + '_next_course'"
                            data-spinner-color="#FFF" [ladda]="loading === 'next'">
                      Next
                    </button>

                    <div *ngIf="!nextCourse && collectionWorkflow.hasAction('LaunchPostAssessmentAction')">
                      <button class="btn btn-default btn-dialog" type="button" [id]="'course_player_collection_' + collection.id + '_start_assessment'"
                              (click)="startCollectionAssessment()"
                              data-spinner-color="#FFF">Assessment</button>
                    </div>

                    <div *ngIf="!nextCourse && collectionWorkflow.hasAction('CompleteAction')">
                      <button class="btn btn-primary btn-dialog" type="button"
                              (click)="collectionWorkflow.exec('CompleteAction')"
                              [id]="'course_player_collection_' + collection.id + '_complete'"
                              [ladda]="collectionWorkflow.processingAction === 'CompleteAction'"
                              data-spinner-color="#FFF">Complete</button>
                    </div>

                  </div>

                </div>
              </div>

              <div *ngSwitchCase="'courseNotSelected'" class="text-center">
                  <p>Continue Learning?</p>
                  <button class="btn btn-default btn-dialog btn-lg" title="Continue Learning" type="button"
                          (click)="continueLearning()" [id]="'course_player_collection_' + collection.id + '_continue'"
                          data-spinner-color="#FFF" [ladda]="loading === 'next'">
                    Continue
                  </button>
              </div>

              <div *ngSwitchCase="'courseCompleted'" class="text-center"> <!--page 7-->
                  <p>
                    This content is already completed. You may retake it or continue to the next component.
                  </p>
                  <button class="btn btn-default btn-dialog btn-lg" title="Re-start" type="button" (click)="retakeCourse()"
                          data-spinner-color="#000" [ladda]="loading === 'registration'" [id]="'course_player_collection_' + collection.id + '_restart_component'">
                    Re-start
                  </button>
                  <button class="btn btn-primary btn-dialog btn-lg" title="Next" type="button" (click)="moveToNextCourse()"
                          data-spinner-color="#FFF" [ladda]="loading === 'next'" [id]="'course_player_collection_' + collection.id + '_next_course'">
                    Next
                  </button>

              </div>

              <div *ngSwitchCase="'upNext'" class="text-center player-up-next" [ngClass]="{'player-up-next-external':(currentCourse?.format === 103 || currentCourse?.format === 101)  && !isCheckState(courseRegistration)}"> <!--page 9-->
                <button class="btn btn-default btn-dialog btn-lg" title="Re-start" type="button" (click)="retakeCourse()" [id]="'course_player_collection_' + collection.id + '_restart_component'"
                        data-spinner-color="#000" [ladda]="loading === 'registration'"  *ngIf="courseRegistration?.status_id === CourseRegistrationStatus.notPassed">
                  Re-start
                </button>

                <button type="button" class="btn btn-primary btn-dialog btn-lg next-button" title="Next" (click)="moveToNextCourse()"
                  *ngIf="!loading" [id]="'course_player_collection_' + collection.id + '_next_course'">
                  Next
                </button>
              </div>

              <div *ngSwitchCase="'openFirst'" class="text-center player-up-next" [ngClass]="{'player-up-next-external':(currentCourse?.format === 103 || currentCourse?.format === 101) && !isCheckState(courseRegistration)}">
                <button type="button" class="btn btn-primary btn-dialog btn-lg next-button" title="Next" (click)="openFirstCourse()" [id]="'course_player_collection_' + collection.id + '_next_course'"
                        *ngIf="!loading">
                  Next
                </button>
              </div>

              <div *ngSwitchCase="'registrationNotRequired'" class="text-center article-next">
                <hr class="0px 0px 25px 0px">
                <button type="button" class="btn btn-primary btn-dialog btn-lg next-button" title="Next" (click)="moveToNextCourse()" [id]="'course_player_collection_' + collection.id + '_next_course'"
                        *ngIf="!loading">
                  Next
                </button>
              </div>

              <div *ngSwitchCase="'courseInterrupted'" class="text-center"> <!--page 6-->
                <p>This content is not complete.</p>
                <button class="btn btn-default btn-dialog" title="Re-start" type="button" (click)="retakeCourse()"
                        data-spinner-color="#000" [ladda]="loading === 'registration'" [id]="'course_player_collection_' + collection.id + '_restart_current_course'">
                  Re-start
                </button>
                <button class="btn btn-primary btn-dialog" title="Next" type="button" (click)="moveToNextCourse()"
                        [id]="'course_player_collection_' + collection.id + '_next_course'"
                        data-spinner-color="#FFF" [ladda]="loading === 'next'">
                  Next
                </button>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>

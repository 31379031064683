import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { LmsComponentsModule } from 'components/kmi.lms.components.module';
import { CommonModule } from '@angular/common';
import { RecentlyUpdatedItemsComponent } from 'modules/course/catalog/components/recently-updated-items.component';
import { CourseTilesModule } from 'modules/course/views/tiles/course-tiles.module';
import { RecentlyUpdatedService } from 'modules/course/catalog/services/recently-updated.service';
import {
  SubjectAreaNavigationItemsComponent
} from 'modules/course/catalog/components/subject-area-navigation-items.component';
import {
  TargetAudienceNavigationItemsComponent
} from 'modules/course/catalog/components/target-audience-navigation-items.component';
import { CatalogSearchFiltersComponent } from 'modules/course/catalog/components/catalog-search-filters.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { NavigationModule } from 'modules/navigation/navigation.module';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { courseCatalogRoutingModule } from 'modules/course/catalog/course-catalog-routing.module';
import { CatalogViewComponent } from 'modules/course/catalog/view/catalog-view.component';
import { SearchDeprecatedModule } from '../../../deprecated/search/search.deprecated.module';


@NgModule({
  imports: [
    UIRouterModule,
    LmsComponentsModule,
    CommonModule,
    NavigationModule,
    // SearchCommonModule, // Please don't touch!.
    SearchDeprecatedModule,
    NgbDropdownModule,
    FormsModule,
    CourseTilesModule,
    UIRouterUpgradeModule.forChild({ states: courseCatalogRoutingModule })
  ],
  declarations: [
    SubjectAreaNavigationItemsComponent,
    TargetAudienceNavigationItemsComponent,
    RecentlyUpdatedItemsComponent,
    CatalogSearchFiltersComponent,
    CatalogViewComponent,
  ],
  providers: [
    RecentlyUpdatedService,
  ],
  exports: [
    SubjectAreaNavigationItemsComponent,
    TargetAudienceNavigationItemsComponent,
    CatalogSearchFiltersComponent
  ],
})

export class CourseCatalogModule {}

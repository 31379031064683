import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalConfig } from 'core/environment';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { QuizOptionsService } from 'modules/quiz/services/quiz-options.service';
import { ICourseQuizTypes } from 'modules/course/quiz/models/course-quiz.models';
import {
  LearningObjectRegistrationWorkflowService
} from 'modules/course-registrations/services/learning-object-registration-workflow.service.ajs-upgraded-provider';
import { IWorkflowState } from 'modules/course-registrations/models/workflow.model';


@Component({
  selector: 'course-quiz-player',
  templateUrl: './course-quiz-player.component.html',
})
export class CourseQuizPlayerComponent implements OnInit {
  static readonly selector = 'courseQuizPlayer';
  @Input() type: string;
  @Input() workflow: LearningObjectRegistrationWorkflowService;
  @Input() quizRetake:Subject<{ resultId: number, skipStartStep?: boolean }>;
  @Input() playerClose:Subject<void>;

  @Output() finish: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() retake: EventEmitter<string> = new EventEmitter<string>();

  quizFinish: Subject<boolean> = new Subject<boolean>();
  quizSkipSubject = new Subject<void>();
  quizOptionsEnum? = this.globalConfig.settings.quizOptions;
  courseQuizTypes?:ICourseQuizTypes;
  quizzes: Partial<IWorkflowState>;

  constructor(
    private globalConfig: GlobalConfig,
    private window:Window,
    private quizOptionsService: QuizOptionsService
  ) {  }

  ngOnInit() {
    this.quizOptionsService.getCourseQuizTypes()
      .subscribe((data) => {
        this.courseQuizTypes = data;
      });

    this.quizzes = _.pick(this.workflow.registration, [
      'preAssessment', 'postAssessment', 'preEvaluation',
      'evaluation', 'followUpEvaluation'
    ]);
  }

  quizFinished(allowMoveToTheNextStep?: boolean) {
    this.workflow.registration.reload()
      .then(() => {
        this.finish.emit(allowMoveToTheNextStep);
      });
  }

  handleRetake(quizType: string) {
    this.retake.emit(quizType);
  }

  handleQuizResult() {
    this.quizFinish.next(true);
  }

  execute(action: string, parameters: any) {
    const callbackFunction = () => {
      if (parameters && parameters.quizFinishStep) {
        this.quizFinished();
      }

      if (parameters && parameters.quizSkipEvent) {
        this.quizSkipSubject.next();
      }

      if (parameters && parameters.sendCloseEvent) {
        this.window.parent.postMessage('closePlayer', '*');
      }
    };

    this.workflow.exec(action, {}).then(callbackFunction, callbackFunction);
  }
}

import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { IUserCourse } from 'modules/course-registrations/models/course-registration.model';


@Directive({
  selector: 'play-course',
})
export class PlayCourseDirective extends UpgradeComponent {
  static readonly selector = 'playCourse';

  @Input() course: {id:number|string};
  @Input() userCourse: IUserCourse;
  @Input() trackingName: string;
  @Input() mode: string;
  @Input() vertical: boolean;
  @Input() smallView: boolean;
  @Input() actionData: any;
  @Input() customName: string;

  constructor(elementRef: ElementRef, injector: Injector) {
    super(PlayCourseDirective.selector, elementRef, injector);
  }
}

<div [ngSwitch]="usePlayer">
  <div *ngSwitchCase="'internal'" class="internal-player"><!--ex- course-content-wrapper-->
    <internal-content-view [type]="type"
                           [workflow]="workflow"
                           [playerActions]="playerActions"
                           [closeSubject]="closeSubject"
                           (closed)="onPLayerClose()"
                           [quizRetake]="quizRetake"
                           (retake)="handleRetake($event)"
                           (finished)="finishHandler()">
    </internal-content-view>
  </div>
  <div *ngSwitchCase="'external'">
    <external-content-view class="course-content-wrapper external-player"
                           (finished)="finishHandler()"
                           [wrapped]="wrapped"
                           [registration]="workflow.registration"
                           [type]="type"
                           [closeSubject]="closeSubject"
                           (closed)="onPLayerClose()"
                           (launch)="launchHandler($event)"
                           [playerActions]="playerActions"
    ></external-content-view>
  </div>
</div>

import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'email-preview-modal',
  templateUrl: './email-preview-modal.component.html',
})
export class EmailPreviewModalComponent {
  @Input() patternId: number;
  @Input() variables: Record<string, string>;
  @Input() courseId: number;
  @Input() notification: string;

  constructor(private activeModal: NgbActiveModal) {}

  cancel(): void {
    this.activeModal.dismiss();
  }
}

<div class="row">
  <div class="col-xs-12">
    <h3 class="pull-left calendar-title">Calendar</h3>
  </div>
</div>

<div *ngIf="template === 'TrainCalendar'">
  <p class="standard-margin-bottom">
    This page will allow you to browse your events and search for upcoming events as well.
    There are a number of filtering options available, including a keyword search.
    The results you receive may be limited by any groups you've joined, and your address
    (<a [uiSref]="'edit.account'" title="Profile details">see your profile for details</a>).
  </p>

  <calendar-events [searchFilters]="filters" [showRegistrationStatus]="showRegistrationStatus">
    <div class="row calendar-legend">
      <div class="col-sm-7">
        <ul class="list-unstyled">
          <li><span class="badge badge-calendar badge-video">V</span> Marks a videoconference event</li>
          <li><span class="badge badge-calendar badge-exercise">E</span> Marks a Drills & Exercises events</li>
          <li><span class="badge badge-calendar badge-unavailable">X</span> Marks an event that is not available for
            registration</li>
          <li><span class="badge badge-calendar badge-registered"><span class="fa fa-hourglass-half"></span></span>
            Marks
            an event that you are registered for but no seat is available</li>
        </ul>
      </div>
      <div class="col-sm-5">
        <ul class="list-unstyled">
          <li><span class="badge badge-calendar badge-conference">C</span> Marks a conference event</li>
          <li><span class="badge badge-calendar badge-other">N</span> Marks a Calendar event</li>
          <li><span class="badge badge-calendar badge-registered">R</span> Marks an event that you are registered for
          </li>
        </ul>
      </div>
    </div>
  </calendar-events>
</div>

<div *ngIf="template === 'Calendar'">
  <calendar-events [searchFilters]="filters" [showRegistrationStatus]="showRegistrationStatus">
    <div class="col-xs-12 calendar-legend">
      <ul class="list-unstyled">
        <li><span class="badge badge-calendar badge-registered">R</span> Marks an event that you are registered for</li>
        <li><span class="badge badge-calendar badge-registered"><span class="fa fa-hourglass-half"></span></span> Marks
          an event that you are registered for but no seat is available</li>
        <li><span class="badge badge-calendar badge-unavailable">X</span> Marks an event that is not available for
          registration</li>
        <li><span class="badge badge-calendar badge-conference">C</span> Marks a conference event</li>
      </ul>
    </div>
  </calendar-events>
</div>

import { Observable } from 'rxjs';
import { ElmsUtils } from 'core/utils';
import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import {
  ICourseProviderOrganizationMember, IOrganizationMemberRole, IOrganizationPotentialMemberUser
} from '../models/course-provider-organization-member.model';
import { CACHE_NAME_TOKEN, CACHE_TTL_TOKEN, TTL } from 'core/http-interceptors/http-caching-interceptor';
import { ISearchQueryParams, ISearchResponse } from 'modules/search/models/search.models';


export enum CourseProviderOrganizationMemberEndpoints {
  QUEUE  = '/a/course/providers/organizations/:organizationId/members/',
  UPDATE = '/a/course/providers/organizations/:organizationId/members/',
  ROLES  = '/a/course/providers/organizations/member-roles/',
  SEARCH = '/a/course/providers/organizations/:organizationId/find-potential-members/'
}

export type PotentialMemberSearchResponse = ISearchResponse<IOrganizationPotentialMemberUser>;

@Injectable()
export class CourseProviderOrganizationMemberService {
  constructor(private http: HttpClient) { }

  public queue(organizationId: number): Observable<ICourseProviderOrganizationMember[]> {
    return this.http.get<ICourseProviderOrganizationMember[]>(
      ElmsUtils.formatUrl(CourseProviderOrganizationMemberEndpoints.QUEUE, { organizationId })
    );
  }

  public update(organizationId: number, members: ICourseProviderOrganizationMember[]): Observable<void> {
    return this.http.put<void>(
      ElmsUtils.formatUrl(CourseProviderOrganizationMemberEndpoints.QUEUE, { organizationId }),
      { members }
    );
  }

  public roles(): Observable<IOrganizationMemberRole[]> {
    return this.http.get<IOrganizationMemberRole[]>(
      CourseProviderOrganizationMemberEndpoints.ROLES,
      {
        context: new HttpContext()
          .set(CACHE_NAME_TOKEN, CourseProviderOrganizationMemberEndpoints.ROLES)
          .set(CACHE_TTL_TOKEN, TTL.NEVER_EXPIRE)
      }
    );
  }

  public search(organizationId: number, params: ISearchQueryParams): Observable<PotentialMemberSearchResponse> {
    return this.http.get<PotentialMemberSearchResponse>(
      ElmsUtils.formatUrl(CourseProviderOrganizationMemberEndpoints.SEARCH, { organizationId }),
      { params }
    );
  }
}

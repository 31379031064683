import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { LmsComponentsModule } from 'components/kmi.lms.components.module';
import { ChannelPostsTileComponent } from './components/lists/channel-posts-tile.component';
import { BlogPostService } from './services/blog-post.service';
import { RecentBlogPostService } from './services/recent-blog-posts.service';
import { RecentBlogPostsComponent } from './components/lists/recent-blog-posts.component';
import { BlogPostsRotatorComponent } from './components/rotator/blog-posts-rotator.component';
import { BlogPostRotatorService } from './services/blog-rotator.service';
import { RecentBlogPostHeroComponent } from './components/rotator/recent-blog-post-hero.component';
import { RecentBlogPostComponent } from './components/rotator/recent-blog-post.component';
import { BlogPostDetailsComponent } from './components/view/blog-post-details.component';
import { blogRoutingModule } from './blog-routing.module';
import { UserModule } from 'modules/user/user.module';
import { BlogPostListTileComponent } from './components/view/blog-post-list-tile.component';


@NgModule({
  imports: [
    CommonModule,
    UserModule,
    LmsComponentsModule,
    UIRouterUpgradeModule.forChild({ states: blogRoutingModule }),
    HttpClientModule],
  declarations: [
    ChannelPostsTileComponent,
    RecentBlogPostsComponent,
    BlogPostsRotatorComponent,
    RecentBlogPostHeroComponent,
    RecentBlogPostComponent,
    BlogPostDetailsComponent,
    BlogPostListTileComponent,
  ],
  providers: [BlogPostService, RecentBlogPostService, BlogPostRotatorService],
  exports: [
    ChannelPostsTileComponent, RecentBlogPostsComponent, BlogPostDetailsComponent, BlogPostListTileComponent
  ]
})
export class BlogModule {}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ICourseSessionBase, ICourseSessionsQueryParams } from '../models/course-session.models';
import _ from 'lodash';


export enum CourseSessionsEndpoints {
  GET = '/a/course/sessions/',
}

@Injectable()
export class CourseSessionsService {
  constructor(protected http: HttpClient) { }

  get(queryParams: ICourseSessionsQueryParams): Observable<ICourseSessionBase[]> {
    const params = new HttpParams().set('query', JSON.stringify(_.omitBy(queryParams, _.isNil)));

    return this.http.get<ICourseSessionBase[]>(CourseSessionsEndpoints.GET, { params });
  }
}

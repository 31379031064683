import { NgModule } from '@angular/core';
import {
  CompetenciesTreeViewDirective
} from 'modules/competency/components/competencies-tree-details-component.upgraded';


@NgModule({
  declarations: [
    CompetenciesTreeViewDirective
  ],
  exports: [
    CompetenciesTreeViewDirective
  ],
})
export class CompetencyModule {}

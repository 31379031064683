import { CourseCustomListService } from 'modules/course/custom-lists/services/course-custom-list.service';
import { CurrentUserService } from 'ajs/modules/app/current-user.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IFeaturedCoursesParams, IFeaturedUserCourse } from 'modules/course/custom-lists/models/featured-courses.model';
import { map, Unsubscribable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import _ from 'lodash';


@Component({
  selector: 'user-featured-courses',
  templateUrl: './user-featured-courses.component.html',
})
export class UserFeaturedCoursesComponent implements OnInit, OnDestroy {
  static readonly selector = 'userFeaturedCourses';
  @Input() noHeader: boolean;
  @Input() header: string;
  @Input() maxCount: number;
  @Input() allowAnonymous: boolean;
  @Output() coursesCount: EventEmitter<number> = new EventEmitter<number>();
  @Input() trackingName: string;

  @Input() tilesView: string;
  @Input() smallView: boolean;
  anonymous = true;
  courses: IFeaturedUserCourse[] = [];

  private _filters: IFeaturedCoursesParams;
  private subscriber?: Unsubscribable;

  constructor(
    private customCourseListsService: CourseCustomListService,
    private currentUser: CurrentUserService
  ) { }

  @Input() set filters(filters: IFeaturedCoursesParams) {
    if (!_.isEqual(this._filters, filters)) {
      this._filters = filters;
      this.loadFeaturedCourses();
    }
  }

  get filters(): IFeaturedCoursesParams {
    return this._filters;
  }

  ngOnInit(): void {
    this.loadFeaturedCourses();
  }

  ngOnDestroy() {
    this.subscriber?.unsubscribe();
  }

  loadFeaturedCourses() {
    this.anonymous = this.currentUser.get().anonymous && !this.allowAnonymous;
    this.subscriber = this.customCourseListsService.getUserFeaturedCourses(this.filters)
      .pipe(
        map((response: IFeaturedUserCourse[]) => {
          return response.filter((item) => {
            return item.categoryId === 1;
          });
        }),
        finalize(() => {
          this.subscriber?.unsubscribe();
          delete this.subscriber;
        }))
      .subscribe((response) => {
        this.courses = this.maxCount ? response.slice(0, this.maxCount) : response;
        this.coursesCount.emit(response.length);
      });
  }
}

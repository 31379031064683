<input hidden name="ngDate" ngbDatepicker positionTarget=".fc-myCustomButton-button" container="body"
       [(ngModel)]="selectedDate"
       aria-label="Open calendar"
       (dateSelect)="onDatePickerSelected()"
       #ngDate="ngbDatepicker" />

<full-calendar #calendar [options]="calendarOptions" [eventSources]="[eventSource]">
  <ng-template #eventContent let-arg>
    <div [ngbTooltip]="arg.event.extendedProps.title" *ngIf="view === 'dayGridMonth'" [openDelay]="400"
         placement="top"
         container="body"
         class="text-truncate">
      <span class="fc-time">{{arg.timeText}}</span>
      <span class="label label-rounded" *ngFor="let label of arg.event.extendedProps.labels"
            [ngClass]="label.className.join(' ')" [innerHTML]="label.key">
      </span>
      <span>{{arg.event.title}}</span>
    </div>
    <div [ngbTooltip]="arg.event.extendedProps.title" *ngIf="view !== 'dayGridMonth'" [openDelay]="400"
         placement="top"
         container="body">
      <div class="fc-time">{{arg.timeText}}</div>
      <div class="fc-title">
        <span class="label label-rounded" *ngFor="let label of arg.event.extendedProps.labels"
              [ngClass]="label.className.join(' ')" [innerHTML]="label.key">
        </span>
        <span>{{arg.event.title}}</span>
      </div>
    </div>
  </ng-template>
</full-calendar>

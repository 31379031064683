<form name="paymentNotesForm" #paymentNotesForm="ngForm" id="paymentNotesForm" (ngSubmit)="register(paymentNotesForm.form)">
  <div class="row hidden-print">
    <div class="col-sm-6 col-xs-6 col-6">
      <button class="btn btn-link no-padding-left modern-back-button" title="Back to roster"
        (click)="goBack()"><span class="fa fa-chevron-left"></span>Back</button>
    </div>
    <div class="col-xs-6 col-6 col-sm-6">
    </div>
  </div>

  <div class="">
    <div class="row standard-margin-bottom">
      <div class="col-xs-12">
        <h4>You will be registered as 'Pending' and will need to pay or provide a proof of payment at the event.</h4>
      </div>
    </div>

    <div class="row  standard-margin-bottom">
      <div class="col-xs-12" [ngClass]="{'has-error':  paymentNotes.invalid && (paymentNotesForm.submitted || paymentNotes.dirty)}">
        <label for="paymentNotes">Payment notes (Please specify how this course will be paid for)</label>
        <textarea id="paymentNotes" #paymentNotes="ngModel" class="form-control  resize-vertical required" placeholder="Your comments here" required
          [(ngModel)]="paymentNotesModel" rows="10" maxlength="255" name="paymentNotes"
          [disabled]="!!registerSubscription"></textarea>
        <p *ngIf="paymentNotes.invalid && (paymentNotesForm.submitted || paymentNotes.dirty)" class="error-block">
          Payment notes is required
        </p>
      </div>
    </div>

    <div class="row margin-bottom">
      <div class="col-xs-12 text-right">

        <button type="button" class="btn btn-danger sm-margin-right " (click)="goBack()"
          title="Cancel">Cancel</button>
        <button type="submit" class="btn btn-primary sm-margin-left" [ladda]="!!registerSubscription" data-spinner-color="#FFF"
          [disabled]="!!registerSubscription" title="Register">Register</button>
      </div>
    </div>

  </div>
</form>
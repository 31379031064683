import { Directive, ElementRef, EventEmitter, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { ISearchFilterItem } from 'modules/search/models/search-filters.models';
import { ICompetency } from 'modules/competency/models/competency.model';
import { ISearchFilterItemUpgraded } from 'app/deprecated/search/models/search-filters.models';


@Directive({
  selector: 'competencies-tree-view',
})
export class CompetenciesTreeViewDirective extends UpgradeComponent {
  static readonly selector = 'competenciesTreeView';

  @Input() competencies: ICompetency[];
  @Input() selectedItems: (ISearchFilterItem | ISearchFilterItemUpgraded)[];
  @Output() selectedItemsChange = new EventEmitter<ISearchFilterItem[]>();

  constructor(elementRef: ElementRef, injector: Injector) {
    super(CompetenciesTreeViewDirective.selector, elementRef, injector);
  }
}

<div
  class="tw-w-full tw-aspect-video tw-relative tw-rounded-lg tw-overflow-hidden"
  [ngClass]="{'tw-bg-gradient-to-tr tw-from-brand-12 tw-to-brand-10': showDefaultIcon && !disableDefaultIcon}"
>
  <img
    *ngIf="thumbnailUrl"
    [src]="thumbnailUrl"
    alt="Course Thumbnail"
    class="tw-w-full tw-aspect-video tw-object-cover"
  />
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 79.73 72.56"
    aria-hidden="true"
    *ngIf="showDefaultIcon && !disableDefaultIcon"
    class="tw-absolute tw-m-auto tw-inset-0 tw-size-16 tw-stroke-brand-1"
  >
    <defs>
      <style>
        .cls-1 {
          fill: none;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 8px;
        }
      </style>
    </defs>
    <title>book-default</title>
    <path
      class="cls-1"
      d="M4.17,3.75h21.5A14.34,14.34,0,0,1,40,18.08V68.25A10.75,10.75,0,0,0,29.25,57.5H4.17Z"
      transform="translate(-0.14 0.28)"
    />
    <path
      class="cls-1"
      d="M75.83,3.75H54.33A14.34,14.34,0,0,0,40,18.08V68.25A10.75,10.75,0,0,1,50.75,57.5H75.83Z"
      transform="translate(-0.14 0.28)"
    />
  </svg>
</div>

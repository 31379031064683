import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ICourseTile } from 'modules/course/common/models/course.model';
import { courseDefaults } from 'modules/course/common/course.consts';
import { PlayCourseService } from 'modules/course/common/services/play-course.service.ajs-upgraded-provider';
import {
  LearningObjectRegistrationWorkflowService
} from 'modules/course-registrations/services/learning-object-registration-workflow.service.ajs-upgraded-provider';
import moment from 'moment';
import _ from 'lodash';
import { IUserCourse } from 'modules/course-registrations/models/course-registration.model';


@Component({
  selector: 'course-info-indicators-board',
  templateUrl: './course-info-indicators-board.component.html',
})
export class CourseInfoIndicatorsBoardComponent implements OnInit {
  @HostBinding('class.hidden') hidden = false;
  @Input() course: ICourseTile;

  loadingWorkflow = false;
  isEmptyObject = _.isEmpty;
  workflow: LearningObjectRegistrationWorkflowService;
  private _userCourse: IUserCourse;
  constructor(
    public playCourseService: PlayCourseService,
  ) { }


  @Input() set userCourse(uc:  IUserCourse) {
    this._userCourse = uc;
    this.updateVisibility();
  }

  get userCourse() {
    return this._userCourse;
  }

  ngOnInit() {
    this.updateVisibility();
  }

  isMandatory() {
    return _.get(this.userCourse, 'lastRegistration.mandatory', false);
  }

  isNew() {
    return this.course &&
      moment().diff(moment(this.course.created), 'days') < courseDefaults.newUpdatePeriodDays;
  }

  isUpdated() {
    const isNew = this.isNew();
    const modified = this.course?.modified ||  this.course?.updated;
    const modifiedDate =   moment(modified);
    const diff = moment().diff(modifiedDate, 'days');

    return !isNew && modified && diff < courseDefaults.newUpdatePeriodDays;
  }

  private updateVisibility() {
    this.hidden = !(this.isMandatory() || this.isNew() || this.isUpdated());
  }
}

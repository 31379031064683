<article
  class="tw-w-full tw-flex tw-gap-4 tw-bg-gray-2 tw-border tw-border-gray-3 tw-p-4 tw-text-gray-12 tw-rounded-lg"
  *ngIf="!smallView && courseTile"
>
  <a
    title="{{ courseTile.name }}"
    class="tw-w-80 tw-shrink-0"
    tabindex="-1"
    [trackLinkClick]="trackingName"
    aria-hidden="true"
    uiSref="main.course"
    [uiParams]="{ id: courseTile.id }"
    [uiOptions]="{ reload: true, notify: true }"
    [id]="trackingName + '_course_link'"
  >
    <span class="tw-sr-only">{{ courseTile.name }} thumbnail</span>
    <course-thumbnail [course]="courseTile"></course-thumbnail>
  </a>

  <div class="tw-flex tw-flex-col tw-gap-2 tw-flex-1">
    <h3 class="tw-text-lg tw-m-0 tw-p-0 tw-font-medium tw-line-clamp-3">
      <a
        href="#"
        title="{{ courseTile.name || courseTile.title }}"
        uiSref="main.course"
        class="tw-text-brand-9 hover:tw-text-brand-10 tw-rounded focus-visible:tw-outline-brand-7 focus-visible:tw-text-brand-11 tw-focus:outline-hidden"
        [uiParams]="{ id: courseTile.id, currentBlsId: currentBlsId }"
        [trackLinkClick]="trackingName"
        name="{{ courseTile.id }}"
        [uiOptions]="{ reload: true, notify: true }"
        [id]="trackingName + '_course_link2'"
        [innerHtml]="courseTile.name || courseTile.title"
      ></a>
    </h3>

    <p class="tw-p-0 tw-m-0 tw-line-clamp-3">
      {{ courseTile.description | markdownToHtml | htmlToPlainText }}
    </p>

    <p
      *ngIf="showOrganizationName || courseTile.createdBy"
      class="tw-p-0 tw-m-0 tw-text-gray-11 tw-text-base"
    >
      by
      {{
        showOrganizationName
          ? courseTile.organization_name
          : courseTile.createdBy?.firstName +
            ' ' +
            courseTile.createdBy?.lastName
      }}
    </p>

    <course-expiration-date
      *ngIf="courseTile.expirationDate || courseTile.deactivationDate"
      [course]="courseTile"
      [short]="true"
      [variant]="'secondary'"
    ></course-expiration-date>

    <div class="tw-flex tw-gap-3 tw-items-center">
      <!--1st horizontal line-->
      <course-star-rating
        [course]="courseTile"
        [singleStar]="true"
        class=""
      ></course-star-rating>

      <course-info-learners-count
        [course]="courseTile"
        *ngIf="courseTile.learners > 0"
      >
      </course-info-learners-count>

      <course-length
        *ngIf="courseTile.length"
        [course]="courseTile"
      ></course-length>
      <!-- TODO: move to component -->
      <div
        *ngIf="courseTile.certificates?.length > 0"
        class="tw-flex tw-gap-1.5 tw-items-center"
      >
        <i class="fa-solid fa-award tw-text-gray-11" aria-hidden="true"></i>
        <span>Certificate</span>
      </div>
    </div>

    <div class="tw-flex tw-gap-3 tw-items-center">
      <!--2nd horizontal line-->
      <course-info-indicators-board
        [course]="courseTile"
        [userCourse]="userCourse"
      ></course-info-indicators-board>

      <course-bookmark
        [trackingName]="trackingName"
        (registrationChanged)="onRegistrationChanged($event)"
        [course]="courseTile"
        [userCourse]="userCourse"
        *ngIf="!hideBookmark"
      ></course-bookmark>
    </div>

    <ng-content select="[format-item-labels]"></ng-content>

    <ng-container [ngTemplateOutlet]="footer"></ng-container>
  </div>

  <!--launch-->
  <div class="tw-flex tw-flex-col tw-gap-3 tw-text-right">
    <div class="tw-font-bold tw-text-lg" *ngIf="hasPrice(courseTile.price)">
      {{ formatPrice(courseTile.price) }}
    </div>
    <div class="hidden-print" *ngIf="!hideRegManagement" class="tw-min-w-24">
      <!--status/checkmark-->
      <course-registration-status
        [course]="courseTile"
        [regData]="courseTile.registration"
        mode="modern"
        *ngIf="completed"
        [showHightStatus]="true"
        [showIcon]="true"
      ></course-registration-status>
      <!--launch/buttons-->
      <fieldset [disabled]="frozen" class="tw-m-0">
        <play-course
          [course]="courseTile"
          [mode]="mode"
          [userCourse]="userCourse"
          *ngIf="!completed"
          [trackingName]="trackingName"
          [actionData]="actionData"
        >
        </play-course>
      </fieldset>
    </div>
  </div>
</article>

<simple-course-tile
  *ngIf="smallView"
  [course]="inputCourse"
  [currentBlsId]="currentBlsId"
  [showMandatory]="showMandatory"
  [actionData]="actionData"
  [trackingName]="trackingName"
  [userCourse]="userCourse"
  [mode]="mode"
  [showRegManagement]="!hideRegManagement"
  [hideBookmark]="hideBookmark"
>
  <div class="tw-text-gray-11">
    <ng-container [ngTemplateOutlet]="footer"></ng-container>
  </div>
</simple-course-tile>

<ng-template #footer>
  <ng-content></ng-content>
</ng-template>

import { IEventLabel } from '../models/events.model';


export const eventLabels: Record<string, IEventLabel> = {
  registered: {
    key: 'R',
    name: 'Registered',
    className: ['label-calendar', 'label-registered'],
    badgeClassName: ['badge-calendar', 'badge-registered']
  },
  waitList: {
    key: '<span class="fa fa-hourglass-half"></span>',
    name: 'Registered, no seats available',
    className: ['label-calendar', 'label-registered'],
    badgeClassName: ['badge-calendar', 'badge-registered']
  },
  unavailable: {
    key: 'X',
    name: 'Unavailable',
    className: ['label-calendar', 'label-unavailable'],
    badgeClassName: ['badge-calendar', 'badge-unavailable']
  },
  other: {
    key: 'N',
    name: 'Calendar',
    className: ['label-calendar', 'label-other'],
    badgeClassName: ['badge-calendar', 'badge-other']
  },
  exercise: {
    key: 'E',
    name: 'Drill/Exercise',
    className: ['label-calendar', 'label-exercise'],
    badgeClassName: ['badge-calendar', 'badge-exercise']
  },
  conference: {
    key: 'C',
    name: 'Conference',
    className: ['label-calendar', 'label-conference'],
    badgeClassName: ['badge-calendar', 'badge-conference']
  },
  video: {
    key: 'V',
    name: 'Video Conference',
    className: ['label-calendar', 'label-video'],
    badgeClassName: ['badge-calendar', 'badge-video']
  },
};

import { Component, Input } from '@angular/core';
import { BaseCourseTileComponent } from 'modules/course/views/tiles/components/base-course-tile.component';
import { GlobalConfig } from 'core/environment';
import { CourseService } from 'modules/course/common/services/course.service';
import { CurrentUserService } from 'ajs/modules/app/current-user.service';
import { UserCourseService } from 'modules/course-registrations/services/user-course.service';


@Component({
  selector: 'simple-course-tile',
  templateUrl: './simple-course-tile.component.html',
})
export class SimpleCourseTileComponent extends BaseCourseTileComponent {
  static readonly selector = 'simpleCourseTile';

  @Input() hideBookmark = false;

  constructor(
    globalConfig: GlobalConfig,
    window: Window,
    courseService: CourseService,
    userCourseService: UserCourseService,
    currentUser: CurrentUserService,
  ) {
    super(globalConfig, window, courseService, userCourseService, currentUser);
  }

  shouldLoadUserCourse(): boolean {
    return !this.userCourse && (this.showMandatory || !this.hideBookmark);
  }
}

<section
  class="tw-rounded-xl tw-border tw-border-gray-3 tw-bg-gray-2 tw-overflow-clip text-sla"
  *ngIf="post"
>
  <img
    *ngIf="heroBackground"
    class="tw-h-48 sm:tw-h-64 md:tw-h-96 tw-w-full tw-object-cover tw-aspect-auto tw-bg-gray-8"
    [src]="heroBackground"
  />

  <div class="tw-flex tw-flex-col tw-gap-4 tw-p-4 md:tw-p-8">
    <h2
      class="tw-text-xl md:tw-text-2xl lg:tw-text-3xl tw-text-gray-12 tw-m-0"
      *ngIf="post.showTitle"
      [innerHTML]="post.title"
    ></h2>

    <div
      class="tw-line-clamp-3 tw-text-gray-11"
      [innerHTML]="post.getContent()"
    ></div>

    <div class="tw-flex tw-items-center tw-gap-3">
      <a
        href="#"
        class="twc-btn twc-btn-primary"
        uiSref="main.blog"
        [uiParams]="{ id: post.id }"
        [trackLinkClick]="'announcement detail'"
      >
        Read more
      </a>
      <a
        class="tw-text-base tw-font-normal"
        uiSref="main.news"
        [trackLinkClick]="'announcements'"
        >View all</a
      >
    </div>
  </div>
</section>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ElmsUtils } from 'core/utils/elms-utils';
import {
  ICourseRegistrationQuestion
} from 'modules/course-registrations/models/course-registration-question.model';


enum CourseRegistrationQuestionAPIPath {
  GET = '/a/course_details/:courseId/custom_fields/:questionId/',
  PUT = '/a/course_details/:courseId/custom_fields/:questionId/',
  DELETE = '/a/course_details/:courseId/custom_fields/:questionId/',
  POST = '/a/course_details/:courseId/custom_fields/',
  QUERY = '/a/course_details/:courseId/custom_fields/',
}

@Injectable()
export class CourseRegistrationQuestionService {
  constructor(private http: HttpClient) {
  }

  public get(courseId: number, questionId: number): Observable<ICourseRegistrationQuestion> {
    return this.http.get<ICourseRegistrationQuestion>(
      ElmsUtils.formatUrl(CourseRegistrationQuestionAPIPath.GET, { courseId, questionId }),
    );
  }

  public query(courseId: number): Observable<ICourseRegistrationQuestion[]> {
    return this.http.get<ICourseRegistrationQuestion[]>(
      ElmsUtils.formatUrl(CourseRegistrationQuestionAPIPath.QUERY, { courseId })
    );
  }

  public save(
    courseId: number, payload: Partial<ICourseRegistrationQuestion>
  ): Observable<ICourseRegistrationQuestion> {
    return this.http.post<ICourseRegistrationQuestion>(
      ElmsUtils.formatUrl(CourseRegistrationQuestionAPIPath.POST, { courseId }),
      payload
    );
  }

  public update(
    courseId: number, questionId: number, payload: Partial<ICourseRegistrationQuestion>
  ): Observable<ICourseRegistrationQuestion> {
    return this.http.put<ICourseRegistrationQuestion>(
      ElmsUtils.formatUrl(CourseRegistrationQuestionAPIPath.PUT, { courseId, questionId }),
      payload
    );
  }

  public delete(courseId: number, questionId: number): Observable<null> {
    return this.http.delete<null>(
      ElmsUtils.formatUrl(CourseRegistrationQuestionAPIPath.GET, { courseId, questionId }),
    );
  }
}

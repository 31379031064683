<div class="flex product-tile">

  <div class="flex-row">
    <div class="flex-col ">
      <div [ngSwitch]="product.typeId" class=" ecommerce-thumbnail-wrapper ">
        <course-thumbnail [course]="course" *ngSwitchCase="productType.COURSE"></course-thumbnail>
        <course-thumbnail [course]="course" *ngSwitchCase="productType.SESSION"></course-thumbnail>
        <div *ngSwitchDefaultt>
          <span class="fa fa-gift fa-4"></span>
        </div>
      </div>
    </div>

    <!--accessibility: Buy '{{vm.product.name}}' {{vm.product.price ? 'for $' + vm.product.price: ' ' }} -->
    <div class="flex-col session flex-col-grow"> <!--ng-class="{'flex-col-grow':vm.product.session}"-->

      <a uiSref="main.course" [uiParams]="{id: course.id}" class="course-title" [title]="'Open ' + course.name">
        {{course.name}}
      </a>

      <h4 class="session-date-title" *ngIf="product.data && product.typeId === productType.SESSION">
        <span *ngIf="product.data.startDate" class="session-time-title">
          <span>
            <date-time [date]="product.data.startDate"></date-time> - <date-time
              [date]="product.data.endDate"></date-time>
          </span>
        </span>
      </h4>

      <div *ngIf="product.data && product.typeId === productType.SESSION">
        <div *ngIf="product.data.locationName || product.data.locationDescription ||
          product.data.locationDistance" class="overflow-hidden sm-margin-bottom">
          <span *ngIf="product.data.locationName">{{product.data.locationName}}</span>
          <span *ngIf="product.data.locationDescription"
            [innerHtml]="product.data.locationDescription | markdownToHtml"></span>
          <em *ngIf="product.data.locationDistance"
            class="text-muted">({{product.data.locationDistance| number : '1.1-1'}} miles away)</em>
        </div>
      </div>

      <div class="alert alert-sm alert-danger" *ngIf="!!purchaseError">{{purchaseError}}</div>
    </div>

    <div class="flex-col flex-col-5x text-right" *ngIf="!!product.price">
      <span class="course-price">${{product.price | number: '1.2-2'}}</span>
    </div>
  </div>
</div>
<div class="tile tile-search-shortcut tile-hot-topics" *ngIf="mode === 'list'">
  <div *ngIf="channel" class="tile-bar">
    <h4 class="no-margin-bottom xs-margin-top"><span [innerHtml]="channel.name"></span></h4>
  </div>

  <div *ngIf="posts" class="tile-body overflow-auto">
    <div *ngFor="let post of posts;" [innerHtml]="post.getContent() | linksSelfTarget" class="post"></div>
  </div>

</div>


<div *ngIf="mode !== 'list'" class="">
  <h3 class="mt-20 mb-15" *ngIf="channel">
    <span [innerHtml]="channel.name"></span>
  </h3>

  <div *ngIf="posts" class="hot-topics-tiles">
    <div *ngFor="let post of posts;" [innerHtml]="post.getContent() | linksSelfTarget" class="hot-topics-tile"></div>
  </div>
</div>

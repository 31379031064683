import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid';
import { StateService, Transition } from '@uirouter/core';
import { combineLatest, lastValueFrom, map, Observable, throwError } from 'rxjs';
import { CheckoutComponent } from './components/checkout.component';
import { CheckoutProductType, ICheckoutProduct } from './models/checkout.models';
import { CourseSessionService } from 'modules/course/sessions/services/course-session.service';
import { IUserCourseSession } from 'modules/course/sessions/models/course-session.models';
import { ICourseDetails } from 'modules/course/common/models/course.model';
import { NotificationService } from 'ajs/modules/app/environment/notification-service';
import _ from 'lodash';
import { CheckoutConfirmationComponent } from './components/checkout-confirmation.component';
import { EcommercePurchaseService } from './services/ecommerce-purchase.service';
import { CourseService } from 'modules/course/common/services/course.service';


export const ecommerceCheckoutRoutingModule: NgHybridStateDeclaration[] = [
  {
    name: 'main.checkout',
    url: '/checkout?courseId&sessionId&price',
    component: CheckoutComponent,
    params: {
      courseId: null,
      sessionId: null,
      price: null
    },
    data: {
      label: 'Checkout',
      availableFor: ['anonymous', 'regularUser', 'admin'],

    },
    resolve: [
      {
        provide: 'productItem',
        deps: [Transition, StateService, CourseService, CourseSessionService, NotificationService],
        useFactory: (transition: Transition, stateService: StateService, courseService: CourseService,
          courseSessionService: CourseSessionService, notificationService: NotificationService) => {
          const transitionParams = transition.params();

          const courseId = transitionParams.courseId;
          const price = transitionParams.price && parseFloat(transitionParams.price);
          const sessionId = transitionParams.sessionId && parseInt(transitionParams.sessionId);
          const productTypeId = sessionId ? CheckoutProductType.SESSION : CheckoutProductType.COURSE;
          const requestSources: Observable<ICourseDetails | IUserCourseSession >[] = [courseService.get(courseId)];

          if (sessionId) {
            requestSources.push(courseSessionService.get(courseId, sessionId));
          }

          return lastValueFrom(
            combineLatest(requestSources)
              .pipe(
                map(results => {
                  const course = <ICourseDetails>results[0];
                  const session = productTypeId === CheckoutProductType.SESSION ? <IUserCourseSession>results[1] : null;

                  if (!price && !course.price) {
                    transition.abort();
                    notificationService.error('Purchase is not allowed.', 3e3);
                    stateService.go('main.course', { id: courseId });

                    return throwError(() => new Error());
                  }

                  const product: ICheckoutProduct  = {
                    id: session?.id || course.id,
                    typeId: productTypeId,
                    name: course.name,
                    price: price || (typeof course.price === 'number' ? course.price : course.price.amount),
                    data: null
                  };

                  if (session) {
                    const locations = _.uniqBy(session.schedules, 'locationId');
                    const mainLocation = locations.length === 1
                      ? locations[0].onlineMeetingIntegration
                        ? null
                        : locations[0]
                      : null;

                    product.data = {
                      courseId: course.id.toString(),
                      startDate: session.schedules[0].start_date,
                      endDate: session.schedules[session.schedules.length - 1].end_date,
                      locationName: mainLocation ? mainLocation.name : null,
                      locationDescription: mainLocation ? mainLocation.locationDescription : null,
                      locationDistance: mainLocation ? mainLocation.distance : null
                    };
                  }

                  return product;
                })
              )
          );
        }
      }
    ]
  },
  {
    name: 'main.checkoutConfirmation',
    url: '^/checkout/{purchaseId}/result',
    data: {
      label: 'Checkout',
      availableFor: ['admin', 'regularUser']
    },
    params: {
      purchaseId: null
    },
    component: CheckoutConfirmationComponent,
    resolve: [
      {
        provide: 'purchase',
        deps: [Transition, EcommercePurchaseService],
        useFactory: (transition: Transition, ecommercePurchaseService: EcommercePurchaseService) => {
          return lastValueFrom(ecommercePurchaseService.get(transition.params().purchaseId));
        }
      }
    ]
  }
];

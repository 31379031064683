import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ExternalApplicationService } from 'modules/external-applications/services/external-application.service';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  declarations: [
  ],
  providers: [
    ExternalApplicationService
  ],
})
export class ExternalApplicationsModule { }

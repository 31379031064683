import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { DiscussionsService } from 'modules/discussions/services/discussions.service';
import { DiscussionComponent } from 'modules/discussions/view/discussion.component';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { discussionsRoutingModule } from 'modules/discussions/discussions-routing.module';
import { NetworkModule } from 'modules/network/network.module';
import { LmsComponentsModule } from 'components/kmi.lms.components.module';
import { FormsModule } from '@angular/forms';


@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    HttpClientModule,
    LmsComponentsModule,
    NetworkModule,
    UIRouterUpgradeModule.forChild({ states: discussionsRoutingModule }),
  ],
  declarations: [
    DiscussionComponent
  ],
  providers: [
    HttpClient,
    DiscussionsService,
  ]
})
export class DiscussionsModule {}

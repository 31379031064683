import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Player } from 'modules/course/player/services/player.service';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { ILearningObjectRegistration } from 'modules/course/common/models/learning-object-registration.model';
import { CollectionsService } from 'modules/course/services/collections.service.ajs-upgraded-provider';
import { ICourseAbstract } from 'modules/course/common/models/course.model';


@Component({
  selector: 'player-related-collections',
  templateUrl: './player-related-collections.component.html',
})
export class PlayerRelatedCollectionsComponent implements OnInit {
  @Input() registration: ILearningObjectRegistration;
  @Input() player: Player;
  @Input() trackingName: string;
  @Output() recommendationsBound = new EventEmitter<number>();

  collections: ICourseAbstract[];

  constructor(
    private collectionsService:CollectionsService,
  ) { }

  ngOnInit() {
    fromPromise(this.collectionsService.getCourseCollections(this.registration.course.id))
      .subscribe((collections) => {
        this.collections = collections.sort((a, b) => a.name.localeCompare(b.name));
        this.recommendationsBound.emit(this.collections.length);
      });
  }
}

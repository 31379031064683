import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICalendarEvent } from '../models/events.model';


@Component({
  selector: 'calendar-event-search-list',
  templateUrl: './event-search-list.component.html'
})
export class CalendarEventSearchListComponent {
  @Input() items: ICalendarEvent[];
  @Input() total: number;
  @Input() disabled: boolean;
  @Output() requestMoreChange = new EventEmitter<void>();

  requestMore() {
    this.requestMoreChange.emit();
  }
}

<!-- BLOG POST TEMPLATE -->
<div class="row">
    <div class="col-xs-12">
        <div class="pull-left">
            <a href="" (click)="$event.stopPropagation(); $event.preventDefault(); goBack()" class="modern-back-button btn btn-link standard-margin-right"  title="Go back"><i class="fa fa-chevron-left"></i>Back</a>
            <a [href]="editPath" class="btn btn-default" *ngIf="isEditable" title="Edit post">Edit <span class="fa-solid fa-pencil"></span></a>
        </div>
    </div>
</div>

<!-- BLOG POST INFO -->
<h3 *ngIf="post.showTitle">{{post.title}}</h3>

<div class="row">
  <div class="col-xs-12">
    <div>
      <p class="author" *ngIf="post.showAuthorInfo">Created by
        <a *ngIf="post.createdBy?.userProfileExist && isStateAvailable('main.user_network')"
        href="" uiSref="main.user_network" [uiParams]="{userId: post.createdBy.id}">{{post.createdBy.firstName}}&nbsp;{{post.createdBy.lastName}}</a>
        <span *ngIf="!post.createdBy?.userProfileExist || !isStateAvailable('main.user_network')">{{post.createdBy.firstName}}&nbsp;{{post.createdBy.lastName}}</span>
      </p>

      <p *ngIf="post.haveBeenUpdated() && post.showAuthorInfo" class="author">Modified by <a *ngIf="post.updatedBy?.userProfileExist && isStateAvailable('main.user_network')"
                                                                                                      href="" uiSref="main.user_network" [uiParams]="{userId: post.updatedBy.id}">{{post.updatedBy.firstName}}&nbsp;{{post.updatedBy.lastName}}</a>
        <em *ngIf="!post.updatedBy?.userProfileExist">{{post.updatedBy.firstName}}&nbsp;{{post.updatedBy.lastName}}</em> on <span class="text-muted">{{post.updatedDate|date:'mediumDate'}}</span>
      </p>

      <p class="label-list">

        <a *ngFor="let tag of post.tags"  uiSref="main.search" [uiParams]="{tag:tag.name}" [title]="tag.name" class="label label-tag">
          <span class="fa-solid fa-tag"></span> {{tag.name}}
        </a>
      </p>

      <div [innerHTML]="post.getContent()"></div>

      <h2 class="text-muted" *ngIf="post.comments?.length">Comments</h2>
      <table class="table">
        <tr *ngFor="let comment of post.comments">
          <td>
            <div><strong>{{comment.title}}</strong></div>
            <div class="author">
              By <em><span [hidden]="comment.anonymous || !comment.createdBy">{{comment.createdBy.firstName}} {{comment.createdBy.lastName}}</span><span
              *ngIf="comment.anonymous || !comment.createdBy">Anonymous</span></em> on <em>{{comment.createdDate|date:'mediumDate'}}</em></div>
            <div [innerHTML]="comment.content"></div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>

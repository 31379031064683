import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { IEmailTemplate } from '../models/email.model';
import { EmailTemplateService } from '../services/email-template.service';
import { Unsubscribable, finalize, tap } from 'rxjs';


@Component({
  selector: 'email-preview',
  templateUrl: './email-preview.component.html',
})
export class EmailPreviewComponent implements OnInit, OnDestroy {
  @Input() patternId: number;
  @Input() variables: Record<string, string>;
  @Input() courseId: number;
  @Input() notification: string;

  emailTemplate: IEmailTemplate;

  private emailTemplateSubscriber?: Unsubscribable;

  constructor(private emailTemplateService: EmailTemplateService) { }

  ngOnInit(): void {
    const observable = (this.courseId && this.notification) ?
      this.emailTemplateService.getEmailTemplatePreview(this.notification, this.courseId, this.variables) :
      this.emailTemplateService.getEmailPreview(this.patternId, this.variables);

    this.emailTemplateSubscriber = observable
      .pipe(
        tap(emailTemplate => {
          this.emailTemplate = emailTemplate;
        }),
        finalize(() => {
          this.clearEmailTemplateSubscriber();
        })
      ).subscribe();
  }

  ngOnDestroy(): void {
    this.clearEmailTemplateSubscriber();
  }

  private clearEmailTemplateSubscriber(): void {
    this.emailTemplateSubscriber?.unsubscribe();
    delete this.emailTemplateSubscriber;
  }
}

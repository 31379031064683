import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { calendarRoutingModule } from 'modules/calendar/calendar-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FullCalendarModule } from '@fullcalendar/angular';
import { CalendarEventsService } from 'modules/calendar/services/calendar-events.service';
import { CalendarEventsComponent } from 'modules/calendar/components/calendar-events.component';
import { CalendarComponent } from 'modules/calendar/components/calendar.component';
import { LmsComponentsModule } from 'components/kmi.lms.components.module';
import { LearningCalendarComponent } from './components/learning-calendar.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CalendarListViewComponent } from './components/calendar-list-view.component';
import { CourseRegistrationCommonModule } from 'modules/course-registrations/course-registration-common.module';
import { CalendarEventSearchListComponent } from './components/event-search-list.component';
import { SearchDeprecatedModule } from '../../deprecated/search/search.deprecated.module';


@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    HttpClientModule,
    NgbTooltipModule,
    FullCalendarModule,
    LmsComponentsModule,
    // SearchCommonModule, // Please don't touch!.
    SearchDeprecatedModule,
    CourseRegistrationCommonModule,
    UIRouterUpgradeModule.forChild({ states: calendarRoutingModule }),
  ],
  declarations: [
    CalendarComponent,
    CalendarEventsComponent,
    LearningCalendarComponent,
    CalendarListViewComponent,
    CalendarEventSearchListComponent
  ],
  providers: [
    CalendarEventsService
  ],
  exports: [
    CalendarEventsComponent
  ]
})
export class CalendarModule { }

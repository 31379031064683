<div class="flex">
  <div class="flex-row text-right">


    <div class="flex-col flex-col-grow" *ngIf="registration?.actions.includes('PrintAction')">
      <div class="dropdown visible-xs player-actions-dropdown" ngbDropdown>
        <button class="btn btn-default btn-sm dropdown-toggle"
                ngbDropdownToggle
                type="button" data-toggle="dropdown">Actions
          <span class="fa fa-caret-down"></span>
        </button>

        <ul class="dropdown-menu pull-right" ngbDropdownMenu>
          <li *ngIf="registration?.hasTranscript" ngbDropdownItem>
            <a href="#" (click)="trainingService.viewTranscript(course?.id)"
               title="Transcript">
              <span class="fa fa-file"></span>
              Transcript
            </a>
          </li>
          <li *ngIf="!registration?.hasTranscript" ngbDropdownItem>
            <a href="/d/course/{{course?.id}}/transcript/"
               target="_blank" title="Print Course">
              <span class="fa fa-print"></span> Print Course
            </a>
          </li>
        </ul>
      </div>

      <button type="button" class="btn btn-sm btn-default navbar-btn navbar-right hidden-xs"
              (click)="trainingService.viewTranscript(course?.id)"
              *ngIf="registration?.hasTranscript" title="Transcript">
        <span class="fa fa-file"></span> Transcript
      </button>

      <a href="/d/course/{{course?.id}}/transcript/"
         class="btn btn-sm btn-default navbar-btn navbar-right btn-opaque hidden-xs"
         target="_blank" *ngIf="!registration?.hasTranscript" title="Print Course">
        <span class="fa fa-print"></span> Print Course
      </a>
    </div>
  </div>
</div>

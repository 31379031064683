<div class="tile tile-blog">

  <div class="tile-bar">
    <a *ngIf="showNewsLink" href="#" uiSref="main.news" aria-label="post" style="font-size: 1.5em; color: white;"
      id="blog_posts_rotator_view_all" [trackLinkClick]="'announcements'">
      <span class="fa fa-circle-exclamation"></span>
    </a>
    <div>
      <span *ngIf="!showNewsLink" class="fa fa-circle-exclamation"></span>
    </div>
  </div>

  <div class="tile-body">
    <div *ngIf="post">
      <div class="d-flex gap-10 mb-20">
        <div *ngIf="!hideAuthor && post.showAuthorInfo">
          <div *ngIf="post.createdBy">
            <user-avatar [size]="64" [user]="post.createdBy"></user-avatar>
          </div>
        </div>
        <div>
          <h4 class="mt-0 mb-5 line-clamp-2" [hidden]="!post.showTitle">
            <a href="" uiSref="main.blog" [uiParams]="{id: post.id}" [innerText]="post.title"
              id="blog_posts_rotator_blog_link" [trackLinkClick]="'announcements'"></a>
          </h4>
          <div class="small">
            <span *ngIf="!hideAuthor && post.showAuthorInfo"><span [innerHTML]="post.createdBy.firstName"></span> <span
                [innerHTML]="post.createdBy.lastName"></span><br /></span>
            <time *ngIf="!hideAuthor && post.showAuthorInfo" [innerHTML]="post.updatedDate| date:'mediumDate'"></time>
          </div>
        </div>
      </div>
      <p [innerHTML]="post.getContent()"></p>
    </div>
    <div [hidden]="postExists" class="alert alert-info" role="alert">
      There is no news available. Stay tuned!
    </div>
  </div>

  <div class="tile-footer flex flex-vertical-center" *ngIf="rotatorState && rotatorState.count > 1">
    <div class="flex-row">
      <div class="flex-col flex-col-3x">
        <button title="Previous" aria-label="Previous" class="btn btn-tile pull-left" (click)="previousPost()"
          [ladda]="isLoading && rotatorState.direction === 'previous'" data-spinner-color="#000"
          data-style="expand-right" [disabled]="isLoading"
          [hidden]="rotatorState.count === 2 && rotatorState.position === 1">
          <span class="fa fa-chevron-left"></span>
        </button>
      </div>
      <div class="flex-col flex-col-grow text-center text-muted">
        <span *ngIf="showNumeration">{{rotatorState.position}} of {{rotatorState.count}}</span>
      </div>
      <div class="flex-col flex-col-3x">
        <button title="Next" aria-label="Next" class="btn btn-tile pull-right" (click)="nextPost()"
          [ladda]="isLoading && rotatorState.direction === 'next'" data-spinner-color="#000" data-style="expand-left"
          [disabled]="isLoading" [hidden]="rotatorState.count === 2 && rotatorState.position === 2">
          <span class="fa fa-chevron-right"></span>
        </button>
      </div>
    </div>

  </div>
</div>
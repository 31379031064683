<player-collection-components [blsId]="collection.id" class="recommendations-section"
                              [registration]="registration"
                              [player]="player"
                              *ngIf="menuItems.upNext"
                              (recommendationsBound)="menuItemsBound($event)"></player-collection-components>

<player-related-collections *ngIf="menuItems.relatedCollections"
                            [registration]="registration"
                            [player]="player" class="recommendations-section"
                            (recommendationsBound)="menuItemsBound($event)"
                            trackingName="course_player"></player-related-collections>

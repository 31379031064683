<span class="star-rating" [ngClass]="{
                                      'star-rating-1': rating < 1.5,
                                      'star-rating-2': rating >= 1.5 && rating < 2.5,
                                      'star-rating-3': rating >= 2.5 && rating < 3.5,
                                      'star-rating-4': rating >= 3.5 && rating < 4.5,
                                      'star-rating-5': rating >= 4.5
                                    }">
    <span *ngFor="let num of range" [ngClass]="{
    'fa': (num <= rating) || ( num - 0.5 <= rating && rating < num),
    'fa-star': (num <= rating) || ( rating < num - 0.5),
    'fa-star-half-stroke': num - 0.5 <= rating && rating < num,
    'fa-regular': num > rating
    }" aria-hidden="true"></span>
  <span class="sr-only">{{toPrecision(rating)}} stars.</span>
</span>

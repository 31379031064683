import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CertificateService } from 'modules/certificates/services/certificate.service';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  declarations: [],
  providers: [
    CertificateService,
  ]
})
export class CertificatesModule { }

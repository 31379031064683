import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid';
import { lastValueFrom } from 'rxjs';
import { Transition } from '@uirouter/core';
import { EventService } from './services/event.service';
import { EventDetailsComponent } from './view/event-details.component';


export const eventRoutingModule: NgHybridStateDeclaration[] = [
  {
    name: 'main.event',
    url: '^/event/{id}',
    params: {
      id: null
    },
    data: {
      label: 'Event',
      availableFor: ['anonymous', 'regularUser', 'admin'],
    },
    component: EventDetailsComponent,
    resolve: [
      {
        provide: 'event',
        deps: [Transition, EventService],
        useFactory: (transition: Transition, eventService: EventService) => {
          return lastValueFrom(eventService.get(Number(transition.params().id)));
        }
      }
    ]
  }
];

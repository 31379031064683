import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { CourseTilesModule } from 'modules/course/views/tiles/course-tiles.module';


@NgModule({
  imports: [
    UIRouterModule,
    CourseTilesModule,
  ],
  declarations: [
  ],
  exports: [
  ]
})
export class CourseViewsModule { }

import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid';
import { GLOBAL_CONFIG } from 'core/environment/global-config.service.ajs-upgraded-provider';
import { CalendarComponent } from 'modules/calendar/components/calendar.component';


export const calendarRoutingModule: NgHybridStateDeclaration[] = [
  {
    name: 'main.calendar',
    url: '^/calendar?query&view&min_start_date&max_start_date&session_label_id&group_id&user_courses&' +
      'calendarViewMode&mode',
    data: {
      label: 'Calendar',
      get availableFor(): string[] {
        return GLOBAL_CONFIG.settings.fullCalendar.availableFor;
      }
    },
    params: {
      query: {
        value: null,
        dynamic: true
      },
      view: {
        value: null,
        dynamic: true
      },
      min_start_date: {
        value: null,
        dynamic: true
      },
      max_start_date: {
        value: null,
        dynamic: true
      },
      session_label_id: {
        value: null,
        dynamic: true
      },
      group_id: {
        value: null,
        dynamic: true
      },
      user_courses: {
        value: null,
        dynamic: true
      },
      calendarViewMode: {
        value: null,
        dynamic: true
      },
      mode: {
        value: null,
        dynamic: true
      }
    },
    component: CalendarComponent
  }
];

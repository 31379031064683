<div *ngIf="courses?.length">
  <h5>Up Next</h5>
  <ul class="content-list">
    <li class="content-list-item collection-item" *ngFor="let course of courses">
      <collection-tile [course]="course"
                       [player]="player"
                       trackingName="course_player"
                       [currentBlsId]="blsId"
                       [actionData]="{current_bls_id: blsId}">

      </collection-tile>
    </li>
  </ul>
</div>

import { FactoryProvider } from '@angular/core';
import { ICourseAbstract } from 'modules/course/common/models/course.model';
import { ILearningObjectRegistration } from 'modules/course/common/models/learning-object-registration.model';


export abstract class LearningObjectRegistrationService {
  abstract get(course: {id:number|string}, userId?: number): Promise<ILearningObjectRegistration>;

  abstract getFromReg(registration: ILearningObjectRegistration, course?: ICourseAbstract):ILearningObjectRegistration;

  abstract init(attrs);

  abstract reload();

  abstract executeAction(actionName, data, actionUrl);

  abstract getLaunchData(type);

  abstract isCheckState();

  abstract initCompilationStructure(userId);

  abstract hasSessions();

  abstract inStatus(statuses);

  abstract checkStatusActual();

  abstract hasNotification(notificationName);

  abstract notificationSeen(notificationName);

  abstract getStatusId();

  abstract getStatusName();

  abstract getStatusTitle();

  abstract isAddAllAvailable();

  abstract isExportAllCoursesAvailable(ignoreSettings);
}

export function learningObjectRegistrationServiceFactory(
  injector: angular.auto.IInjectorService
): LearningObjectRegistrationService {
  return injector.get<LearningObjectRegistrationService>('LearningObjectRegistration');
}

export const learningObjectRegistrationServiceProvider: FactoryProvider = {
  provide: LearningObjectRegistrationService,
  useFactory: learningObjectRegistrationServiceFactory,
  deps: ['$injector']
};

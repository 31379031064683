<span class="twc-badge" [ngClass]="{
    'twc-badge-secondary': variant === 'secondary',
    'twc-badge-outline-secondary': variant === 'outline-secondary'
  }"
      *ngIf="expirationDate">
  <span class="fa-regular fa-calendar-times" aria-hidden="true"></span>
  Expiration Date
  <date-time *ngIf="!short" [date]="expirationDate"></date-time>
  <date-with-hidden-time
    *ngIf="short"
    [date]="expirationDate"
  ></date-with-hidden-time>
</span>

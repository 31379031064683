import { Component, Input } from '@angular/core';
import { ICourseDetails } from 'modules/course/common/models/course.model';
import { ILearningObjectRegistration } from 'modules/course/common/models/learning-object-registration.model';
import { TrainingService } from 'modules/course-registrations/services/training.service.ajs-upgraded-provider';
import { Player } from 'modules/course/player/services/player.service';


@Component({
  selector: 'player-transcript-button',
  templateUrl: './transcript-button.component.html'
})
export class TranscriptButtonComponent  {
  static readonly selector = 'playerTranscriptButton';

  @Input() course: ICourseDetails;
  @Input() player: Player;
  @Input() registration: ILearningObjectRegistration;

  constructor(
    public trainingService: TrainingService,
  ) {}
}

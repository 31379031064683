import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IBlogPost, IBlogPostChannel } from '../../models/blog-post.model';
import { CurrentUserService } from 'ajs/modules/app/current-user.service';
import { BlogPostService, IQueueBlogPostResponse } from '../../services/blog-post.service';
import { Unsubscribable, tap } from 'rxjs';
import _ from 'lodash';
import { GlobalConfig } from 'core/environment/global-config.service.ajs-upgraded-provider';


@Component({
  selector: 'channel-posts-tile',
  templateUrl: './channel-posts-tile.component.html',
})
export class ChannelPostsTileComponent implements OnInit, OnDestroy {
  static readonly selector = 'channelPostsTile';

  @Input() channelId!: number;
  @Input() limit?: number;
  @Input() count!: number;
  @Input() mode?: 'list';
  @Input() posts?: IBlogPost[];

  @Output() countChange = new EventEmitter<number>();

  channel?: IBlogPostChannel;

  private loadSubscriber?: Unsubscribable;

  private readonly cacheDataKey = 'channelPostsTile:' + this.currentUserService.get().id;

  constructor(
    private currentUserService: CurrentUserService,
    private blogPostService: BlogPostService,
    private globalConfig: GlobalConfig
  ) {}

  ngOnInit(): void {
    if (!this.posts?.length) {
      this.updateDataFromCache();
      this.loadSubscriber = this.blogPostService.queue({
        take: this.limit || 20,
        channelId: this.channelId,
        basePath: _.trim(this.globalConfig.base, '/').toLowerCase()
      }).pipe(
        tap(response => {
          this.updateData(response);
          this.cacheData(response);
        })
      ).subscribe();
    } else {
      this._bindData(this.posts, this.posts.length, true);
    }
  }

  ngOnDestroy(): void {
    if (this.loadSubscriber) {
      this.loadSubscriber.unsubscribe();
      delete this.loadSubscriber;
    }
  }

  private updateData( data: IQueueBlogPostResponse ): void {
    if (!data?.count || !data?.items) {
      return;
    }

    this._bindData(
      data.items,
      data.count
    );
  }

  private _bindData(posts: IBlogPost[], count: number, sanitize = false): void {
    this.posts = posts.map(blogPost => this.blogPostService.transform(blogPost, {}, sanitize));
    this.count = count;
    this.countChange.emit(this.count);
    this.channel = this.posts[0].channel;
  }

  private cacheData(data: IQueueBlogPostResponse): void {
    localStorage.setItem(this.cacheDataKey, JSON.stringify(data));
  }

  private updateDataFromCache(): void {
    const storedData = localStorage.getItem(this.cacheDataKey);

    if (storedData) {
      this.updateData(JSON.parse(storedData));
    }
  }
}

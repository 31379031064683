import { Component, Input, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { CourseService } from 'modules/course/common/services/course.service';
import { IActionItem } from 'modules/navigation/models/navigation.model';


@Component({
  selector: 'subject-area-navigation-items',
  templateUrl: './subject-area-navigation-items.component.html'
})
export class SubjectAreaNavigationItemsComponent implements OnInit {
  static readonly selector = 'subjectAreaNavigationItems';

  @Input() title: string;

  items: IActionItem[] = [];

  constructor(
    private courseService: CourseService,
    private stateService:StateService
  ) {  }


  ngOnInit() {
    this.items = JSON.parse(localStorage.getItem(SubjectAreaNavigationItemsComponent.selector)) || [];

    this.courseService.getCourseOptions()
      .subscribe((options) => {
        this.items.length = 0;
        options.subjectAreas.forEach((item) => {
          if (item.topics) {
            this.items.push(...item.topics.map((item) => ({
              id: item.id,
              name: item.name,
            })));
          }
        });
        localStorage.setItem(SubjectAreaNavigationItemsComponent.selector, JSON.stringify(this.items));
      });
  }

  clicked(item:IActionItem) {
    this.stateService.go('main.fullCatalog', {
      subject: item.id,
      type: 'course',
      catalogType: 'subject'
    });
  }
}

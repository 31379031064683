import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'star-rating',
  templateUrl: './star-rating.component.html',
})
export class StarRatingComponent implements OnInit {
  static readonly selector = 'starRating';

  @Input() rating: number;

  get range(): number[] {
    return [1, 2, 3, 4, 5];
  }

  ngOnInit() {
    this.rating = this.rating || 0;
  }

  toPrecision(num: number): string {
    return num ? num.toPrecision(2) : '';
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { EventService } from './services/event.service';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { eventRoutingModule } from './events-routing.module';
import { EventDetailsComponent } from './view/event-details.component';
import { LmsComponentsModule } from 'components/kmi.lms.components.module';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    LmsComponentsModule,
    UIRouterUpgradeModule.forChild({ states: eventRoutingModule }),
  ],
  declarations: [
    EventDetailsComponent
  ],
  providers: [
    EventService
  ]
})
export class EventsModule {}

<div *ngIf="collections.length">
  <h5>Related Collections</h5>
  <ul class="content-list">
    <li class="content-list-item" *ngFor="let course of collections">
      <collection-tile
        [course]="course"
        [player]="player"
        trackingName="course_player"></collection-tile>
    </li>
  </ul>
</div>

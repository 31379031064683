import { FactoryProvider, InjectionToken } from '@angular/core';
import { catchError, EMPTY, filter, from, map, Observable, of, Subject, switchMap } from 'rxjs';
import {
  LearningObjectRegistrationWorkflowService
} from 'modules/course-registrations/services/learning-object-registration-workflow.service.ajs-upgraded-provider';
import { QuizOptionsService } from 'modules/quiz/services/quiz-options.service';
import { ICourseQuizTypes } from 'modules/course/quiz/models/course-quiz.models';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import {
  CourseRegistrationStatus, courseRegistrationStatuses
} from 'modules/course-registrations/models/course-registration-status.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  LearningObjectRegistrationService
} from 'modules/course-registrations/services/learning-object-registration.service.ajs-upgraded-provider';
import {
  IncompleteConfirmationComponent
} from 'modules/course/player/components/modal/incomplete-confirmation.component';
import { quizEnums } from 'modules/quiz';
import { TrainingService } from 'modules/course-registrations/services/training.service.ajs-upgraded-provider';


export interface IStepType {
  type?: string;
  actionName?: string;
}

export interface IPlayerStep {
  title: string
  name: string;
  defaultAction?: IStepType;
  contentStep: boolean;
  launch: (includePrerequisites: boolean) => Observable<void>;
  overrideEnabled: boolean;
  visible: boolean;
  completed: boolean;
  intendedFor?: number;
  availableTypes?: IStepType[];
  launchable?: boolean;
  available: boolean;
}

export class PreAssessmentStep implements IPlayerStep {
  name = 'pre';
  visible = true;
  overrideEnabled = false;
  contentStep = false;
  intendedFor: number;

  constructor(private registrationWorkflow: LearningObjectRegistrationWorkflowService,
              private courseQuizTypes: ICourseQuizTypes) {
  }

  get title() {
    return this.courseQuizTypes.preAssessment?.name || null;
  }

  get defaultAction() {
    return { type: 'pre' };
  }

  get completed() {
    return this.registrationWorkflow.registration.preAssessment &&
      this.registrationWorkflow.registration.preAssessment.completed;
  }

  get available() {
    return this.registrationWorkflow.hasAction('LaunchPreAssessmentAction') &&
      (!this.registrationWorkflow.registration?.hasSessions() || !!this.registrationWorkflow.registration?.session_id);
  }

  launch(includePrerequisites: boolean): Observable<void> {
    return fromPromise(
      this.registrationWorkflow.exec('LaunchPreAssessmentAction', {}, {
        skipPrerequisites: !includePrerequisites
      }));
  }
}


export class CourseStep implements IPlayerStep {
  name = 'course';
  contentStep = true;
  overrideEnabled = false;
  visible = true;
  intendedFor: number;

  constructor(
    private registrationWorkflow: LearningObjectRegistrationWorkflowService,
    private trainingService: TrainingService) {
  }

  get title() {
    return 'Course';
  }

  get defaultAction() {
    const availableLaunchActions = this.registrationWorkflow.getDefaultLaunchActions();

    return availableLaunchActions.length > 0 ? availableLaunchActions[0] : null;
  }

  get availableTypes(): IStepType[] {
    return this.registrationWorkflow.getAvailableCourseLaunchTypes();
  }

  get launchable() {
    const availableTypes = this.registrationWorkflow.getAvailableCourseLaunchTypes();

    return availableTypes.length > 0;
  }

  get completed():boolean {
    return this.registrationWorkflow.registration.courseCompleted;
  }

  get available() {
    return this.registrationWorkflow.getAvailableCourseLaunchTypes().length > 0 &&
      this.trainingService.canShowContent(this.registrationWorkflow.registration);
  }

  launch(includePrerequisites: boolean): Observable<void> {
    if (this.defaultAction) {
      return fromPromise(
        this.registrationWorkflow.exec(this.defaultAction.actionName, {}, {
          skipPrerequisites: !includePrerequisites
        }));
    } else {
      return fromPromise(
        this.registrationWorkflow.launchContent('course') || Promise.resolve()
      );
    }
  }
}

export class PostAssesmentStep implements IPlayerStep {
  name = 'post';
  visible = true;
  overrideEnabled = false;
  contentStep = false;
  intendedFor: number;

  constructor(private registrationWorkflow: LearningObjectRegistrationWorkflowService,
              private courseQuizTypes: ICourseQuizTypes) {
  }

  get title() {
    return this.courseQuizTypes.assessment?.name || null;
  }

  get defaultAction() {
    return { type: 'post' };
  }

  get completed() {
    return this.registrationWorkflow.registration.postAssessment &&
      this.registrationWorkflow.registration.postAssessment.completed;
  }

  get available() {
    return this.registrationWorkflow.hasAction(
      this.registrationWorkflow.getPostAssessmentLaunchActionName(['LaunchPostAssessmentAction'])
    );
  }

  launch(includePrerequisites: boolean): Observable<void> {
    const postActionName = this.registrationWorkflow.getPostAssessmentLaunchActionName(['LaunchPostAssessmentAction']);

    return fromPromise(
      this.registrationWorkflow.exec(postActionName, {}, {
        skipPrerequisites: !includePrerequisites
      }));
  }
}


export class PreEvaluationStep implements IPlayerStep {
  name = 'preEvaluation';
  visible = true;
  overrideEnabled = false;
  contentStep = false;
  intendedFor: number;

  constructor(private registrationWorkflow: LearningObjectRegistrationWorkflowService,
              private courseQuizTypes: ICourseQuizTypes) {
  }

  get title() {
    return this.courseQuizTypes.preEvaluation?.name || null;
  }

  get defaultAction() {
    return { type: 'preEvaluation' };
  }

  get completed() {
    return this.registrationWorkflow.registration.preEvaluationCompleted &&
      this.registrationWorkflow.registration.preEvaluation.completed;
  }

  get available() {
    return this.registrationWorkflow.hasAction('LaunchPreEvaluationAction');
  }

  launch(includePrerequisites: boolean): Observable<void> {
    return fromPromise(
      this.registrationWorkflow.exec('LaunchPreEvaluationAction', {}, {
        skipPrerequisites: !includePrerequisites
      }));
  }
}

export class EvaluationStep implements IPlayerStep {
  name = 'eval';
  visible = true;
  overrideEnabled = false;
  contentStep = false;
  intendedFor: number;

  constructor(private registrationWorkflow: LearningObjectRegistrationWorkflowService,
              private courseQuizTypes: ICourseQuizTypes) {
  }

  get title() {
    return this.courseQuizTypes.evaluation?.categoryName || null;
  }

  get defaultAction() {
    return { type: 'eval' };
  }

  get completed() {
    return this.registrationWorkflow.registration?.evaluation?.completed ||
      courseRegistrationStatuses.completedSet.includes(this.registrationWorkflow.registration?.status_id);
  }

  get available() {
    return this.registrationWorkflow.hasAction('LaunchEvaluationAction');
  }

  launch(includePrerequisites: boolean): Observable<void> {
    return fromPromise(
      this.registrationWorkflow.exec('LaunchEvaluationAction', {}, {
        skipPrerequisites: !includePrerequisites
      }));
  }
}


export class FollowUpEvaluationStep implements IPlayerStep {
  name = 'followUpEvaluation';
  visible = false;
  overrideEnabled = false;
  contentStep = false;

  constructor(private registrationWorkflow: LearningObjectRegistrationWorkflowService,
              private courseQuizTypes: ICourseQuizTypes) {
  }

  get title() {
    return this.courseQuizTypes.followupEvaluation?.categoryName || null;
  }

  get defaultAction() {
    return { type: 'followUpEvaluation' };
  }

  get completed() {
    return this.registrationWorkflow.registration.completedRegistration &&
      this.registrationWorkflow.registration.followUpEvaluation.completed;
  }

  get available() {
    return this.registrationWorkflow.hasAction('LaunchFollowUpEvaluationAction');
  }

  launch(includePrerequisites: boolean): Observable<void> {
    return fromPromise(
      this.registrationWorkflow.exec('LaunchFollowUpEvaluationAction', {}, {
        skipPrerequisites: !includePrerequisites
      }));
  }
}

export interface IPlayerEvent {
  event: string;
  step: IPlayerStep
}

export class Player {
  processingAction: string;
  steps: IPlayerStep[] = [];
  currentStep: IPlayerStep;
  currentType: string;

  private events$ = new Subject<IPlayerEvent>();
  private courseQuizTypes: ICourseQuizTypes;

  constructor(
    public registrationWorkflow: LearningObjectRegistrationWorkflowService,
    private quizOptionsService: QuizOptionsService,
    private trainingService: TrainingService,
    private ngbModal: NgbModal,
    private learningObjectRegistrationService: LearningObjectRegistrationService
  ) {
    this.registrationWorkflow.registration.on('updated', () => {
      this.buildSteps(this.registrationWorkflow);
    });

    // this.registrationWorkflow.launchContent = (stepType: string) => {
    //   this.launch(stepType);
    // };
  }

  init():Observable<void> {
    return this.quizOptionsService.getCourseQuizTypes()
      .pipe(map((options) => {
        this.courseQuizTypes = options;
        this.buildSteps(this.registrationWorkflow);
      }));
  }

  launch(stepType: string) {
    const step = this.steps.find(
      s => s.defaultAction?.type === stepType || s.availableTypes?.includes(stepType as IStepType)
    );

    if (this.stepAvailable(step)) {
      this.currentStep = step;
      this.currentType = stepType;
      this.dispatchEvent('stepChanged', this.currentStep);
    } else if (step && !step.completed && step.launchable) {
      this.dispatchEvent('contentNotAvailable', step);
    } else {
      const allSteps = Object.values(this.getAllSteps(this.registrationWorkflow)) as IPlayerStep[];
      const requestedStep = allSteps
        .find(s => s.defaultAction?.type === stepType || s.availableTypes?.includes(stepType as IStepType));

      if (requestedStep && requestedStep.title) {
        this.dispatchEvent('error', `${requestedStep.title} is not available.`);
      } else {
        this.dispatchEvent('error', 'Requested content is not available');
      }
    }
  }

  hasCompleteAction(course:{id:number|string}):boolean {
    return  course.id.toString() === this.registrationWorkflow.registration.courseId.toString() &&
     this.registrationWorkflow.hasAction('CompleteAction');
  }

  stepAvailable(step: IPlayerStep) {
    return step && step.available && (!step.completed || step.overrideEnabled);
  }

  showStep(step: IPlayerStep) {
    step.launch(true)
      .subscribe(() => {
        this.launch(step.defaultAction?.type);
      });
  }

  getNextAvailableStep(ignoreAvailability?: boolean) {
    return this.getNextStep(ignoreAvailability);
  }

  getNextStep(ignoreAvailability?: boolean) {
    let stepIndex = this.currentStep ? this.steps.indexOf(this.steps.find(s => s.name === this.currentStep.name)) : -1;

    while (stepIndex < this.steps.length - 1) {
      const nextStep = this.steps[stepIndex + 1];

      if (this.stepAvailable(nextStep) || (ignoreAvailability && nextStep)) {
        return nextStep;
      } else {
        stepIndex++;
      }
    }

    return null;
  }

  launchNextAvailableStep() {
    this.launchNextStep(false);
  }

  launchNextStep(ignoreAvailability?: boolean) {
    const step = this.getNextStep(ignoreAvailability);

    if (step) {
      step.launch(true)
        .subscribe(() => {
          this.launch(step.defaultAction?.type);
        });
    }
  }

  executeAndLaunchNextStep(action: string) {
    this.registrationWorkflow.exec(action, {
      actionCallback: () => {
        this.launchNextStep();
      }
    });
  }

  leave() {
    if (this.currentStep && !this.currentStep.completed || this.getNextStep()) {
      const modalInstance = this.ngbModal.open(IncompleteConfirmationComponent);

      return from(modalInstance.result).pipe(
        catchError(() => EMPTY),
        switchMap(() => {
          return this.checkComponentCompletesCollection();
        }));
    }

    return this.checkComponentCompletesCollection();
  }

  public on(key: string) {
    return this.events$.asObservable()
      .pipe(
        filter(event => event.event === key),
        map(event => event.step));
  }

  public onError():Observable<string> {
    return this.events$.asObservable()
      .pipe(
        catchError(event => of(event))
      );
  }

  private checkComponentCompletesCollection() {
    if (this.registrationWorkflow.registration.hasNotification('parent_bls_completion')) {
      return of({ parentCollectionCompletion: true });
    }

    return fromPromise(this.learningObjectRegistrationService.get(this.registrationWorkflow.registration.course, null)
      .then((reg) => {
        return { parentCollectionCompletion: reg.hasNotification('parent_bls_completion') };
      }));
  }

  private getAllSteps(registrationWorkflow: LearningObjectRegistrationWorkflowService) {
    return {
      pre: new PreAssessmentStep(registrationWorkflow, this.courseQuizTypes),
      course: new CourseStep(registrationWorkflow, this.trainingService),
      post: new PostAssesmentStep(registrationWorkflow, this.courseQuizTypes),
      preEvaluation: new PreEvaluationStep(registrationWorkflow, this.courseQuizTypes),
      eval: new EvaluationStep(registrationWorkflow, this.courseQuizTypes),
      followUpEvaluation: new FollowUpEvaluationStep(registrationWorkflow, this.courseQuizTypes)
    };
  }

  private buildSteps(registrationWorkflow: LearningObjectRegistrationWorkflowService) {
    const allSteps = this.getAllSteps(registrationWorkflow);
    const registration = registrationWorkflow.registration;

    if (registration.preEvaluation?.id) {
      allSteps.preEvaluation.intendedFor = registration.preEvaluation.intendedForModeId ||
        quizEnums.quizIntendedFor.learners;
      this.addStep(allSteps.preEvaluation);
    }

    if (registration.preAssessment?.id) {
      allSteps.pre.intendedFor = registration.preAssessment.intendedForModeId || quizEnums.quizIntendedFor.learners;
      this.addStep(allSteps.pre);
    }

    this.addStep(allSteps.course);

    if (registration.postAssessment?.id) {
      allSteps.post.intendedFor = registration.postAssessment.intendedForModeId || quizEnums.quizIntendedFor.learners;
      this.addStep(allSteps.post);
    }

    if (registration.evaluation?.id && registration.id
      && registration.status_id === CourseRegistrationStatus.evaluationPending) {
      allSteps.eval.intendedFor = registration.evaluation.intendedForModeId || quizEnums.quizIntendedFor.learners;
      this.addStep(allSteps.eval);
    }

    if (registration.followUpEvaluation?.id &&
      (registration.completedRegistration ||
        registration.id && courseRegistrationStatuses.completedSet.includes(registration.status_id))) {
      this.addStep(allSteps.followUpEvaluation);
    }
  }

  private addStep(step: IPlayerStep) {
    const existingStep = this.steps.find(s => s.name === step.name);

    if (!existingStep) {
      this.steps.push(step);
    }
  }

  private dispatchEvent(key: string, data: IPlayerStep | string) {
    if (key === 'error') {
      this.events$.error(data as string);
    } else {
      this.events$.next({ event: key, step: data as IPlayerStep });
    }
  }
}

export type PlayerServiceFactory = (r: LearningObjectRegistrationWorkflowService) => Player;
export const PlayerServiceToken = new InjectionToken<PlayerServiceFactory>('PlayerServiceFactory');

function factory(
  quizOptionsService: QuizOptionsService,
  ngbModal: NgbModal,
  learningObjectRegistrationService: LearningObjectRegistrationService,
  trainingService: TrainingService
): PlayerServiceFactory {
  return (registrationWorkflow) => {
    return new Player(registrationWorkflow, quizOptionsService, trainingService,
      ngbModal, learningObjectRegistrationService);
  };
}

export const playerServiceProvider: FactoryProvider = {
  provide: PlayerServiceToken,
  useFactory: factory,
  deps: [QuizOptionsService, NgbModal, LearningObjectRegistrationService, TrainingService]
};

<!--Exam-->
<div *ngIf="workflow.registration.course.format === 108" class="exam-player">
  <div class="content row">
    <quiz-player-view introText="Click the 'Start' button when you are ready to take the exam."
                      lostConnectionText="The internet connection was lost. Please close the browser tab, check your internet connection, and relaunch the exam. Your progress has been saved up to this point."
                      (finishHandler)="finishHandler()"
                      [resultId]="workflow.registration.exam.resultId"
                      [playerClose]="closeSubject"
                      [quizRetake]="quizRetake"
                      [customPlayerSteps]="settings.courseDetails.examCustomQuizPlayerSteps">

      <span  retakeButtons class="text-center-xs display-block-xs">
        <button class="btn btn-default btn-lg xs-margin-bottom-phone xs-margin-bottom-phone"
                *ngIf="workflow.hasAction('Launch')"
                (click)="handleRetake(type)"
                type="button" title="Review">
          Re-take Exam
        </button>
      </span>

      <div finishText>
        Thank you for taking the exam.
      </div>
      <div timeEndsText>
        Your time is out. The exam result will be calculated based on the answers you have entered until this point.
      </div>
    </quiz-player-view>
  </div>
</div>

<!--Survey-->
<div *ngIf="workflow.registration.course.format === 115" class="survey-player">
  <div class="content row">
    <quiz-player-view introText="Click the 'Start' button when you are ready to take the survey."
                      lostConnectionText="The internet connection was lost. Please close the browser tab, check your internet connection, and relaunch the survey. Your progress has been saved up to this point."
                      warningText="{{workflow.registration.course.collectResultsAnonymously ? 'All results will be collected and stored in the database anonymously with no way to look up the account.' : null}}"
                      (finishHandler)="finishHandler()"
                      [playerClose]="closeSubject"
                      [resultId]="workflow.registration.exam.resultId">
      <div finishText>
        Thank you for taking the survey.
      </div>
      <div timeEndsText>
        Your time is out. The survey result will be calculated based on the answers you have entered until this point.
      </div>
    </quiz-player-view>
  </div>

</div>

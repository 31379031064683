import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { UIRouterGlobals, UrlService } from '@uirouter/core';
import { IUrlParams, UrlParamHandler } from 'core/navigation/models/navigation-url.model';
import _ from 'lodash';
import { ICourseOptions } from 'modules/course/common/models/play-course.model';
import { CourseService } from 'modules/course/common/services/course.service';


interface ICatalogFilters {
  subject?: string;
  audience_id?: string;
}

@Component({
  selector: 'catalog-view-component',
  templateUrl: './catalog-view.component.html',
})
export class CatalogViewComponent implements OnInit, OnDestroy {
  moduleTitle = 'Catalog';
  catalogTitle: string = null;
  catalogFilters: ICatalogFilters = {};
  catalogType: 'subject' | 'audience' | null = null;
  locationPath: string;
  courseOptions: ICourseOptions;
  searchQuery: string;
  loading = false;

  mobileView = false;

  private urlHandlerDestroy: UrlParamHandler;
  private urlParams: IUrlParams;

  constructor(
    private window: Window,
    private urlService: UrlService,
    private courseService: CourseService,
    private activeState: UIRouterGlobals,
  ) { }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setWindowWidth();
  }

  ngOnInit(): void {
    this.moduleTitle = _.get(this.activeState.current, 'data.label') || 'Catalog';

    this.locationPath = this.window.location.pathname;
    this.initCatalog(this.urlService.search());
    this.setWindowWidth();
    this.courseService.getCourseOptions()
      .subscribe((options) => {
        this.courseOptions = options;

        if (this.catalogType) {
          this.initTitle();
        }
      });
    this.ngOnUrlChange();
    this.urlHandlerDestroy = <UrlParamHandler> this.urlService.onChange(() => this.ngOnUrlChange());
  }

  ngOnDestroy(): void {
    this.urlHandlerDestroy();
  }

  ngOnUrlChange() {
    const params = _.pickBy(this.urlService.search(), _.identity);

    if (!_.isEqual(params, this.urlParams)) {
      this.urlParams = params;
      this.ngOnUrlParamsChange(params);
    }
  }

  ngOnUrlParamsChange(params: IUrlParams<string>) {
    this.searchQuery = params.catalog_query;
    this.initTitle();

    if (params.catalogType !== this.catalogType && this.locationPath === this.window.location.pathname) {
      this.initCatalog(params);
    }
  }

  private setWindowWidth() {
    this.mobileView = this.window.innerWidth < 768;
  }

  private initCatalog(params: IUrlParams<string>) {
    this.catalogTitle = null;
    this.catalogFilters = {};
    this.catalogType = params.catalogType as 'subject' | 'audience';

    if (this.catalogType === 'subject') {
      this.catalogFilters.subject = params.subject;
    }

    if (this.catalogType === 'audience') {
      this.catalogFilters.audience_id = params.audience_id;
    }
  }

  private initTitle() {
    if (this.searchQuery) {
      this.catalogTitle = 'Search results for "' + this.searchQuery + '"';

      return false;
    }

    if (this.courseOptions) {
      if (this.catalogType === 'subject') {
        _.forEach(this.courseOptions.subjectAreas, (subjectArea) => {
          _.forEach(subjectArea.topics, (topic) => {
            if (topic.id === parseInt(this.catalogFilters.subject)) {
              this.catalogTitle = topic.name + ' courses';

              return false;
            }
          });

          if (this.catalogTitle) {
            return false;
          }
        });
      }

      if (this.catalogType === 'audience') {
        const audience = _.find(this.courseOptions.targetAudiences, (targetAudience) => {
          return targetAudience.id === parseInt(this.catalogFilters.audience_id);
        });

        this.catalogTitle = audience.name + ' courses';

        return false;
      }
    }

    this.catalogTitle = null;

    return true;
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { BackUrlService } from 'core/navigation/back-url.service.ajs-upgraded-provider';
import { IEvent } from 'modules/events/models/event.model';
import { CurrentUserService } from 'ajs/modules/app/current-user.service';


@Component({
  selector: 'event-details',
  templateUrl: './event-details.component.html'
})
export class EventDetailsComponent implements OnInit {
  @Input() event!: IEvent;

  anonymous: boolean;

  constructor(private backUrlService: BackUrlService, private currentUserService: CurrentUserService) { }

  ngOnInit() {
    this.anonymous = this.currentUserService.get().anonymous;
  }

  goBack() {
    this.backUrlService.goBack();
  }
}

import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { LmsComponentsModule } from 'components/kmi.lms.components.module';
import { CommonModule } from '@angular/common';
import { CourseCustomListService } from 'modules/course/custom-lists/services/course-custom-list.service';
import { UserFeaturedCoursesComponent } from 'modules/course/custom-lists/componenets/user-featured-courses.component';
import { CourseTilesModule } from 'modules/course/views/tiles/course-tiles.module';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { courseCustomListRoutingModule } from 'modules/course/custom-lists/course-custom-list-routing.module';
import { CourseRegistrationCommonModule } from 'modules/course-registrations/course-registration-common.module';


@NgModule({
  imports: [
    UIRouterModule,
    LmsComponentsModule,
    CommonModule,
    CourseRegistrationCommonModule,
    CourseTilesModule,
    UIRouterUpgradeModule.forChild({ states: courseCustomListRoutingModule }),
  ],
  declarations: [
    UserFeaturedCoursesComponent,
  ],
  providers: [
    CourseCustomListService,
  ],
  exports: []
})
export class CourseCustomListModule {}

import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ElmsUtils } from 'core/utils';
import { PASS_HTTP_ERRORS_TOKEN } from 'core/http-interceptors';
import { ICourseReview } from 'modules/course/reviews/models/course-review.model';
import { HttpRequestParams } from 'core/services';
import { CurrentUserService } from 'ajs/modules/app/current-user.service';


enum CourseReviewEndpoints {
  GET = '/a/course/:courseId/reviews/:reviewId/',
  QUERY = '/a/course/:courseId/reviews/',
  POST = '/a/course/:courseId/reviews/',
  PUT = '/a/course/:courseId/reviews/:reviewId/',
  DELETE = '/a/course/:courseId/reviews/:reviewId/'
}


interface ICourseReviewsQueryResponse{
  total: number;
  items: ICourseReview[];
}

@Injectable()
export class CourseReviewService {
  constructor(private http: HttpClient, private currentUser:CurrentUserService) { }

  public get(courseId: number | string, reviewId: number): Observable<ICourseReview> {
    return this.http.get<ICourseReview>(
      ElmsUtils.formatUrl(CourseReviewEndpoints.GET, { courseId, reviewId })
    );
  }

  public query(courseId: number | string, params: HttpRequestParams): Observable<ICourseReviewsQueryResponse> {
    return this.http.get<ICourseReviewsQueryResponse>(
      ElmsUtils.formatUrl(CourseReviewEndpoints.QUERY, { courseId }),
      { params }
    );
  }

  public save(courseId: number, payload: Partial<ICourseReview>, throwHttpErrors = false): Observable<ICourseReview> {
    return this.http.post<ICourseReview>(
      ElmsUtils.formatUrl(CourseReviewEndpoints.POST, { courseId }),
      payload,
      { context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors) }
    );
  }

  public update(
    courseId: number | string, reviewId: number, payload: Partial<ICourseReview>, throwHttpErrors = false
  ): Observable<ICourseReview> {
    return this.http.put<ICourseReview>(
      ElmsUtils.formatUrl(
        CourseReviewEndpoints.PUT, { courseId: courseId, reviewId: reviewId }
      ),
      payload,
      { context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors) }
    );
  }

  public delete(courseId: number | string, reviewId: number): Observable<void> {
    return this.http.delete<void>(
      ElmsUtils.formatUrl(
        CourseReviewEndpoints.DELETE, { courseId: courseId, reviewId: reviewId }
      )
    );
  }

  public newReview(attrs) : ICourseReview {
    const user = this.currentUser.get();

    return {
      userName: user.firstName + ' ' + user.lastName,
      showName: true,
      rating: 0,
      date: new Date(),
      recommendCourse: true,
      ...attrs
    };
  }
}

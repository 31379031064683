<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
  <h4 class="modal-title">Send Email</h4>
</div>

<form ngForm="composeEmailForm" name="composeEmailForm" #composeEmailForm="ngForm"
      (ngSubmit)="onSubmit(composeEmailForm.form)">
  <div class="modal-body">
    <div *ngIf="view === 'compose'">
      <email-sender [email]="email" [recipientsReadOnly]="recipientsReadOnly"
                    [formOptions]="{submitted: composeEmailForm.submitted, pending: processing}"
                    [allowAddition]="allowAddition"></email-sender>
    </div>
    <div *ngIf="view === 'success'">
      <div class="alert alert-success">Emails Successfully Sent</div>
    </div>
    <div *ngIf="view === 'error'">
      <div class="alert alert-danger" role="alert">{{error}}</div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-warning" (click)="cancel()">Close</button>
    <button type="submit" class="btn btn-success" [ladda]="processing" *ngIf="view === 'compose'"
            data-spinner-color="#FFF" [disabled]="processing || composeEmailForm.invalid"> Send</button>
    <button type="button" class="btn btn-default" (click)="retry()" *ngIf="view === 'error'"> Retry</button>
  </div>
</form>

import { IEntityUser } from 'modules/user/models/user.model';


export enum CheckoutProductType {
  COURSE = 3,
  SESSION = 4
}

export interface ICheckoutProductData {
  courseId: string;
  startDate: string;
  endDate: string;
  locationName: string | null;
  locationDescription: string | null;
  locationDistance: number | null;
}


export interface IPurchasePaymentGateway {
  id: number;
  typeId: CheckoutProductType
}

export interface IPurchasePaymentInfo {
  processorEndpoint: string;
  securityTokenId: string;
  securityToken: string;
  paymentGateway: IPurchasePaymentGateway;
  response: string | null;
}

export interface IPurchaseResult {
  id: number;
  statusId: number;
  payment: IPurchasePaymentInfo;
}

export interface IPurchasePayloadPayment{
  cancelUrl: string;
}

export interface IPurchasePayload {
  payment: IPurchasePayloadPayment;
  cart: ICheckoutProduct[],
  returnUrl: string;
}
export interface ICheckoutProduct {
  id: string | number;
  typeId: CheckoutProductType;
  name: string;
  price: number;
  data: ICheckoutProductData | null;
}
export interface IPurchaseDetailsCart extends ICheckoutProduct {
  productId: number,
  quantity: number;
  priceTypeId: number,
  data:  ICheckoutProductData | null;
}

export interface IPurchaseDetailsUser extends IEntityUser {
  address: string | null;
  address2: string | null;
  city: string | null;
  stateName: string | null;
  zip: string | null;
}

export interface IPurchaseResultDetails {
  id: number;
  statusId: number;
  cart: IPurchaseDetailsCart[];
  user: IPurchaseDetailsUser;
  amount: number;
  payment: IPurchasePaymentInfo;
}

import { Unsubscribable, finalize, tap } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { IBlogPost } from 'modules/blog/models/blog-post.model';
import { BlogPostService } from 'modules/blog/services/blog-post.service';
import { BlogPostRotatorService } from 'modules/blog/services/blog-rotator.service';


@Component({
  selector: 'recent-blog-post-hero',
  templateUrl: './recent-blog-post-hero.component.html',
})
export class RecentBlogPostHeroComponent implements OnInit, OnDestroy {
  static readonly selector = 'recentBlogPostHero';

  post: IBlogPost | null = null;
  heroBackground?: string;

  private requestSubscriber?: Unsubscribable;

  constructor(
    private blogPostRotatorService: BlogPostRotatorService,
    private blogPostService: BlogPostService
  ) {}

  ngOnInit(): void {
    const lastPostId = this.blogPostRotatorService.state.post?.id;

    this.requestSubscriber = (
      lastPostId
        ? this.blogPostService.get(lastPostId)
        : this.blogPostRotatorService.nextPost(this.post?.id)
    )
      .pipe(
        tap((post) => this.updatePost(post)),
        finalize(() => this.clearRequestSubscriber())
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.clearRequestSubscriber();
  }

  private clearRequestSubscriber(): void {
    if (this.requestSubscriber) {
      this.requestSubscriber.unsubscribe();
      delete this.requestSubscriber;
    }
  }

  private updatePost(post: IBlogPost | null): void {
    this.post = post;

    if (this.post?.heroImageId) {
      this.heroBackground = `/a/blog_posts/${this.post.id}/hero/`;
    } else if (this.heroBackground) {
      delete this.heroBackground;
    }
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import { CurrentUserService } from 'core/authorization';
import { ActiveRegistrationStatuses, CompletedRegistrationStatuses, RegistrationStatuses } from 'core/types';
import { ElmsUtils } from 'core/utils';
import _ from 'lodash';
import {
  IUserCourseQueryParams, ICourseRegistration
} from 'modules/course-registrations/models/course-registration.model';
import { UserCourseService } from 'modules/course-registrations/services/user-course.service';
import { ISessionSearchItem } from 'modules/course/common/models/course-search.model';
import { ICourseSessionBase } from 'modules/course/sessions/models/course-session.models';
import { CourseSessionsService } from 'modules/course/sessions/services/course-sessions.service';
import { SearchService } from 'modules/search/services';
import { ISearchResponse } from 'modules/search/models/search.models';
import { IUser } from 'modules/user/models/user.model';
import moment from 'moment';
import { Observable, map, of, switchMap } from 'rxjs';
import {
  ICalendarSearchItem, ICalendarConfig, ICalendarQueryParams, ICalendarEvent, IEventLabel
} from '../models/events.model';
import { eventLabels } from '../types/event-labels.types';


enum CalendarEventsEndpoints {
  public = '/a/search/',
  admin = '/a/admin/search/',
  adminUserCoursesSearch = '/a/admin/search/users/:userId/courses/'
}


@Injectable()
export class CalendarEventsService extends SearchService<ICalendarSearchItem> {
  initialRequestItemCount = 1000;

  private configs: ICalendarConfig = {
    mode: 'public',
    user: null
  };

  private currentUser: IUser;
  private labels = Object.assign({}, eventLabels);

  constructor(
    protected http: HttpClient,
    private stateService: StateService,
    private currentUserService: CurrentUserService,
    private userCourseService: UserCourseService,
    private sessionsService: CourseSessionsService,
  ) {
    super(http);

    this.currentUser = this.currentUserService.get();
    this.configs.user = this.currentUser;
  }

  query(params: ICalendarQueryParams, unique?: boolean): Observable<ISearchResponse<ICalendarEvent>> {
    return super.search(Object.assign({}, params, { show_in_calendar: true }))
      .pipe(map((data) => ({
        count: data.count,
        error: data.error,
        items: this.transformEvents(data.items, unique, !!params.user_courses)
      })));
  }

  events(params: ICalendarQueryParams, unique?: boolean): Observable<ICalendarEvent[]> {
    return super.search(Object.assign({}, params, { show_in_calendar: true }))
      .pipe(map((data) => this.transformEvents(data.items, unique, !!params.user_courses)));
  }

  registrations(items: ICalendarEvent[], userId: number, sessionIds: number[]): Observable<ICalendarEvent[]> {
    const query: IUserCourseQueryParams = {
      session_id: sessionIds,
      include_external: false,
      last: true,
      statuses: ActiveRegistrationStatuses.concat(CompletedRegistrationStatuses)
    };

    const request = !this.configs.user.anonymous
      ? this.userCourseService.registrations(userId, query)
      : of([]);

    return request
      .pipe(switchMap(registrations => {
        return this.sessionsService.get({ session_id: sessionIds }).pipe(
          map(sessions => this.transformRegistrations(items, registrations, sessions))
        );
      }));
  }

  conferenceRegistrations(items: ICalendarEvent[], userId: number, sessionIds: number[]): Observable<ICalendarEvent[]> {
    const query: IUserCourseQueryParams = {
      conference_session_id: sessionIds,
      include_external: false,
      last: true,
      statuses: ActiveRegistrationStatuses.concat(CompletedRegistrationStatuses)
    };

    const request = !this.configs.user.anonymous
      ? this.userCourseService.registrations(userId, query)
      : of([]);

    return request
      .pipe(switchMap(registrations => {
        return this.sessionsService.get({ conference_session_id: sessionIds }).pipe(
          map(sessions => this.transformConferenceRegistrations(items, registrations, sessions))
        );
      }));
  }

  next(unique?: boolean): Observable<ICalendarEvent[]> {
    const params = this.requestParams as ICalendarQueryParams;

    return super.searchNext().pipe(map(data => this.transformEvents(data.items, unique, !!params.user_courses)));
  }

  protected get endpoint(): string {
    if (!this.configs.mode) {
      throw Error(`'CalendarEventsService' doesn't have endpoints.`);
    }

    return ElmsUtils.formatUrl(CalendarEventsEndpoints[this.configs.mode], { userId: this.configs.user.id });
  }

  get user(): IUser {
    return this.configs.user;
  }

  set user(value: IUser) {
    this.configs.user = value;
  }

  // set mode(value: ICalendarDataModes) {
  //   if (!(value in CalendarEventsEndpoints)) {
  //     this.configs.mode = null;
  //     throw Error(`'CalendarEventsService' doesn't support this mode '${value}'.`);
  //   }
  //
  //   this.configs.mode = value;
  // }

  private transformEvents(items: ICalendarSearchItem[], unique?: boolean, registered?: boolean): ICalendarEvent[] {
    let events: ICalendarEvent[] = [];

    _.each(_.groupBy(items, 'type'), (value: ICalendarSearchItem[], key: string) => {
      if (key === 'course') {
        events = events.concat(this.transformCourses(value, unique, registered));
      }

      if (key === 'event') {
        events = events.concat(this.transformNonTrainEvents(value, registered));
      }
    });

    return events;
  }

  private getLabels(event: ICalendarSearchItem | ICalendarEvent, registered?: boolean): IEventLabel[] {
    const eventLabels = [];

    // Exercise
    if (event.course_format_type_id === 7) {
      eventLabels.push(this.labels.exercise);
    }

    // Conference
    if (event.course_format_type_id === 5 || event.course_format_type_id === 4) {
      eventLabels.push(this.labels.conference);
    }

    // Calendar event
    if (event.type === 'event') {
      eventLabels.push(this.labels.other);
    }

    // Video conference
    if (event.labelName && event.labelName.toLowerCase() === 'videoconference') {
      eventLabels.push(this.labels.video);
    }

    // Is registered
    if ((registered || event.registered) && this.currentUser.id === this.configs.user.id) {
      eventLabels.push(this.labels.registered);
    }

    // Registered to wait list
    if (event.waitList) {
      eventLabels.push(this.labels.waitList);
    }

    // Is unavailable
    if (event.unavailable) {
      eventLabels.push(this.labels.unavailable);
    }

    return eventLabels;
  }

  private transformCourses(items: ICalendarSearchItem[], unique?: boolean, registered?: boolean): ICalendarEvent[] {
    return items.flatMap(item => {
      const labels = this.getLabels(item, registered);
      const url = this.stateService.href('main.' + item.type, { id: item.id });

      return this.denormalizeCourseSessions(item.sessions, unique).map((event): ICalendarEvent => {
        return {
          ...event,

          id: Number(item.id),
          url: url,
          labels: labels,
          startOrigin: event.start,
          endOrigin: event.end,
          name: item.title,
          type: item.type,
          sponsor: item.sponsor,
          registered: registered,
          labelName: item.labelName,
          creditTypes: item.courseCredits,
          courseFormat: item.courseFormat,
          format: item.format,
          formatId: item.formatId,
          providesMultipleCredits: item.providesMultipleCredits,
          approvalStatusId: item.approvalStatusId,
          course_format_type_id: item.course_format_type_id,
          locationTypeId: item.locationTypeId,
          title: labels.length ? `${labels[0].name} - ${item.title}` : item.title,
          className: labels.length ? labels[0].className : null,
          conferenceSessionId: ['conference_session', 'bls_conference_session'].includes(event.sessionType)
            ? event.sessionId
            : null,
        };
      });
    });
  }

  private denormalizeCourseSessions(items: ISessionSearchItem[], unique?: boolean): ICalendarEvent[] {
    const sessions = items.flatMap(i => i.schedules.map((s, idx): ICalendarEvent => ({
      sessionId: Number(i.id),
      scheduleId: Number(idx),
      start: s.start,
      end: s.end,
      location: s.location,
      allDay: !s.end,
      sessionType: i.session_type
    })));

    if (unique) {
      return _.sortBy(sessions, (s) => s.start && new Date(s.start)).slice(0, 1);
    }

    return sessions;
  }

  private transformNonTrainEvents(events: ICalendarSearchItem[], registered?: boolean): ICalendarEvent[] {
    return events.map((item): ICalendarEvent => {
      const url = this.stateService.href('main.' + item.type, { id: item.id }, { absolute: true });
      const labels = this.getLabels(item, registered);

      return {
        id: Number(item.id),
        url: url,
        type: item.type,
        start: item.start,
        end: item.end,
        allDay: !item.end,
        location: item.location,
        labels: labels,
        name: item.title,
        registered: registered,
        sponsor: item.sponsor,
        format: item.format,
        formatId: item.formatId,
        approvalStatusId: item.approvalStatusId,
        providesMultipleCredits: item.providesMultipleCredits,
        className: labels.length ? labels[0].className : null,
        title: labels.length ? `${labels[0].name} - ${item.title}` : null
      };
    });
  }

  // TODO Review the logic.
  private transformRegistrations(
    events: ICalendarEvent[], registrations: ICourseRegistration[], sessions: ICourseSessionBase[]
  ): ICalendarEvent[] {
    const registered = [];
    const unavailable = [];

    // Convert user courses to dict. I'll help to make search faster.
    const userCoursesDict = _.groupBy(registrations, 'courseId');
    const sessionsDict = _.keyBy(sessions, 'id');

    // For each event look for the registration
    events.forEach(e => {
      if (e.type === 'course') {
        const event = Object.assign({}, e) as ICalendarEvent;
        const courseRegistration = userCoursesDict[event.id]?.find(item => item.sessionId === event.sessionId);

        // Update event. Assign "registered" label
        if (courseRegistration) {
          if (courseRegistration.statusId === RegistrationStatuses.waitList) {
            event.waitList = true;
          } else {
            event.registered = true;
          }

          event.labels = this.getLabels(event);
          event.className = event.labels.length ? event.labels[0].className : null;
          event.title = event.labels.length ? `${event.labels[0].name} - ${event.title}` : event.title;

          registered.push(event);
        } else {
          // find session flags
          if (sessionsDict[event.sessionId]) {
            const session = sessionsDict[event.sessionId];
            const endSessionDateTime = event.endOrigin;
            const scheduleIsExpired = moment().isAfter(endSessionDateTime) &&
              (!session.registrationDeadline || moment().isAfter(session.registrationDeadline));

            if (session.deadlinePassed || scheduleIsExpired || ((session.attendCapacity > 0) &&
            (session.attendCapacity - session.seatsAllocated - session.seatsHeld - session.seatsOverAllocated <= 0))) {
              event.unavailable = true;
              event.labels = this.getLabels(event);
              event.className = event.labels.length ? event.labels[0].className : null;
              event.title = event.labels.length ? `${event.labels[0].name} - ${event.title}` : event.title,

              unavailable.push(event);
            }
          }
        }
      }
    });

    return registered.concat(unavailable);
  }

  // TODO Review the logic.
  private transformConferenceRegistrations(
    events: ICalendarEvent[], registrations: ICourseRegistration[], sessions: ICourseSessionBase[]
  ): ICalendarEvent[] {
    const registered = [];
    const unavailable = [];

    // Convert user courses to dict. I'll help to make search faster.
    const userCoursesDict = _.keyBy(registrations, 'courseId');
    const sessionsDict = _.keyBy(sessions, 'id');

    // For each conference look for the registration
    events.forEach(e => {
      const event = Object.assign({}, e) as ICalendarEvent;

      if (event.type === 'course' && event.conferenceSessionId) {
        const courseRegistration = userCoursesDict[event.conferenceSessionId];

        // Update event. Assign "registered" label
        if (courseRegistration) {
          if (courseRegistration.statusId === RegistrationStatuses.waitList) {
            event.waitList = true;
            event.registered = false;
          } else {
            event.registered = true;
          }

          event.labels = this.getLabels(event);
          event.className = event.labels.length ? event.labels[0].className : null;
          event.title = event.labels.length ? `${event.labels[0].name} - ${event.title}` : event.title;

          registered.push(event);
        } else {
          // find session flags
          if (sessionsDict[event.conferenceSessionId]) {
            const session = sessionsDict[event.conferenceSessionId];
            const endSessionDateTime = event.endOrigin;
            const scheduleIsExpired = moment().isAfter(endSessionDateTime) &&
              (!session.registrationDeadline || moment().isAfter(session.registrationDeadline));

            if (session.deadlinePassed || scheduleIsExpired ||
              ((session.attendCapacity - session.seatsAllocated - session.seatsHeld <= 0) && session.attendCapacity >
                0 && !session.allowOverRegister)) {
              event.unavailable = true;
              event.labels = this.getLabels(event);
              event.className = event.labels.length ? event.labels[0].className : null;
              event.title = event.labels.length ? `${event.labels[0].name} - ${event.title}` : event.title,

              unavailable.push(event);
            }
          }
        }
      }
    });

    return registered.concat(unavailable);
  }
}

import { Component, Input } from '@angular/core';


@Component({
  selector: 'course-length',
  templateUrl: './course-length.component.html',
})
export class CourseLengthComponent {
  static readonly selector = 'courseLength';

  @Input() course: {length:number};
}

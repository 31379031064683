<div *ngIf="post">
  <div class="flex flex-vertical-center">
    <div class="flex-row">
      <div class="flex-col flex-col-grow" [id]="trackingName + '_header'">
        <h3 class="home-tile-title">{{title || 'News and Announcements'}}</h3>
      </div>
      <div class="flex-col flex-col-7x text-right sm-margin-bottom">
        <a href="#" uiSref="main.news" class="view-all" aria-label="post" *ngIf="isStateAvailable('main.news')" title="View All"
          [id]="trackingName + '_blog_posts_rotator_view_all'" [trackLinkClick]="'announcements'">
          View All
        </a>
        <a href="#" uiSref="main.backdoorNews" aria-label="post" title="View All" class="view-all"
           *ngIf="!isStateAvailable('main.news') && isStateAvailable('main.backdoorNews')"
          [id]="trackingName + '_blog_posts_rotator_view_all'" [trackLinkClick]="'announcements'">
          View All
        </a>
      </div>
    </div>
  </div>


  <div class="tile-recent-post">
    <div class="tile-body">

      <div class="flex flex-vertical-center recent-post-author-info" *ngIf="post.showAuthorInfo">
        <div class="flex-row">
          <div class="flex-col flex-col-3x">
            <user-avatar [user]="post.createdBy" [size]="32" [hideTitle]="true"></user-avatar>
          </div>
          <div class="flex-col flex-col-grow">
            <a *ngIf="post.createdBy?.userProfileExist && isStateAvailable('main.user_network')"
              href="" uiSref="main.user_network" [uiParams]="{userId: post.createdBy.id}"
              class="recent-post-username"
              [id]="trackingName + '_blog_posts_rotator_user_profile'">
              <span [innerHTML]="post.createdBy.firstName"></span>
              <span [innerHTML]="post.createdBy.lastName"></span>
            </a>
            <span *ngIf="!post.createdBy?.userProfileExist || !isStateAvailable('main.user_network')"
              class="recent-post-username">
              <span [innerHTML]="post.createdBy.firstName"></span>
              <span [innerHTML]="post.createdBy.lastName"></span>
            </span>
            <div class="recent-post-date" [innerHTML]="post.updatedDate| date:'mediumDate'"></div>
          </div>
        </div>
      </div>

      <h4 class="tile-course-title" *ngIf="post.showTitle" [innerHTML]="post.title"></h4>

      <expand-for-more [content]="post.getContent()" class="course-description"
          [trackingName]="trackingName + '_blog_posts_rotator'"
          [parentId]="trackingName + '_header'"
          placeUnder='nav.navbar.visible-xs'
      ></expand-for-more>

    </div>

  </div>
</div>

import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';


export const calendarOptions: CalendarOptions = {
  initialDate: null,
  initialView: null,
  views: {
    dayGridMonth: {
      dayHeaderClassNames: 'fc-col-header-disabled',
      dayMaxEvents: 4,
    }
  },
  navLinks: true,
  timeZone: 'local',
  eventTimeFormat: { hour12: true, hour: 'numeric', minute: '2-digit', omitZeroMinute: true },
  customButtons: {
    myCustomButton: {
      text: 'select',
      icon: 'datePicker',
      click: null
    }
  },
  headerToolbar: {
    start: 'prev,next today myCustomButton',
    center: 'title',
    end: 'dayGridMonth,timeGridWeek,timeGridDay'
  },
  fixedWeekCount: false,
  editable: false,
  plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
  height: 'auto',
  contentHeight: 'auto',
  datesSet: null,
  eventClick: null
};

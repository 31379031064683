import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { CourseService } from 'modules/course/common/services/course.service';
import { IActionItem } from 'modules/navigation/models/navigation.model';


@Component({
  selector: 'target-audience-navigation-items',
  templateUrl: './target-audience-navigation-items.component.html',
})
export class TargetAudienceNavigationItemsComponent implements OnInit {
  static readonly selector = 'targetAudienceNavigationItems';

  items: IActionItem[] = [];

  constructor(
    private courseService: CourseService,
    private stateService: StateService
  ) { }


  ngOnInit() {
    this.items = JSON.parse(localStorage.getItem(TargetAudienceNavigationItemsComponent.selector)) || [];
    this.courseService.getCourseOptions()
      .subscribe((options) => {
        this.items.length = 0;
        options.targetAudiences.forEach((item) => {
          this.items.push({
            id: item.id,
            name: item.name,
          });
        });
        localStorage.setItem(TargetAudienceNavigationItemsComponent.selector, JSON.stringify(this.items));
      });
  }

  clicked(item: IActionItem) {
    this.stateService.go('main.fullCatalog', {
      audience_id: item.id,
      type: 'course',
      catalogType: 'audience'
    });
  }
}
